import { useField } from '@unform/core';
import React, { useEffect, useRef, useState } from 'react';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';

import { Error, Container } from './styles';

interface Props {
  placeholder: string;
  icon: React.ComponentType<IconBaseProps>;
  value: string;
  name: string;
  onChange(text: string): void;
}

export const TextArea: React.FC<Props> = ({
  onChange,
  name,
  placeholder,
  value,
  icon: Icon,
}: Props) => {
  const [isFocused, setIsFocused] = useState(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: textAreaRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container isErrored={!!error} isFilled={!isFocused} isFocused={isFocused}>
      <div style={{ width: '100%', display: 'flex' }}>
        <Icon size={20} color={value.length > 0 ? '#439133' : '#665360'} />
        <textarea
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          ref={textAreaRef}
          defaultValue={defaultValue}
          placeholder={placeholder}
          onChange={(text) => onChange(text.target.value)}
          maxLength={250}
        />
        {error && (
          <Error title={error}>
            <FiAlertCircle color="#c53030" size="20" />
          </Error>
        )}
      </div>
      <span style={{ color: 'white', textAlign: 'right' }}>
        {value.length}/250 caracteres
      </span>
    </Container>
  );
};
