import styled, { keyframes } from 'styled-components';

interface ContainerProps {
  status?: string;
  cor: boolean;
}

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(70px);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  height: auto;
  flex: 1;
  width: 93%;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  margin: 0px auto;
  margin-top: 30px;
  margin-bottom: 30px;
  padding-bottom: 20px;
  animation: ${appearFromRight} 1s;
`;

export const Aba = styled.div`
  width: 100%;
  display: flex;
`;

export const ContainerAba = styled.div<ContainerProps>`
  button {
    margin: 0;
    height: 40px;
    background-color: ${(props) => (props.cor ? ' #f1f1f1' : '#a6a6a6')};
    color: #111111;
    border-width: 0px;
    min-width: 150px;
    padding: 0 10px 3px 10px;
    border: 1px solid #a6a6a6;
    &:hover {
      filter: brightness(1.1);
    }
  }
`;

export const ContainerSearch2 = styled.div`
  max-width: auto;
  margin-top: 20px;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  overflow-x: auto;

  div {
    display: flex;
    margin-left: 0 !important;

    svg {
      margin-bottom: 2px;
    }
    border-bottom-left-radius: 15px;
  }
  .search1 {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }
`;

export const ContainerSearch = styled.div`
  width: 100%;
  display: flex;
  /* margin: 0px auto; */
  margin-top: 2px;
  /*padding-left: 5%;*/

  .searchNome {
    div {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      padding-bottom: 1px;
      margin-left: 0px !important;
    }
    input {
      width: 200px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      margin-right: 15px !important;
    }
  }
  .searchDate {
    input {
      background-color: #f1f1f1;
      color: #111111;
      border-top-left-radius: 5px;
      border: none;
      padding: 10px;
      width: 110px;
      height: 40px;
      text-align: center;
      width: 120px;
      margin-left: 10px;
    }
  }
  .searchDateFim {
    input {
      background-color: #f1f1f1;
      color: #111111;
      border-top-right-radius: 5px;
      border: none;
      padding: 10px;
      width: 110px;
      height: 40px;
      text-align: center;
      width: 120px;
      margin-left: 2px;
    }
  }
`;

export const ContainerTable = styled.div`
  width: auto;
  align-items: center;
  margin: 0px auto;
  max-height: 45vh;
  overflow-x: auto;
  border-radius: 10px 10px 0 0;
  table {
    width: 100%;
    margin: 0 auto;
    border-collapse: collapse;
    border: 1px solid #439133;
    border-top: 0px;
    th {
      border-bottom: 1px solid #439133;
      justify-content: space-between;
      padding: 5px 10px;
      background-color: #439133;
      color: #fff;
      position: sticky;
      position: -webkit-sticky;
      top: 0;
    }
  }
  tbody {
    td {
      margin: 5px;
      padding: 5px;
      text-align: center;
      font-size: 14px;
      button {
        background-color: transparent;
        color: #fff;
        border: 0;
        & + button {
          margin-left: 20px;
        }
      }
    }
    tr {
      border-bottom: 1px solid #439133;
      /**funcao para fazer as linhas da tabela uma de cada cor de forma alternada.*/
      &:nth-child(even) {
        background-color: #323232;
      }
      &:hover {
        background-color: #454545;
      }
    }
  }
`;

export const ContainerPagination = styled.div`
  width: 90%;
  display: flex;
  margin: 0px auto;
  padding-bottom: 50px;
  justify-content: start;
  align-items: center;
  button {
    background-color: #0036cc;
    margin-left: 20px;
    border: none;
    height: 40px;
    width: 100px;
    padding: 0px 20px;
    border-radius: 5px;
    color: #ffffff;
    line-height: 20px;
  }
`;

export const ContainerSelect = styled.div`
  background: #232129;
  border-radius: 10px;
  padding: 16px;
  width: 100%;

  border: 2px solid #232129;
  color: #665360;

  display: flex;
  align-items: center;
`;

export const ContainerSupSearch = styled.div`
  display: flex;
  width: 100%;
  margin: 0px auto;
  margin-top: 15px;
  /*padding-left: 5%;*/
  select {
    background-color: #f1f1f1;
    color: #111111;
    border-radius: 0px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    /*margin-left: 20px;*/
    margin-left: 0px !important;
    border: none;
    justify-content: center;
    width: 240px;
    padding: 5px 10px;
  }
  .searchDate {
    margin-left: 15px;
    input {
      background-color: #f1f1f1;
      color: #111111;
      border-top-left-radius: 5px;
      border: none;
      padding: 10px;
      width: 110px;
      height: 40px;
      text-align: center;
      width: 120px;
      margin-left: 10px;
    }
  }
  .searchDateFim {
    input {
      background-color: #f1f1f1;
      color: #111111;
      border-top-right-radius: 5px;
      border: none;
      padding: 10px;
      width: 110px;
      height: 40px;
      text-align: center;
      width: 120px;
      margin-left: 2px;
    }
  }
`;

export const SearchContentRight = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  flex: 1;
  max-width: 600px;
  padding-left: 25px;
`;

export const SearchFilter = styled.div`
  display: flex;
  flex-direction: row;

  .searchUp {
    display: flex;
    flex-direction: row;
    flex: 1;
  }
`;

export const ButtonStyle = styled.button<ContainerProps>`
  display: flex;
  width: 100%;
  height: 40px;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.cor ? ' #f1f1f1' : '#a6a6a6')};
  color: #1a1a1a;
  border: 0px solid #a6a6a6;
  &:hover {
    filter: brightness(1.1);
  }
`;
