import React, { useCallback } from 'react';
import jsPDF from 'jspdf';
import { FiX } from 'react-icons/fi';
import { format } from 'date-fns-tz';
import pt from 'date-fns/locale/pt-BR/index';
import { maskCpf, Criptografar } from '../Input/mask';
import logoImg from '../../assets/logoMenu.png';

import {
  ContainerModal,
  Container,
  ContainerButton,
  ContainerHeader,
  ContainerHeaderDados,
  ContainerButtonAtualizar,
} from './styles';

interface ResponseGet {
  CENTRO: string;
  CENTRO_CUSTO_DESC: string;
  CNPJ: string;
  COD_FUNCAO: string;
  CPF: string;
  DESC_FUNCAO: string;
  EMPRESA: string;
  EMPRESA_NOME: string;
  ENDERECO: string;
  MATRICULA: string;
  NOME: string;
  POSTO: string;
  SITUACAO: string;
  checked: boolean;
}

interface ModalQrCode {
  dados?: string;
  dadosGeral?: ResponseGet[];
  onClose?(): void;
}

const ModalDeclaracaoVacinacao: React.FC<ModalQrCode> = ({
  onClose = () => {
    ('');
  },
  dadosGeral,
}) => {
  const dataAtual = format(new Date(), "dd 'de' MMMM 'de' yyyy", {
    locale: pt,
  });

  const handleOutSideClick = useCallback(
    (e) => {
      if (e.target.id === 'modal') {
        onClose();
      }
    },
    [onClose],
  );

  const handleDownloadPDF = useCallback(() => {
    const doc = new jsPDF("p", "mm", "a4"); // eslint-disable-line
    // const width = doc.internal.pageSize.getWidth();
    // const height = doc.internal.pageSize.getHeight();

    /*------------------------------------------------------------------*/
    dadosGeral?.forEach((dado, index) => {
      const img = new Image();
      img.src = logoImg;
      // margin-left, margin-top, largura, altura
      doc.addImage(img, 'JPEG', 15, 10, 72, 19);

      doc.setFontSize(17);
      doc.setTextColor(0, 0, 0);
      doc.setFontType('bold');
      // margin-left, margin-top
      doc.text('DECLARAÇÃO', 104, 47, 'center');
      doc.setFontSize(12);

      let posY: number;
      posY = 80;

      let quebraLinha = doc.splitTextToSize(
        `            ${dado?.EMPRESA_NOME.trim()}, pessoa jurídica de direito privado inscrita no CNPJ sob ` +
          `o nº. ${dado?.CNPJ.trim()}, com sede na ${dado?.ENDERECO.trim()}, em Fortaleza/CE, vem por meio do presente documento DECLARAR que o profissional ` +
          `abaixo informado é prestador de serviço de apoio ao serviço de saúde, e que, de acordo com o Plano de Operacionalização para ` +
          `Vacinação contra o COVID 19, expedido pelo Governo do Estado do Ceará, possui prioridade na vacinação.`,
        162,
      );
      doc.setFontType('normal');
      doc.text(quebraLinha, 25, posY);

      posY += 50;
      doc.setFontType('normal');
      doc.text('Nome do funcionário:', 25, posY, 'left');
      quebraLinha = doc.splitTextToSize(`${dado?.NOME.trim()}`, 70);
      doc.setFontType('normal');
      doc.text(quebraLinha, 67, posY, { align: 'left', maxWidth: 115 });
      posY += 10;

      doc.setFontType('normal');
      doc.text('CPF Nº:', 25, posY, 'left');
      quebraLinha = doc.splitTextToSize(`${maskCpf(dado?.CPF.trim())}`, 70);
      doc.setFontType('normal');
      doc.text(quebraLinha, 42, posY, { align: 'left', maxWidth: 140 });
      posY += 10;

      doc.setFontType('normal');
      doc.text('Hospital que presta serviço:', 25, posY, 'left');
      quebraLinha = doc.splitTextToSize(`${dado?.POSTO.trim()}`, 70);
      doc.setFontType('normal');
      doc.text(quebraLinha, 79, posY, { align: 'left', maxWidth: 104 });
      posY += 10;

      doc.setFontType('normal');
      doc.text('Função:', 25, posY, 'left');
      quebraLinha = doc.splitTextToSize(`${dado?.DESC_FUNCAO.trim()}`, 70);
      doc.setFontType('normal');
      doc.text(quebraLinha, 42, posY, { align: 'left', maxWidth: 138 });
      posY += 22;

      doc.text(
        'Por ser expressão da verdade, firma-se a presente.',
        103,
        posY,
        'center',
      );
      posY += 30;
      doc.text(`Fortaleza (CE), ${dataAtual}.`, 180, posY, {
        align: 'right',
        maxWidth: 80,
      });
      posY += 60;

      doc.setFontSize(10);
      doc.text(`${dado?.ENDERECO.trim()}`, 103, posY, {
        align: 'center',
        maxWidth: 140,
      });
      posY += 6;

      doc.setFontSize(10);
      doc.text(`TEL: (85) 3265.8000 - www.gruposervnac.com.br`, 103, posY, {
        align: 'center',
        maxWidth: 180,
      });
      posY += 13;

      doc.setFontSize(10);
      doc.setTextColor(179, 179, 179);
      doc.text(`${Criptografar(dado?.CPF.trim() || '')}`, 200, posY, 'right');

      index !== dadosGeral?.length - 1 && doc.addPage();
    });

    dadosGeral !== undefined &&
      dadosGeral?.length === 1 &&
      doc.save(
        `${dadosGeral[0].NOME.trim()} - ${format(
          new Date(),
          'dd-MM-yyyy',
        )}.pdf`,
      );

    dadosGeral !== undefined &&
      dadosGeral?.length > 1 &&
      doc.save(
        `DECLARAÇÕES DE VACINAÇÃO - ${format(new Date(), 'dd-MM-yyyy')}.pdf`,
      );
  }, [dadosGeral, dataAtual]);

  return (
    <>
      <div>
        <ContainerModal id="modal" onClick={(e) => handleOutSideClick(e)}>
          <Container>
            <ContainerButton>
              <button
                type="button"
                id="btnFechar"
                onClick={() => {
                  onClose();
                }}
              >
                <FiX />
              </button>
            </ContainerButton>
            <ContainerHeader>
              <p>Gerar Declaração(es)</p>
            </ContainerHeader>
            <div className="divGeral">
              <ContainerHeaderDados>
                {dadosGeral?.map((dado) => {
                  return (
                    <p className="titulo">
                      {`${dado?.NOME} - ${maskCpf(dado.CPF)}`}
                    </p>
                  );
                })}

                <img id="imgLogoId2" src={logoImg} alt="Logo Servnac" />
              </ContainerHeaderDados>
            </div>

            <ContainerButtonAtualizar>
              <button
                type="button"
                id="btnPrint"
                onClick={() => {
                  handleDownloadPDF();
                }}
              >
                Salvar
              </button>
            </ContainerButtonAtualizar>
          </Container>
        </ContainerModal>
      </div>
    </>
  );
};

export default ModalDeclaracaoVacinacao;
