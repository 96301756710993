import styled from 'styled-components';

export const ContainerModal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: 'center';
  align-items: 'center';
`;

export const Container = styled.div`
  @media print {
    #modalRelatorio {
      background-color: white;
      height: 100%;
      width: 100%;
      position: fixed;
      top: 0;
      left: 0;
      margin: 0;
      padding: 15px;
      font-size: 14px;
      line-height: 18px;
    }
  }
  width: 70%;
  min-width: 70vh;
  height: 90%;
  min-height: 90vh;
  background-color: #ffffff;
  color: #000000;
  border-radius: 5px;
  margin: auto auto;
  overflow-y: auto;
`;

export const ContainerColunas = styled.div`
  background: #f9f9f9;
  z-index: 2051;
  width: 50%;
  min-width: 50vh;
  height: 80%;
  min-height: 80vh;
  color: #000000;
  border-radius: 5px;
  margin: auto auto;
  box-shadow: -7px 4px 20px -7px rgb(0 0 0 / 75%);
  overflow-y: auto;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ContainerColunasHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background: rgb(0, 0, 0, 0.05);
  font-size: 1.3em;
  font-weight: 600;
`;

export const ContainerColunasBody = styled.div`
  display: flex;
  padding: 40px;
  flex-direction: column;
  height: 100%;
  input {
    margin-right: 10px;
  }
  label {
    display: flex;
    align-items: center;
    align-content: center;
  }
`;

export const ContainerColunasFooter = styled.div`
  display: flex;
  button {
    background-color: #006600;
    color: #ffffff;
    border: none;
    padding: 10px;
    margin: 5px;
    border-radius: 5px;
  }
  justify-content: flex-end;
  margin-right: 10px;
`;

export const ContainerButton = styled.div`
  width: auto;
  display: flex;
  button {
    border: transparent;
    background-color: transparent;
    padding: 2px;
    margin-left: auto;
  }
`;

export const ContainerHeader = styled.div`
  width: auto;
  display: flex;
  p {
    margin: 0px auto;
    font-size: 24px;
  }
`;

export const ContainerHeaderDados = styled.div`
  width: auto;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding: 0px 50px;
`;

export const ContainerPessoais = styled.div`
  td {
    font-weight: bold;
    & + td {
      padding-left: 10px;
      font-weight: normal;
    }
  }
`;

export const ContainerBodyDados = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 50px;
  margin-top: 20px;
`;

export const DivContainerDados = styled.div`
  display: flex;
  width: 100%;
  .coluna {
    width: 25%;
    p {
      margin-left: 5px;
      text-align: center;
      margin-bottom: 0px;
    }
  }
`;

export const TableRegistro = styled.table`
  td,
  th {
    border: 1px solid #222;
    font-size: 18px;
    justify-content: center;
    min-width: 50px;
    text-align: center;
    padding: 5px 10px;
  }
  td {
    &:nth-child(1) {
      font-weight: bold;
    }
  }
  margin-bottom: 80px;
  &:last-child {
    margin-bottom: 10px;
  }
`;

export const ContainerDadosDetalhes = styled.div`
  margin-top: 30px;
  padding: 0px 50px;
  margin-bottom: 30px;
`;

export const ContainerBoxBorder = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid #111111;
  padding: 10px;
`;
export const DadosDetalhes = styled.div`
  table {
    & + table {
      margin-top: 10px;
    }
  }
  td {
    font-weight: bold;
    & + td {
      padding-left: 10px;
      font-weight: normal;
      text-align: end;
    }
  }
  .tdColumn {
    min-width: 200px;
  }
`;

export const ContainerButtonPDF = styled.div`
  width: auto;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding: 0 50px;
  button {
    background-color: #006600;
    color: #ffffff;
    border: none;
    padding: 10px;
    margin: 5px;
    border-radius: 5px;
  }
`;
