import styled from "styled-components";

export const Container = styled.div`
  flex: 1;
  max-width: auto;
  align-items: center;
  max-height: 100vh;
`;

export const ContainerPesquisaGeral = styled.div`
  width: 90%;
  margin: 0px auto;
  margin-top: 15px;
  margin-bottom: 80px;
  padding: 55px 10px 30px 10px;
  background-color: #3e3946;
  overflow-x: auto;
  border-radius: 5px;
  h3 {
    text-align: center;
  }
`;

export const ContainerSupFilter = styled.div`
  display: flex;
  width: 100%;
  margin: 0px auto;
  justify-content: center;
  p {
    font-size: 17px;
    font-family: Arial;
    margin: 7px 5px 0px 10px;
  }
  select {
    background-color: #f1f1f1;
    color: #439133;
    border-radius: 10px;
    margin: 0px;
    margin-right: 10px;
    margin-left: 5px;
    border: none;
    justify-content: center;
    width: 120px;
    padding: 5px 10px;
  }
  input {
    background-color: #f1f1f1;
    color: #439133;
    border-radius: 10px;
    border: none;
    padding: 10px;
    height: 40px;
    width: 80px;
    margin-left: 10px;
  }
  
`;

export const ContainerInfFilter = styled.div`
  display: flex;
  margin-left: 20px;
  justify-content: center;

  .VisualizarPdf {
    background-color: #1661a7;
    &:hover {
      background-color: #1890ff;
    }
  }

  p {
    font-size: 17px;
    font-family: Arial;
    margin: 10px;
  }
  input {
    height: 20px;
    width: 20px;
  }
  label {
    font-size: 14px;
    text-align: center;
    font-family: Arial;
    width: 100px;
    padding: 10px 5px;
  }
  button {
    height: 40px;
    padding: 0px 10px;
    justify-content: center;
    align-content: center;
    background-color: #439133;
    color: #ffffff;
    border-radius: 5px;
    border: none;
    margin-right: 15px;
    &:hover {
      background-color: #57bd42;
    }
  }
  svg {
    margin-left: 3px;
  }
`;