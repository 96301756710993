import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-70px);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  height: auto;
  flex: 1;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const AnimationContainer = styled.div`
  display: flex;
  width: 100%;

  animation: ${appearFromLeft} 1s;
  .ContainerImage {
    margin: 0px auto;
    position: relative;
    h1 {
      margin: 50px;
      text-align: center;
    }
    img {
      width: 200px;
      height: 200px;
      border-radius: 50%;
    }
    label {
      position: absolute;
      width: 40px;
      height: 40px;
      background: #006000;
      border: none;
      border-radius: 50%;
      display: flex;
      left: 150px;
      top: 290px;
      align-items: center;
      justify-content: center;
      input {
        display: none;
      }
      svg {
        width: 15px;
        height: 15px;
        color: #ffffff;
      }
      &:hover {
        filter: brightness(0.9);
      }
    }
  }
  form {
    margin: 30px 0px;
    margin-right: auto;
    width: 340px;
    text-align: center;

    .ContainerSenhas {
      margin-top: 15px;
      h3 {
        margin-top: 30px;
        margin-bottom: 10px;
      }
    }
    a {
      color: #f4ede8;
      display: block;
      margin-top: 24px;
      text-decoration: none;
      transition: color 0.2s;

      &:hover {
        color: ${shade(0.2, '#f4ede8')};
      }
    }
  }
  /**Ele estiliza apenas o 'a' que estar dentro do content. Assim, ele não
  sobrescreve nenhuma estilização de quaisquer outro 'a' que esteja em outro
  componente. */
`;

/** export const Background = styled.div`
  flex: 1;
  background: url(${signInBackGroundImg}) no-repeat center;
  background-size: cover;
`;
*/
