import styled from 'styled-components';
import { shade } from 'polished';

export const ContainerFiltro = styled.div`
  width: 90%;
  margin: 0px auto;
  margin-top: 15px;
  background-color: #3e3946;
`;

export const Aba = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px 10px 20px;

  div.geralAba {
    display: flex;
  }

  .dropbtn {
    background-color: #006600;
    color: white;
    font-size: 16px;
    border: none;
    cursor: pointer;
  }

  .dropdown {
    position: sticky;
    display: inline-block;

    button {
      background-color: #006600;
      margin-left: 20px;
      border: none;
      height: 40px;
      width: 220px;
      //padding: 10px 20px 3px;
      padding-left: 20px;
      padding-right: 10px;
      border-radius: 5px;
      color: #ffffff;
      line-height: 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-content: center;
      align-items: center;
      p {
        //height: 100%;
        //width: 90%;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: transparent;
    width: auto;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    z-index: 1;
    margin-top: -3px;
    border-bottom-left-radius: 10px;

    border-bottom-right-radius: 10px;
  }

  .dropdown-content button {
    padding: 8px 12px;
    text-decoration: none;
    display: block;
    border-radius: 0px;
    width: 220px;
  }

  .dropdown-content button:hover {
    background-color: ${shade(0.2, '#006600')};
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  .dropdown:hover .dropbtn {
    background-color: #3e8e41;
  }
`;

export const DivIconCheck = styled.div`
  background-color: #59b300;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const DivIconClose = styled.div`
  background-color: #b33c00;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  padding-bottom: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TdIcons = styled.td`
  justify-content: center;
  align-content: center;
`;

export const DivIcons = styled.div`
  min-width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
`;
