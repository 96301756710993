/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useMemo, useCallback } from 'react';
// import Swal from 'sweetalert2';
import { FiEye, FiFile } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import DatePicker, { registerLocale } from 'react-datepicker';
import { differenceInMinutes } from 'date-fns/esm';
import { ptBR } from 'date-fns/locale';
import { format } from 'date-fns-tz';
import { GoFilePdf } from 'react-icons/go';
import { useAuth } from '../../hooks/auth';
import HeaderTable from '../../components/HeaderTable';
import Search from '../../components/Search';
import Modal from '../VFiltroPonto/ModalPdf/indexModal';
import ModalConexao from '../../components/ModalConexao';
import { Loading } from '../../components/Loading';
import api from '../../services/api';
import { maskCpf } from '../../components/Input/mask';
import {
  ResponseRelatorio as ResponseRelatorioWrong,
  ResponseGet as ResponseGetWrong,
} from '../VFiltroPonto/Interface/index';
import {
  formatarData,
  formatarDataMesAno,
  formatarMaxData,
} from '../../components/Formulas/formulas';
import {
  Container,
  ContainerSearchSup,
  ContainerSearchInf,
  Aba,
  ContainerAba,
  ContainerTable,
  ContainerTableMeses,
  ContainerTableEstatica,
  ContainerSemDados,
} from './styles';
import DrawerApp from '../../components/Drawer';
import {
  ContainerInfFilter,
  ContainerPagination,
  ContainerPesquisaGeral,
  ContainerSupFilter,
} from '../VFiltroPonto/styles';
import DropDown from '../../components/DropDown';
import { Filial } from '../../models/Filial';
// import { dataResponse } from './tempData';
import Pagination from '../../components/Pagination';
import CheckBox from '../../components/CheckBox';
import Tooltip from '../../components/Tooltip';
import { ModalRegistroPdf } from './ModalRegistroPdf/indexModal';
import { parseResponseRelatorio } from '../VFiltroPonto/ModalData/indexModal';

export type ResponseRelatorio = Omit<
  ResponseRelatorioWrong,
  'DIFERENCA_EM_MINUTOS_HORAS_A_TRABALHAR_HORAS_TRABALHADAS'
> & {
  DIFERENCA_EM_MINUTOS_HORAS_A_TRABALHAR_HORAS_TRABALHADAS: number | null;
  POSTO: string;
};

interface ResponseGet extends ResponseGetWrong {
  CPF: string;
  EMPRESA: string;
  COD_CLIENTE: string;
  DT_ADMISSAO: string;
  SITUACAO: string;
  PONTO_COMPETENCIA_PONTO: string;
  NOME_EMPRESA: string;
  CENTRO_CUSTO: string;
  EMAIL: string;
  DATA_FINAL_DIA_AGENDA: string;
}
// interface ResponseGet {
//   CENTRO_CUSTO: string;
//   PONTO_COMPETENCIA_PONTO: string;
//   CONTRATO: string;
//   CPF: string;
//   CPFSEMFORMATO: string;
//   EMPRESA: string;
//   NOME: string;
//   NOME_CLIENTE: string;
//   NOME_EMPRESA: string;
//   POSTO: string;
// }

interface ResponseGetNew extends ResponseGet {
  vazio: boolean;
}

const setores = [
  { valor: 'Todos', id: 15, NivelAcesso: 1 },
  { valor: 'Todos', id: 17, NivelAcesso: 1 },
];

const VisualizarPonto: React.FC = () => {
  registerLocale('pt-BR', ptBR);
  const { userAcesso } = useAuth();
  const history = useHistory();
  const [searchEmpresa, setSearchEmpresa] = useState('');
  const [searchCliente, setSearchCliente] = useState('');
  const [searchNome, setSearchNome] = useState('');
  const [searchCPF, setSearchCPF] = useState('');
  const [searchPosto, setSearchPosto] = useState('');
  const [searchContrato, setSearchContrato] = useState('');
  const [response, setResponse] = useState<ResponseGet[]>([]);
  const [responseUser, setResponseUser] = useState<ResponseGetNew[]>([]);
  const [responsePdf, setResponsePdf] = useState<ResponseRelatorioWrong[]>([]);
  const [responseRelatorio, setResponseRelatorio] = useState<
    ResponseRelatorio[]
  >([]);
  const [visualizarMeses, setVisualizarMeses] = useState(false);
  const [visualizarMes, setVisualizarMes] = useState(false);
  const [visualizarRelatorio, setVisualizarRelatorio] = useState(false);
  const [loading, setLoading] = useState(false);

  const dataAtual = new Date();
  const dataAtualFormatada = format(dataAtual, 'yyyy-MM-dd');
  const [dataInicial, setDataInicial] = useState<any>(new Date());
  const [dataFinal, setDataFinal] = useState<any>(new Date());
  const [dataInicialGet, setDataInicialGet] = useState(dataAtualFormatada);
  const [dataFinalGet, setDataFinalGet] = useState(dataAtualFormatada);
  const [filial, setFilial] = useState('');
  const [filiais, setFiliais] = useState<Filial[]>([]);
  const [maxDate, setMaxDate] = useState('');
  const [acessoOak] = useState(false);
  const [postos, setPostos] = useState<{ id: number; valor: string }[]>([]);
  const [posto, setPosto] = useState('');
  const [porcentagem, setPorcentagem] = useState(0);

  const [totalItemsGeral, setTotalItemsGeral] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalTabela, setTotalTabela] = useState(0);
  const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState<number>(20);

  const [checkPonto, setCheckPonto] = useState(true);

  const headers = [
    { name: 'Empresa', field: 'empresa' },
    { name: 'Cliente', field: 'cliente' },
    { name: 'Nome', field: 'nome' },
    { name: 'CPF', field: 'cpf' },
    { name: 'Contrato', field: 'contrato' },
    { name: 'Posto', field: 'posto' },
    { name: 'visualizar', field: 'visualizarFim' },
  ];

  const headersCompetencia = [
    { name: 'Competência', field: 'competencia' },
    { name: 'Gerar PDf', field: 'gerarPDF' },
  ];

  const dropDown = [
    { valor: '20', id: 20 },
    { valor: '30', id: 30 },
    { valor: '40', id: 40 },
    { valor: '50', id: 50 },
    { valor: 'todos', id: 1 },
  ];

  const dropDownFilialOAK = [
    { valor: 'Nenhum', id: 0, codigo: '000000' },
    { valor: '06 - OAK SVNK Tecnologia', id: 6, codigo: '999999888888' },
  ];

  useEffect(() => {
    let semAcesso = true;
    let access;

    // console.log('--------------- userAcesso ---------------');
    // console.log(userAcesso);

    userAcesso.forEach((user) => {
      access = setores.filter(
        (setor) =>
          setor.id === user.SetorID && user.NivelACesso >= setor.NivelAcesso,
      );
      // console.log('--------------- access ---------------');
      // console.log(access);
      if (access.length > 0) {
        // console.log('--------------- ENTROU NO SEMACESSO ---------------');
        semAcesso = false;
      }

      // console.log('--------------- semAcesso ---------------');
      // console.log(semAcesso);
    });

    if (semAcesso) {
      history.push('/dashboard');
    } else {
      setLoading(true);
      api
        .get('filiais/all')
        .then((resp) => {
          setFiliais(resp.data);
        })
        .catch((err) => {
          Swal.fire({
            icon: 'info',
            title: 'Erro ao carregar dados, por favor atualize a página!',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          });
          setLoading(false);
        });
      // setResponse(dataResponse);
      // console.log(dataResponse);
      setLoading(false);
      window.scrollTo(0, 0);
      setPorcentagem(0);

      // api.get(
      //   `/pontoEletronico/getEscalaWeb/${codigo}/${dataInicialGet}/${dataFinalGet}`,
      //   {
      //     onDownloadProgress(progress) {
      //       setPorcentagem((progress.loaded * 100) / progress.total);
      //     },
      //   },
      // );
      // api
      //   .get<ResponseGet[]>(`/pontoEletronico/getEscalaWebBuscalAllEmpresas`, {
      //     onDownloadProgress(progress) {
      //       setPorcentagem((progress.loaded * 100) / progress.total);
      //     },
      //   })
      //   .then(({ data }) => {
      //     setLoading(false);
      //     setResponse(data);
      //     let postosTemp: any = data.map((a) => a.POSTO);
      //     postosTemp = [...new Set(postosTemp)];
      //     postosTemp = postosTemp.map((a: string, i: number) => ({
      //       id: i + 1,
      //       valor: a,
      //     }));
      //     setPostos(postosTemp);
      //   })
      //   .catch(() => {
      //     setLoading(false);

      //     if (navigator.onLine) {
      //       Swal.fire({
      //         icon: 'info',
      //         title: 'Erro ao carregar dados, por favor atualize a página!',
      //         showClass: {
      //           popup: 'animate__animated animate__fadeInDown',
      //         },
      //         hideClass: {
      //           popup: 'animate__animated animate__fadeOutUp',
      //         },
      //       });
      //     }
      //   });
    }
  }, [userAcesso, history]);

  const filterUserResponse = useCallback(
    (data: ResponseGet): ResponseGet[] => {
      let computedFilter: ResponseGet[] = response;
      computedFilter = computedFilter.filter(
        (res) =>
          res.CPFSEMFORMATO === data.CPFSEMFORMATO &&
          res.PONTO_COMPETENCIA_PONTO,
      );
      return computedFilter;
    },
    [response],
  );

  function arrayUnique(data: any[]): any[] {
    const result: any[] = [];
    const map = new Map();
    data.forEach((item) => {
      if (!map.has(item.CPFSEMFORMATO) && item.CPFSEMFORMATO !== null) {
        map.set(item.CPFSEMFORMATO, true);
        result.push({
          NOME: item.NOME,
          PONTO_COMPETENCIA_PONTO: item.PONTO_COMPETENCIA_PONTO,
          CPFSEMFORMATO: item.CPFSEMFORMATO,
          NOME_CLIENTE: item.NOME_CLIENTE,
          EMPRESA: item.EMPRESA,
          NOME_EMPRESA: item.NOME_EMPRESA,
          CONTRATO: item.CONTRATO,
          CENTRO_CUSTO: item.CENTRO_CUSTO,
          CPF: item.CPF,
          POSTO: item.POSTO,
        });
      }
    });
    return result;
  }

  function arrayUniqueCompetencia(data: any[]): any[] {
    const result: any[] = [];
    const map = new Map();
    data.forEach((item) => {
      if (
        !map.has(item.PONTO_COMPETENCIA_PONTO) &&
        item.PONTO_COMPETENCIA_PONTO !== null
      ) {
        map.set(item.PONTO_COMPETENCIA_PONTO, true);
        result.push({
          NOME: item.NOME,
          PONTO_COMPETENCIA_PONTO: item.PONTO_COMPETENCIA_PONTO,
          CPFSEMFORMATO: item.CPFSEMFORMATO,
          NOME_CLIENTE: item.NOME_CLIENTE,
          EMPRESA: item.EMPRESA,
          NOME_EMPRESA: item.NOME_EMPRESA,
          CONTRATO: item.CONTRATO,
          CENTRO_CUSTO: item.CENTRO_CUSTO,
          CPF: item.CPF,
          POSTO: item.POSTO,
        });
      }
    });
    return result;
  }

  const completeOrder = useCallback((data: any[]): any[] => {
    if (data.length > 0) {
      const listOrder = data
        .map((i) => {
          return {
            ...i,
            vazio: false,
          };
        })
        .sort((a, b) => {
          if (
            Number(a.PONTO_COMPETENCIA_PONTO) >
            Number(b.PONTO_COMPETENCIA_PONTO)
          ) {
            return 1;
          }
          if (
            Number(a.PONTO_COMPETENCIA_PONTO) <
            Number(b.PONTO_COMPETENCIA_PONTO)
          ) {
            return -1;
          }
          return 0;
        });
      let anoIni = Number(listOrder[0].PONTO_COMPETENCIA_PONTO.slice(0, 4));
      let mesIni = Number(listOrder[0].PONTO_COMPETENCIA_PONTO.slice(4));
      const anoEnd = Number(
        listOrder[listOrder.length - 1].PONTO_COMPETENCIA_PONTO.slice(0, 4),
      );
      const mesEnd = Number(
        listOrder[listOrder.length - 1].PONTO_COMPETENCIA_PONTO.slice(4),
      );
      const listMesAno: string[] = [];
      while (anoIni !== anoEnd || mesEnd !== mesIni) {
        listMesAno.push(`${anoIni}${mesIni < 10 ? `0${mesIni}` : mesIni}`);
        if (mesIni === 12) {
          mesIni = 1;
          anoIni += 1;
        } else {
          mesIni += 1;
        }
      }
      listMesAno.push(`${anoIni}${mesIni < 10 ? `0${mesIni}` : mesIni}`);

      return listMesAno.map((item) => {
        const find = listOrder.find((i) => i.PONTO_COMPETENCIA_PONTO === item);
        if (find) {
          return find;
        }
        return {
          CENTRO_CUSTO: '',
          PONTO_COMPETENCIA_PONTO: item,
          CONTRATO: '',
          CPF: '',
          CPFSEMFORMATO: '',
          EMPRESA: '',
          NOME: '',
          NOME_CLIENTE: '',
          NOME_EMPRESA: '',
          POSTO: '',
          vazio: true,
        };
      });
    }

    return [];
  }, []);

  const responseData = useMemo(() => {
    let computedResponses: ResponseGet[] = [];
    computedResponses = arrayUnique(response);
    if (filial) {
      if (filial !== 'Nenhum') {
        const filialTemp = filial.split(' - ')[0];
        computedResponses = computedResponses.filter(
          (a) => a.EMPRESA === filialTemp,
        );
      }
    }

    if (posto) {
      if (posto !== 'Nenhum') {
        computedResponses = computedResponses.filter((a) => a.POSTO === posto);
      }
    }

    if (checkPonto) {
      computedResponses = computedResponses.filter(
        (a) => a.PONTO_COMPETENCIA_PONTO && a.PONTO_COMPETENCIA_PONTO !== '',
      );
    }

    if (searchEmpresa) {
      computedResponses = computedResponses.filter((res: ResponseGet) =>
        res.EMPRESA.toLowerCase().includes(searchEmpresa.toLowerCase()),
      );
    }
    if (searchCliente) {
      computedResponses = computedResponses.filter((res: ResponseGet) =>
        res.NOME_CLIENTE.toLowerCase().includes(searchCliente.toLowerCase()),
      );
    }
    if (searchNome) {
      computedResponses = computedResponses.filter((res: ResponseGet) =>
        res.NOME.toLowerCase().includes(searchNome.toLowerCase()),
      );
    }
    if (searchCPF) {
      let CPF: string = searchCPF.replace('.', '');
      CPF = CPF.replace('.', '');
      CPF = CPF.replace('-', '');
      computedResponses = computedResponses.filter((res: ResponseGet) =>
        res.CPF.toLowerCase().includes(CPF.toLowerCase()),
      );
    }
    if (searchContrato) {
      computedResponses = computedResponses.filter((res: ResponseGet) =>
        res.CONTRATO.toLowerCase().includes(searchContrato.toLowerCase()),
      );
    }
    if (searchPosto) {
      computedResponses = computedResponses.filter((res: ResponseGet) =>
        res.POSTO.toLowerCase().includes(searchPosto.toLowerCase()),
      );
    }
    setTotalTabela(computedResponses.length);
    setTotalItemsGeral(computedResponses.length);
    if (ITEMS_PER_PAGE === 1) {
      return computedResponses;
    }

    return computedResponses.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE,
    );
  }, [
    response,
    filial,
    posto,
    checkPonto,
    searchEmpresa,
    searchCliente,
    searchNome,
    searchCPF,
    searchContrato,
    searchPosto,
    currentPage,
    ITEMS_PER_PAGE,
  ]);

  const handleGerarRelatorio = useCallback(
    async (cpf: string, competencia: string) => {
      try {
        setLoading(true);
        await api
          .get(
            `/pontoEletronico/getEscalaWebCompetenciaFuncionario/${competencia}/${cpf}`,
          )
          .then((data) => {
            const allPontos: ResponseRelatorioWrong[] = data.data;
            /*
            DESC_DIA
            DESC_PERIODO
            DESC_FALTA
            DESC_ENTRADA
            DESC_PAUSA
            DESC_VOLTA
            DESC_SAIDA
            DATA_INICIAL_DIA_AGENDA
            DIAS_SEMANA
            PONTO_LATITUDE_ENTRADA
            PONTO_HORA_ENTRADA
            PONTO_HORA_PAUSA
            PONTO_HORA_VOLTA
            PONTO_HORA_SAIDA
            HORAS_TRABALHADAS_MENOS_HORA_PAUSA
            DIFERENCA_EM_MINUTOS_HORAS_A_TRABALHAR_HORAS_TRABALHADAS
            HORAS_A_TRABALHAR,
            */
            const unique = [
              ...new Map(
                allPontos.map((item) => [
                  `${item.DESC_DIA}-${item.DESC_PERIODO}-${item.DESC_FALTA}-${item.DESC_ENTRADA}-${item.DESC_PAUSA}-${item.DESC_VOLTA}-${item.DESC_SAIDA}-${item.DATA_INICIAL_DIA_AGENDA}-${item.DIAS_SEMANA}-${item.PONTO_LATITUDE_ENTRADA}-${item.PONTO_HORA_ENTRADA}-${item.PONTO_HORA_PAUSA}-${item.PONTO_HORA_VOLTA}-${item.PONTO_HORA_SAIDA}-${item.HORAS_TRABALHADAS_MENOS_HORA_PAUSA}-${item.DIFERENCA_EM_MINUTOS_HORAS_A_TRABALHAR_HORAS_TRABALHADAS}-${item.HORAS_A_TRABALHAR}`,
                  item,
                ]),
              ).values(),
            ];
            setResponsePdf(unique);
            // console.log('responsePDF', unique);
            // filtro
            setVisualizarMes(true);
            setLoading(false);
          });
      } catch (err) {
        setLoading(false);
        Swal.fire({
          icon: 'info',
          title: 'Erro ao carregar dados, tente novamente!',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        });
      }
    },
    [],
  );

  const generateData = (): ResponseRelatorio[] => {
    const tempData = response
      .filter((a) =>
        responseData.map((b) => b.CPFSEMFORMATO).includes(a.CPFSEMFORMATO),
      )
      .map((a) => {
        const HORAS_TRABALHADAS_MENOS_HORA_PAUSA = a.HORAS_TRABALHADAS_MENOS_HORA_PAUSA
          ? new Date(
              `${a.DATA_INICIAL_DIA_AGENDA} ${a.HORAS_TRABALHADAS_MENOS_HORA_PAUSA}`,
            )
          : null;
        const HORAS_A_TRABALHAR = a.HORAS_A_TRABALHAR
          ? new Date(`${a.DATA_INICIAL_DIA_AGENDA} ${a.HORAS_A_TRABALHAR}`)
          : null;

        return {
          NOME: a.NOME,
          NOME_EMPRESA: a.NOME_EMPRESA,
          MATRICULA: a.COD_CLIENTE,
          DT_ADMISSAO: a.DT_ADMISSAO,
          SITUACAO: a.SITUACAO,
          EMAIL: a.EMAIL,
          CPF: a.CPF,
          CPFSEMFORMATO: a.CPFSEMFORMATO,
          Telefone: '-',
          ESCALA: a.ESCALA,
          DIAS_SEMANA: format(
            new Date(`${a.DATA_INICIAL_DIA_AGENDA} 06:00:00`),
            'EEEE',
            {
              locale: ptBR,
            },
          ),
          HORAS_A_TRABALHAR: a.HORAS_A_TRABALHAR,
          DATA_INICIAL_DIA_AGENDA: a.DATA_INICIAL_DIA_AGENDA,
          DATA_FINAL_DIA_AGENDA: a.DATA_FINAL_DIA_AGENDA,
          HORA_INICIAL: a.HORA_INICIAL,
          HORA_FINAL: a.HORA_FINAL,
          DIFERENCA_EM_MINUTOS_HORAS_A_TRABALHAR_HORAS_TRABALHADAS:
            HORAS_TRABALHADAS_MENOS_HORA_PAUSA && HORAS_A_TRABALHAR
              ? differenceInMinutes(
                  HORAS_TRABALHADAS_MENOS_HORA_PAUSA,
                  HORAS_A_TRABALHAR,
                )
              : 0,
          Id_UsersAppColaborador: a.Id_UsersAppColaborador,
          PONTO_DATA_INICIAL_PONTO: a.PONTO_DATA_INICIAL_PONTO,
          PONTO_DATA_FINAL_PONTO: a.PONTO_DATA_FINAL_PONTO,
          PONTO_COMPETENCIA_PONTO: a.PONTO_COMPETENCIA_PONTO,
          PONTO_HORA_ENTRADA: a.PONTO_HORA_ENTRADA,
          PONTO_LATITUDE_ENTRADA: a.PONTO_LATITUDE_ENTRADA,
          PONTO_LONGITUDE_ENTRADA: a.PONTO_LONGITUDE_ENTRADA,
          PONTO_LINK_FOTO_ENTRADA: a.PONTO_LINK_FOTO_ENTRADA,
          PONTO_LATITUDE_PAUSA: a.PONTO_LATITUDE_PAUSA,
          PONTO_HORA_PAUSA: a.PONTO_HORA_PAUSA,
          PONTO_LONGITUDE_PAUSA: a.PONTO_LONGITUDE_PAUSA,
          PONTO_DATA_SAIDA: a.PONTO_DATA_SAIDA,
          PONTO_DATA_VOLTA: a.PONTO_DATA_VOLTA,
          PONTO_DATA_PAUSA: a.PONTO_DATA_PAUSA,
          PONTO_DATA_ENTRADA: a.PONTO_DATA_ENTRADA,
          PONTO_HORA_SAIDA: a.PONTO_HORA_SAIDA,
          PONTO_HORA_VOLTA: a.PONTO_HORA_VOLTA,
          HORAS_TRABALHADAS_MENOS_HORA_PAUSA:
            a.HORAS_TRABALHADAS_MENOS_HORA_PAUSA,
          DESC_MOTIVO_ABONO: a.DESC_MOTIVO_ABONO,
          DESC_ENTRADA: a.DESC_ENTRADA,
          DESC_PAUSA: a.DESC_PAUSA,
          DESC_VOLTA: a.DESC_VOLTA,
          DESC_SAIDA: a.DESC_SAIDA,
          DIA_ABONADO: a.DIA_ABONADO,
          DESC_DIA: a.DESC_DIA,
          PERIODO_ABONADO: a.PERIODO_ABONADO,
          DESC_PERIODO: a.DESC_PERIODO,
          FALTA_ABONADA: a.FALTA_ABONADA,
          DATA_ABONO_FALTA: a.DATA_ABONO_FALTA,
          DESC_FALTA: a.DESC_FALTA,
          POSTO: a.POSTO,
        };
      });
    return parseResponseRelatorio(tempData);
  };

  // useEffect(() => {
  //   const filialTemp = filial.split(' - ')[0];
  //   setResponseData(resp => resp.filter((a) => a.EMPRESA === filialTemp));
  //   console.log('RTEPOSNEPORNMPON', responseData[0]);
  //   console.log('FILIAL', filial);
  //   console.log('FILIALTemp', filialTemp);
  // }, [filial, responseData]);
  const handlePesquisar = useCallback(async () => {
    if (
      filial === '' ||
      filial === 'nenhuma' ||
      !(
        dataInicial !== (undefined || null) && dataFinal !== (undefined || null)
      )
    ) {
      Swal.fire({
        icon: 'info',
        text: 'Preencha os dados para Pesquisar',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
    } else {
      const codigo: string = filial.split(' - ')[0];

      window.scrollTo(0, 0);
      setPorcentagem(0);
      setLoading(true);
      await api
        .get<ResponseGet[]>(
          `/pontoEletronico/getEscalaWeb/${codigo}/${dataInicialGet}/${dataFinalGet}`,
          {
            onDownloadProgress(progress) {
              setPorcentagem((progress.loaded * 100) / progress.total);
            },
          },
        )
        .then(({ data }) => {
          setResponse(data);
          let postosTemp: any = data.map((a) => a.POSTO);
          postosTemp = [...new Set(postosTemp)];
          postosTemp = postosTemp.map((a: string, i: number) => ({
            id: i + 1,
            valor: a,
          }));
          setPostos(postosTemp);
          setResponseUser([]);
          setSearchCPF('');
          setSearchCliente('');
          setSearchNome('');
          setSearchPosto('');
          setSearchContrato('');
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          Swal.fire({
            icon: 'info',
            text: 'Erro ao carregar dados, por favor atualize a página!',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          });
        });
    }
  }, [dataFinal, dataFinalGet, dataInicial, dataInicialGet, filial]);

  return (
    <>
      <ModalConexao />
      <DrawerApp />
      {/* <Header /> */}
      <ContainerPesquisaGeral>
        <ContainerSupFilter>
          <label htmlFor="temPonto" style={{ marginRight: '50px' }}>
            <CheckBox
              onChangeCheck={(value: boolean) => {
                // // handleChangeCheck(item, value);
                // // console.log(item);
                // console.log(value);
                setCheckPonto((a) => !a);
              }}
              valorCheck={checkPonto}
            />
            <div>
              <p style={{ lineHeight: 1, fontSize: 13, marginTop: 0 }}>
                Tem ponto
              </p>
            </div>
          </label>
          <p>Filial: </p>
          {acessoOak && (
            <div
              style={{
                padding: 30,
                backgroundColor: '#cccc',
              }}
            >
              <DropDown
                onChangeItems={(value: string) => {
                  setFilial(value);
                }}
                objetoEnum={dropDownFilialOAK}
              />
            </div>
          )}
          {!acessoOak && (
            <DropDown
              onChangeItems={(value: string) => {
                setFilial(value);
              }}
              objetoEnum={[
                { valor: 'Nenhum', id: 0 },
                ...filiais.map((item, index) => ({
                  id: index + 1,
                  valor: `${item.importId} - ${item.name}`,
                })),
                // { valor: '9999 - Backup Fortes', id: 9999 },
              ]}
            />
          )}
          {responseData.length > 0 && (
            <>
              <p>Posto: </p>
              <DropDown
                onChangeItems={(value: string) => {
                  setPosto(value);
                }}
                objetoEnum={[{ valor: 'Nenhum', id: 0 }, ...postos]}
              />
            </>
          )}
          <p style={{ marginRight: -3, marginLeft: 5 }}>Período: </p>
          <DatePicker
            locale="pt-BR"
            selected={dataInicial}
            onChange={(date: any) => {
              setDataInicialGet(formatarData(date?.toLocaleString()));
              setDataInicial(date);
              setMaxDate(formatarMaxData(date?.toLocaleString()));
            }}
            selectsStart
            startDate={dataInicial}
            endDate={dataFinal}
            dateFormat="P"
            placeholderText="dd/mm/aaaa"
          />
          <DatePicker
            locale="pt-BR"
            selected={dataFinal}
            onChange={(date: any) => {
              setDataFinalGet(formatarData(date?.toLocaleString()));
              setDataFinal(date);
            }}
            selectsEnd
            startDate={dataInicial}
            endDate={dataFinal}
            minDate={dataInicial}
            maxDate={new Date(maxDate)}
            dateFormat="P"
            placeholderText="dd/mm/aaaa"
          />
          <ContainerInfFilter>
            <button
              type="button"
              onClick={() => {
                handlePesquisar();
              }}
            >
              Pesquisar
            </button>
          </ContainerInfFilter>
        </ContainerSupFilter>
      </ContainerPesquisaGeral>
      {/* <Tooltip
        title="Clique aqui para pesquisar informações específicas de um usuário."
        className="Branco"
        tamanho={300}
        marginLeftComponent="15%"
        marginTopComponent={-147}
        marginBottomComponent={55}
        widthComponent={60}
      >
        <ButtonFilter>
          <button
            type="button"
            onClick={() => setIsModalFindUser(!isModalFindUser)}
          >
            <FiSearch />
          </button>
        </ButtonFilter>
      </Tooltip> */}
      {!visualizarMeses && (
        <Container>
          <ContainerSearchSup>
            <Search
              onSearch={(value: string) => {
                setSearchEmpresa(value);
              }}
              nomePlaceHolder="Buscar Empresa"
            />
            <Search
              onSearch={(value: string) => {
                setSearchCliente(value);
              }}
              nomePlaceHolder="Buscar Cliente"
            />
            <Search
              onSearch={(value: string) => {
                setSearchNome(value);
              }}
              nomePlaceHolder="Buscar Nome"
            />
          </ContainerSearchSup>
          <ContainerSearchInf>
            <Search
              onSearch={(value: string) => {
                setSearchCPF(value);
              }}
              nomePlaceHolder="Buscar CPF"
            />
            <Search
              onSearch={(value: string) => {
                setSearchContrato(value);
              }}
              nomePlaceHolder="Buscar Contrato"
            />
            <Search
              onSearch={(value: string) => {
                setSearchPosto(value);
              }}
              nomePlaceHolder="Buscar Posto"
            />
          </ContainerSearchInf>
          <ContainerTable>
            <table>
              <HeaderTable headers={headers} />
              <tbody>
                {responseData.map((res) => (
                  <tr key={res.CPF}>
                    <td>{res.EMPRESA}</td>
                    <td>{res.NOME_CLIENTE}</td>
                    <td>{res.NOME}</td>
                    <td style={{ minWidth: 120 }}>{res.CPF}</td>
                    <td>{res.CONTRATO}</td>
                    <td>{res.POSTO}</td>
                    <td>
                      <button
                        type="button"
                        onClick={() => {
                          setVisualizarMeses(true);
                          setResponseUser(
                            completeOrder(
                              arrayUniqueCompetencia(filterUserResponse(res)),
                            ),
                          );
                        }}
                      >
                        <FiEye />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </ContainerTable>
          <ContainerPagination>
            <div className="divPaginacao">
              <Pagination
                total={totalItemsGeral}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page: number) => setCurrentPage(page)}
              />
              <DropDown
                onChangeItems={(value: string) => {
                  setCurrentPage(1);
                  if (value === 'todos') {
                    setITEMS_PER_PAGE(1);
                  } else {
                    setITEMS_PER_PAGE(Number(value));
                  }
                }}
                objetoEnum={dropDown}
                minWidth={30}
              />
              {/* <Tooltip
                title="Exportar em Excel."
                className="Branco"
                tamanho={160}
                marginLeftComponent={100}
                marginRightComponent={20}
                marginTopComponent={0}
                widthComponent={0}
              >
                <div style={{ marginLeft: -70 }}>
                  <ReactHTMLTableToExcel
                    id="export-excel"
                    // className="btn"
                    table="geral"
                    filename="gerenciamento_ponto"
                    sheet="tablexls"
                    buttonText={
                      <DivButtonExcel>
                        <RiFileExcel2Line style={{ height: 25, width: 25 }} />
                      </DivButtonExcel>
                    }
                  />
                </div>
              </Tooltip> */}
              <Tooltip
                title="Exportar em PDF."
                className="Branco"
                tamanho={160}
                marginLeftComponent={100}
                marginTopComponent={0}
                widthComponent={0}
              >
                <button
                  className="buttonPdf"
                  type="button"
                  onClick={() => {
                    // exportPdfGeral(
                    //   responseDataSimple,
                    //   filial,
                    //   formatarDataAnoMesDia(dataInicialGet),
                    //   formatarDataAnoMesDia(dataFinalGet),
                    // );
                    setVisualizarRelatorio(true);
                    setResponseRelatorio(generateData());
                  }}
                >
                  <GoFilePdf />
                </button>
              </Tooltip>
            </div>

            <div style={{}}>
              <p>Pessoas: {totalTabela}</p>
            </div>
          </ContainerPagination>
        </Container>
      )}
      {visualizarMeses && responseUser.length > 0 && (
        <>
          <Aba>
            <ContainerAba className="aba" cor={false}>
              <button
                type="button"
                onClick={() => {
                  setVisualizarMeses(false);
                  setVisualizarRelatorio(false);
                  setSearchEmpresa('');
                  setSearchNome('');
                  setSearchCliente('');
                  setSearchContrato('');
                  setSearchCPF('');
                  setSearchPosto('');
                }}
              >
                Voltar
              </button>
            </ContainerAba>
          </Aba>
          <ContainerTableEstatica>
            <table>
              <tbody>
                <tr key="a">
                  <td style={{ minWidth: 250 }}>
                    Empresa: {responseUser[0].NOME_EMPRESA}
                  </td>
                  <td style={{ minWidth: 240, maxWidth: 250 }}>
                    Cliente: {responseUser[0].NOME_CLIENTE}
                  </td>
                </tr>
                <tr key="b">
                  <td style={{ minWidth: 250 }}>
                    Nome: {responseUser[0].NOME}
                  </td>
                  <td style={{ minWidth: 100 }}>
                    CPF: {maskCpf(responseUser[0].CPFSEMFORMATO)}
                  </td>
                </tr>
                <tr key="c">
                  <td style={{ maxWidth: 250 }}>
                    Posto: {responseUser[0].POSTO}
                  </td>
                  <td>Contrato: {responseUser[0].CONTRATO}</td>
                </tr>
              </tbody>
            </table>
          </ContainerTableEstatica>
          <ContainerTableMeses>
            <table>
              <HeaderTable headers={headersCompetencia} />
              <tbody>
                {responseUser.map(
                  (res, index) =>
                    res.PONTO_COMPETENCIA_PONTO && (
                      <tr
                        key={`${res.CPF + index + res.PONTO_COMPETENCIA_PONTO}`}
                      >
                        <td>
                          {formatarDataMesAno(res.PONTO_COMPETENCIA_PONTO)}
                        </td>
                        {res.vazio ? (
                          <td>Sem dados</td>
                        ) : (
                          <td>
                            <button
                              type="button"
                              onClick={() => {
                                handleGerarRelatorio(
                                  res.CPFSEMFORMATO,
                                  res.PONTO_COMPETENCIA_PONTO,
                                );
                              }}
                            >
                              <FiFile />
                            </button>
                          </td>
                        )}
                      </tr>
                    ),
                )}
              </tbody>
            </table>
          </ContainerTableMeses>
        </>
      )}
      {visualizarMeses && !(responseUser.length > 0) && (
        <>
          <Aba>
            <ContainerAba className="aba" cor={false}>
              <button
                type="button"
                onClick={() => {
                  setVisualizarMeses(false);
                  setVisualizarRelatorio(false);
                  setSearchEmpresa('');
                  setSearchNome('');
                  setSearchCliente('');
                  setSearchContrato('');
                  setSearchCPF('');
                  setSearchPosto('');
                }}
              >
                Voltar
              </button>
            </ContainerAba>
          </Aba>
          <ContainerSemDados>
            <h3>Não existe competência para esse funcionário.</h3>
          </ContainerSemDados>
        </>
      )}
      {visualizarMes && (
        <>
          {responsePdf && (
            <Modal
              dados={responsePdf}
              onClose={() => setVisualizarMes(false)}
            />
          )}
        </>
      )}
      {responseRelatorio && visualizarRelatorio && (
        <ModalRegistroPdf
          dados={responseRelatorio}
          onClose={() => setVisualizarRelatorio(false)}
        />
      )}
      {loading && <Loading porcentagem={porcentagem} />}
    </>
  );
};

export default VisualizarPonto;
