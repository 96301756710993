import React, { useState, useMemo, useCallback } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { FiEye } from 'react-icons/fi';
import { MdAlarmOff } from 'react-icons/md';
import { GoFilePdf } from 'react-icons/go';
import Swal from 'sweetalert2';
import Search from '../../../components/Search';
import HeaderTable from '../../../components/HeaderTable';
import { maskCpf } from '../../../components/Input/mask';
import DropDown from '../../../components/DropDown';
import Pagination from '../../../components/Pagination';
import { formatarDataAnoMesDia } from '../../../components/Formulas/formulas';
import 'react-datepicker/dist/react-datepicker.css';
import { ResponseGet } from '../Interface/index';
import { exportPdfPontoDetalhado } from '../ExportPdf/tablePontoDetalhado';
import PontoTodosDetalhado from '../TablesExportExcel/ponto';
import {
  Container,
  Aba,
  ContainerAba,
  ContainerFiltro,
  ContainerFilter,
  ContainerSearchTodos,
  ContainerTable,
  ContainerPagination,
} from './styles';
import ModalAbonoBatida from '../ModalAbonoBatida';
import { AbonoInterface, DropdownInterface } from '..';
import ModalAbonoDia from '../ModalAbonoDia';
import ModalAbonoPeriodo from '../ModalAbonoPeriodo';
import api from '../../../services/api';
import { Loading } from '../../../components/Loading';

interface DadosTable {
  dados: ResponseGet[];
  dadosCompletos: ResponseGet[];
  abono: AbonoInterface[];
  empresa: string;
  dataInicial: string;
  dataFinal: string;
  valor: number;
  onChange: () => void;
  onVisualize: (dados: ResponseGet) => void;
}

const TabelaExibicao: React.FC<DadosTable> = ({
  dados,
  dadosCompletos,
  empresa,
  dataInicial,
  abono,
  dataFinal,
  valor,
  onChange,
  onVisualize,
}) => {
  const [searchCliente, setSearchCliente] = useState('');
  const [searchContrato, setSearchContrato] = useState('');
  const [searchPosto, setSearchPosto] = useState('');
  const [searchNome, setSearchNome] = useState('');
  const [searchCPF, setSearchCPF] = useState('');
  const [totalTabela, setTotalTabela] = useState(0);

  const [responseUser, setResponseUser] = useState<ResponseGet[]>([]);
  const [arrayId, setArrayId] = useState<number[]>([]);

  const [verModalAbonoBatida, setVerModalAbonoBatida] = useState(false);
  const [verModalAbonoDia, setVerModalAbonoDia] = useState(false);
  const [verModalAbonoPeriodo, setVerModalAbonoPeriodo] = useState(false);

  const [abonoDropDown] = useState<DropdownInterface[]>([]);

  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState<number>(20);

  const [loading, setLoading] = useState(false);

  const dropDown = [
    { valor: '20', id: 20 },
    { valor: '30', id: 30 },
    { valor: '40', id: 40 },
    { valor: '50', id: 50 },
    { valor: 'todos', id: 1 },
  ];

  const abas = [
    { name: 'Ponto', field: 'ponto', id: 1 },
    { name: 'Hora Extra', field: 'he', id: 2 },
    { name: 'Falta', field: 'falta', id: 3 },
    { name: 'Atraso', field: 'atraso', id: 4 },
    // { name: 'Suspensão', field: 'suspensao' },
    { name: 'Férias', field: 'ferias', id: 6 },
    // { name: 'Licença', field: 'licenca' },
    { name: 'Afastamento', field: 'afasta', id: 8 },
    { name: 'Local Divergente', field: 'ponto', id: 9 },
    { name: 'Hora Divergente', field: 'horaDivergente', id: 11 },
    { name: 'Abono', field: 'abono', id: 12 },
  ];

  const headersPonto = [
    { name: '', field: 'visualizar' },
    { name: 'Cliente', field: 'cliente' },
    { name: 'Nome', field: 'nome' },
    { name: 'CPF', field: 'cpf' },
    { name: 'Contrato', field: 'contrato' },
    { name: 'Posto', field: 'posto' },
    { name: 'Função', field: 'funcao' },
    { name: 'Ponto', field: 'ponto' },
  ];

  const headersHoraExtra = [
    { name: '', field: 'visualizar' },
    { name: 'Cliente', field: 'cliente' },
    { name: 'Nome', field: 'nome' },
    { name: 'CPF', field: 'cpf' },
    { name: 'Contrato', field: 'contrato' },
    { name: 'Posto', field: 'posto' },
    { name: 'Função', field: 'funcao' },
    // { name: 'Data', field: 'data' },
    // { name: 'Horas à trabalhar', field: 'horaATrabalhar' },
    // { name: 'Horas Trabalhadas', field: 'horaTrabalhadas' },
  ];

  const headersFalta = [
    { name: '', field: 'visualizar' },
    { name: 'Cliente', field: 'cliente' },
    { name: 'Nome', field: 'nome' },
    { name: 'CPF', field: 'cpf' },
    { name: 'Contrato', field: 'contrato' },
    { name: 'Posto', field: 'posto' },
    { name: 'Função', field: 'funcao' },
  ];

  const headersAtraso = [
    { name: '', field: 'visualizar' },
    { name: 'Cliente', field: 'cliente' },
    { name: 'Nome', field: 'nome' },
    { name: 'CPF', field: 'cpf' },
    { name: 'Contrato', field: 'contrato' },
    { name: 'Posto', field: 'posto' },
    { name: 'Função', field: 'funcao' },
    // { name: 'Data', field: 'data' },
    // { name: 'Horas à Trabalhar', field: 'horaATrabalhar' },
    // { name: 'Horas Trabalhadas', field: 'horaTrabalhada' },
  ];

  const headersFerias = [
    { name: '', field: 'visualizar' },
    { name: 'Cliente', field: 'cliente' },
    { name: 'Nome', field: 'nome' },
    { name: 'CPF', field: 'cpf' },
    { name: 'Contrato', field: 'contrato' },
    { name: 'Posto', field: 'posto' },
    { name: 'Função', field: 'funcao' },
    { name: 'Data Inicial', field: 'dataInicial' },
    { name: 'Data Final', field: 'dataFinal' },
    { name: 'Data Volta', field: 'dataVolta' },
  ];

  const headersAfastamento = [
    { name: '', field: 'visualizar' },
    { name: 'Cliente', field: 'cliente' },
    { name: 'Nome', field: 'nome' },
    { name: 'CPF', field: 'cpf' },
    { name: 'Contrato', field: 'contrato' },
    { name: 'Posto', field: 'posto' },
    { name: 'Função', field: 'funcao' },
    { name: 'Data Inicial', field: 'dataInicial' },
    { name: 'Data Final', field: 'dataFinal' },
    { name: 'Data Volta', field: 'dataVolta' },
    { name: 'Motivo', field: 'motivo' },
  ];

  const headersPontoDivergente = [
    { name: '', field: 'visualizar' },
    { name: 'Cliente', field: 'cliente' },
    { name: 'Nome', field: 'nome' },
    { name: 'CPF', field: 'cpf' },
    { name: 'Contrato', field: 'contrato' },
    { name: 'Posto', field: 'posto' },
    { name: 'Função', field: 'funcao' },
    /* { name: 'Data', field: 'data' },
    { name: 'Latitude Posto', field: 'latitudePosto' },
    { name: 'Longitude Posto', field: 'longitudePosto' },
    { name: 'Latitude Real', field: 'latitudeReal' },
    { name: 'Longitude Real', field: 'longitudefalseeal' },
    { name: 'Distância', field: 'distancia' }, */
  ];
  const headersHoraDivergente = [
    { name: '', field: 'visualizar' },
    { name: 'Cliente', field: 'cliente' },
    { name: 'Nome', field: 'nome' },
    { name: 'CPF', field: 'cpf' },
    { name: 'Contrato', field: 'contrato' },
    { name: 'Posto', field: 'posto' },
    { name: 'Função', field: 'funcao' },
    // { name: 'Data', field: 'data' },
    // { name: 'Hora Escala', field: 'horaEscala' },
    // { name: 'Hora Ponto', field: 'horaPonto' },
  ];

  const headersAbono = [
    { name: '', field: 'visualizar' },
    { name: 'Cliente', field: 'cliente' },
    { name: 'Nome', field: 'nome' },
    { name: 'CPF', field: 'cpf' },
    { name: 'Contrato', field: 'contrato' },
    { name: 'Posto', field: 'posto' },
    { name: 'Função', field: 'funcao' },
  ];

  const responseData = useMemo(() => {
    let computedResponses: ResponseGet[] = [];
    computedResponses = dados;
    switch (valor) {
      case 1: {
        computedResponses = computedResponses.filter(
          (res: ResponseGet) => !res.SEM_PONTO,
        );
        break;
      }
      case 2: {
        computedResponses = computedResponses.filter(
          (res: ResponseGet) => res.EXTRA,
        );
        break;
      }
      case 3: {
        computedResponses = computedResponses.filter(
          (res: ResponseGet) => res.SEM_PONTO,
        );
        break;
      }
      case 4: {
        computedResponses = computedResponses.filter(
          (res: ResponseGet) => res.ATRASO,
        );
        break;
      }
      case 6: {
        computedResponses = computedResponses.filter(
          (res: ResponseGet) => res.FERIAS,
        );
        break;
      }
      case 8: {
        computedResponses = computedResponses.filter(
          (res: ResponseGet) => res.AFASTAMENTO,
        );
        break;
      }
      case 9: {
        computedResponses = computedResponses.filter(
          (res: ResponseGet) => res.PONTO_DIVERGENTE,
        );
        break;
      }
      case 11: {
        computedResponses = computedResponses.filter(
          (res: ResponseGet) => res.HORA_DIVERGENTE,
        );
        break;
      }
      case 12: {
        computedResponses = computedResponses.filter(
          (res: ResponseGet) => res.ABONO || res.FALTA_ABONADA,
        );
        break;
      }
      case 13: {
        computedResponses = computedResponses.filter(
          (res: ResponseGet) => res.SEM_PONTO,
        );
        break;
      }
      default: {
        break;
      }
    }

    if (searchCliente) {
      computedResponses = computedResponses.filter((res: ResponseGet) =>
        res.NOME_CLIENTE.toLowerCase().includes(searchCliente.toLowerCase()),
      );
    }
    if (searchContrato) {
      computedResponses = computedResponses.filter((res: ResponseGet) =>
        res.CONTRATO.toLowerCase().includes(searchContrato.toLowerCase()),
      );
    }
    if (searchNome) {
      computedResponses = computedResponses.filter((res: ResponseGet) =>
        res.NOME.toLowerCase().includes(searchNome.toLowerCase()),
      );
    }
    if (searchCPF) {
      let CPF: string = searchCPF.replace('.', '');
      CPF = CPF.replace('.', '');
      CPF = CPF.replace('-', '');
      computedResponses = computedResponses.filter((res: ResponseGet) =>
        res.CPFSEMFORMATO.toLowerCase().includes(CPF.toLowerCase()),
      );
    }
    if (searchPosto) {
      computedResponses = computedResponses.filter((res: ResponseGet) =>
        res.POSTO.toLowerCase().includes(searchPosto.toLowerCase()),
      );
    }
    setTotalTabela(computedResponses.length);
    setTotalItems(computedResponses.length);

    if (ITEMS_PER_PAGE === 1) {
      return computedResponses;
    }

    return computedResponses.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE,
    );
  }, [
    ITEMS_PER_PAGE,
    currentPage,
    dados,
    searchCPF,
    searchCliente,
    searchContrato,
    searchNome,
    searchPosto,
    valor,
  ]);

  const handleApagarAbono = useCallback(
    async (resUser: ResponseGet[], tempArrayId: number[]) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const arrayApagarAbono: any[] = [];
      const arrayDataApagarAbono: string[] = [];
      tempArrayId.forEach((num) => {
        if (num > 0) {
          arrayApagarAbono.push(num);
        } else {
          arrayApagarAbono.push(null);
        }
      });
      resUser.forEach((dado) => {
        if (tempArrayId.includes(dado.Id_PontoEletronico)) {
          arrayDataApagarAbono.push(dado.DATA_INICIAL_DIA_AGENDA);
        }
      });
      try {
        setLoading(true);
        await api
          .delete('/pontoEletronico/deleteAbono/', {
            data: {
              idPontoEletronico: arrayApagarAbono,
              dataAbono: arrayDataApagarAbono,
              cpf: resUser[0].CPFSEMFORMATO,
            },
          })
          .then(() => {
            setLoading(false);
            Swal.fire({
              icon: 'success',
              text: 'Abono apagado com sucesso!',
              showConfirmButton: false,
              timer: 1500,
            });
          })
          .catch(() => {
            setLoading(false);
            Swal.fire({
              icon: 'error',
              text: 'Erro, ao apagar o abono.',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            });
          });
      } catch (err) {
        setLoading(false);
        Swal.fire({
          icon: 'info',
          text: 'Erro ao apagar dados, tente novamente!',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        });
      }
    },
    [],
  );

  const verificaSeTemAbono = useCallback(
    (dadosRes: ResponseGet[], resUser: ResponseGet) => {
      const tempUser = dadosCompletos.filter(
        (a) => a.Id_UsersAppColaborador === resUser.Id_UsersAppColaborador,
      );
      const tempArrayId = tempUser.map((val) => val.Id_PontoEletronico);

      let resultado = false;
      dadosRes.forEach((dado) => {
        if (tempArrayId.includes(dado.Id_PontoEletronico)) {
          if (
            !(
              dado.PERIODO_ABONADO ||
              dado.DIA_ABONADO ||
              dado.FALTA_ABONADA ||
              dado.ENTRADA_ABONADA ||
              dado.PAUSA_ABONADA ||
              dado.VOLTA_ABONADA ||
              dado.SAIDA_ABONADA
            )
          ) {
            resultado = true;
          }
        }
      });
      if (!(tempArrayId.length > 0)) {
        Swal.fire({
          icon: 'info',
          text: 'Não tem nenhuma dia marcado para abonar.',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        });
      } else if (resultado) {
        Swal.fire({
          icon: 'info',
          text: 'Tem dia marcado que não possui abono, por favor desmarque.',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        });
      } else {
        Swal.fire({
          text: 'Tem certeza que deseja apagar o Abono?',
          // text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'Não',
          cancelButtonColor: '#d33',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Sim',
        }).then((result) => {
          if (result.isConfirmed) {
            handleApagarAbono(tempUser, tempArrayId);
          }
        });
      }
    },
    [dadosCompletos, handleApagarAbono],
  );

  const preencherDropDown = useCallback(
    (drop: AbonoInterface[], idDrop: string) => {
      const array = JSON.parse(JSON.stringify(abonoDropDown));
      array.forEach((a: DropdownInterface) => {
        abonoDropDown.splice(abonoDropDown.indexOf(a), 1);
      });
      abonoDropDown.push({
        id: -1,
        valor: 'Nenhum',
      });
      drop.forEach((a) => {
        if (a.NOME === idDrop || idDrop === 'Todos') {
          abonoDropDown.push({
            id: a.ID,
            valor: a.NOME,
          });
        }
      });
    },
    [abonoDropDown],
  );

  // useEffect(() => {
  //   if (setaAbono === 'AiFillCaretDown') {
  //     setDropDownOver(true);
  //   } else setDropDownOver(false);
  // }, [setaAbono]);
  const setUser = (
    res: ResponseGet,
    apagar?: boolean,
    abonar?: boolean,
  ): void => {
    const tempUser = dadosCompletos.filter(
      (a) => a.CPFSEMFORMATO === res.CPFSEMFORMATO,
    );
    const tempUserAbono = dadosCompletos.filter(
      (dado) =>
        dado.Id_UsersAppColaborador === res.Id_UsersAppColaborador &&
        !(
          dado.PERIODO_ABONADO ||
          dado.DIA_ABONADO ||
          dado.FALTA_ABONADA ||
          dado.ENTRADA_ABONADA ||
          dado.PAUSA_ABONADA ||
          dado.VOLTA_ABONADA ||
          dado.SAIDA_ABONADA
        ),
    );
    if (abonar) setResponseUser(tempUserAbono);
    else setResponseUser(tempUser);
    setArrayId(
      tempUser
        .filter((val) => val.Id_PontoEletronico)
        .map((val) => val.Id_PontoEletronico),
    );
    if (!apagar) preencherDropDown(abono, 'Todos');
    else preencherDropDown(abono, '');
  };

  return (
    <>
      {loading && <Loading />}
      <ContainerFiltro>
        <Aba>
          <ContainerAba className="aba" cor={false}>
            <button
              type="button"
              onClick={() => {
                onChange();
              }}
            >
              Voltar
            </button>
          </ContainerAba>
          {abas.map(
            (head) =>
              head.id === valor && (
                <ContainerAba key={head.field} className="aba" cor>
                  <button type="button">{head.name}</button>
                </ContainerAba>
              ),
          )}
        </Aba>
        <Container>
          <ContainerSearchTodos>
            <Search
              onSearch={(value: string) => {
                setSearchCliente(value);
              }}
              nomePlaceHolder="Buscar Cliente"
            />
            <Search
              onSearch={(value: string) => {
                setSearchNome(value);
              }}
              nomePlaceHolder="Buscar Nome"
            />
            <Search
              onSearch={(value: string) => {
                setSearchCPF(value);
              }}
              nomePlaceHolder="Buscar CPF"
            />
            <Search
              onSearch={(value: string) => {
                setSearchContrato(value);
              }}
              nomePlaceHolder="Buscar Contrato"
            />
            <Search
              onSearch={(value: string) => {
                setSearchPosto(value);
              }}
              nomePlaceHolder="Buscar Posto"
            />
          </ContainerSearchTodos>
        </Container>
      </ContainerFiltro>
      {responseData.length <= 0 && (
        <ContainerFilter>
          <h3>Não existe dados para essa filtragem.</h3>
        </ContainerFilter>
      )}

      <ContainerTable>
        {valor === 1 && responseData.length > 0 && (
          <>
            <div style={{ display: 'none' }}>
              <PontoTodosDetalhado
                dados={responseData}
                dadosCompletos={dadosCompletos}
              />
            </div>
            <table style={{ minHeight: '270px' }} id="pontoEscala">
              <HeaderTable headers={headersPonto} />
              <tbody>
                {responseData.map((res: ResponseGet, index) => (
                  <tr key={res.CPFSEMFORMATO + index.toString()}>
                    <td>
                      <button
                        type="button"
                        onClick={() => {
                          onVisualize(res);
                        }}
                      >
                        <FiEye />
                      </button>
                    </td>
                    <td style={{ minWidth: 250 }}>{res.NOME_CLIENTE}</td>
                    <td style={{ minWidth: 250 }}>{res.NOME}</td>
                    <td style={{ minWidth: 120 }}>
                      {maskCpf(res.CPFSEMFORMATO)}
                    </td>
                    <td>{res.CONTRATO}</td>
                    <td style={{ minWidth: 150 }}>{res.POSTO}</td>
                    <td>{res.FUNCAO}</td>
                    <td>
                      {/*
                      // opção para Editar ponto, não implementada ainda
                      <button
                        className="buttonOptionsTable"
                        type="button"
                        title="Editar Ponto"
                        onClick={() => {
                          // setUser();
                          setVerModalAbonoBatida(true);
                        }}
                      >
                        <MdEdit />
                      </button> */}
                      <div className="dropup">
                        <button
                          className="buttonOptionsTable dropbtn"
                          type="button"
                          title="Abono"
                        >
                          <MdAlarmOff />
                        </button>
                        <div className="dropup-content">
                          <>
                            <button
                              className="buttonAbonar"
                              type="button"
                              onClick={() => {
                                setUser(res);
                                setVerModalAbonoBatida(true);
                              }}
                            >
                              Abonar Batida
                            </button>

                            <button
                              className="buttonAbonar"
                              type="button"
                              onClick={() => {
                                setUser(res);
                                setVerModalAbonoDia(true);
                              }}
                            >
                              Abonar Dia
                            </button>

                            <button
                              className="buttonAbonar"
                              type="button"
                              onClick={() => {
                                setUser(res);
                                setVerModalAbonoPeriodo(true);
                              }}
                            >
                              Abonar Período
                            </button>

                            <button
                              className="buttonAbonar"
                              type="button"
                              onClick={() => {
                                setUser(res, true, true);
                                verificaSeTemAbono(
                                  dadosCompletos.filter(
                                    (a) =>
                                      a.CPFSEMFORMATO === res.CPFSEMFORMATO,
                                  ),
                                  res,
                                );
                              }}
                            >
                              Apagar Abono
                            </button>
                          </>
                        </div>
                      </div>
                    </td>
                  </tr> // trazer as funcionalidades do abonar para cá, provavelmente o dropdown todo de abonar
                ))}
              </tbody>
            </table>
          </>
        )}
        {verModalAbonoBatida && responseUser && arrayId && (
          <ModalAbonoBatida
            onClose={() => setVerModalAbonoBatida(false)}
            onSave={() => {
              setVerModalAbonoBatida(false);
            }}
            dados={responseUser}
            arrayId={arrayId}
            abono={abonoDropDown}
          />
        )}
        {verModalAbonoDia && responseUser && arrayId && (
          <ModalAbonoDia
            onClose={() => setVerModalAbonoDia(false)}
            onSave={() => {
              setVerModalAbonoDia(false);
              // setRefresh(!refresh);
              // setConsulta(true);
            }}
            dados={responseUser}
            arrayId={arrayId}
            abono={abonoDropDown}
          />
        )}
        {verModalAbonoPeriodo && responseUser && arrayId && (
          <ModalAbonoPeriodo
            onClose={() => setVerModalAbonoPeriodo(false)}
            onSave={() => {
              setVerModalAbonoPeriodo(false);
              // setRefresh(!refresh);
              // setConsulta(true);
            }}
            dados={responseUser}
            arrayId={arrayId}
            abono={abonoDropDown}
          />
        )}

        {valor === 2 && responseData.length > 0 && (
          <table id="horaExtra">
            <HeaderTable headers={headersHoraExtra} />
            <tbody>
              {responseData.map((res: ResponseGet, index) => (
                <tr key={res.CPFSEMFORMATO + index.toString()}>
                  <td>
                    <button
                      type="button"
                      onClick={() => {
                        onVisualize(res);
                      }}
                    >
                      <FiEye />
                    </button>
                  </td>
                  <td style={{ minWidth: 250 }}>{res.NOME_CLIENTE}</td>
                  <td style={{ minWidth: 250 }}>{res.NOME}</td>
                  <td style={{ minWidth: 120 }}>
                    {maskCpf(res.CPFSEMFORMATO)}
                  </td>
                  <td>{res.CONTRATO}</td>
                  <td style={{ minWidth: 150 }}>{res.POSTO}</td>
                  <td>{res.FUNCAO}</td>
                  {/* <td style={{ minWidth: 100 }}>
                    {formatarDataAnoMesDia(res.DATA_INICIAL_DIA_AGENDA)}
                  </td>
                  <td>{res.HORAS_A_TRABALHAR}</td>
                    <td>{res.HORAS_TRABALHADAS_MENOS_HORA_PAUSA}</td> */}
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {valor === 3 && responseData.length > 0 && (
          <table id="fal">
            <HeaderTable headers={headersFalta} />
            <tbody>
              {responseData.map((res: ResponseGet, index) => (
                <tr key={res.CPFSEMFORMATO + index.toString()}>
                  <td>
                    <button
                      type="button"
                      onClick={() => {
                        onVisualize(res);
                      }}
                    >
                      <FiEye />
                    </button>
                  </td>
                  <td style={{ minWidth: 250 }}>{res.NOME_CLIENTE}</td>
                  <td style={{ minWidth: 250 }}>{res.NOME}</td>
                  <td style={{ minWidth: 120 }}>
                    {maskCpf(res.CPFSEMFORMATO)}
                  </td>
                  <td>{res.CONTRATO}</td>
                  <td style={{ minWidth: 150 }}>{res.POSTO}</td>
                  <td>{res.FUNCAO}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {valor === 4 && responseData.length > 0 && (
          <table id="atraso">
            <HeaderTable headers={headersAtraso} />
            <tbody>
              {responseData.map((res: ResponseGet, index) => (
                <tr key={res.CPFSEMFORMATO + index.toString()}>
                  <td>
                    <button
                      type="button"
                      onClick={() => {
                        onVisualize(res);
                      }}
                    >
                      <FiEye />
                    </button>
                  </td>
                  <td style={{ minWidth: 250 }}>{res.NOME_CLIENTE}</td>
                  <td style={{ minWidth: 250 }}>{res.NOME}</td>
                  <td style={{ minWidth: 120 }}>
                    {maskCpf(res.CPFSEMFORMATO)}
                  </td>
                  <td>{res.CONTRATO}</td>
                  <td style={{ minWidth: 150 }}>{res.POSTO}</td>
                  <td>{res.FUNCAO}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {valor === 6 && responseData.length > 0 && (
          <table id="ferias">
            <HeaderTable headers={headersFerias} />
            <tbody>
              {responseData.map((res: ResponseGet, index) => (
                <tr key={res.CPFSEMFORMATO + index.toString()}>
                  <td>
                    <button
                      type="button"
                      onClick={() => {
                        onVisualize(res);
                      }}
                    >
                      <FiEye />
                    </button>
                  </td>
                  <td style={{ minWidth: 250 }}>{res.NOME_CLIENTE}</td>
                  <td style={{ minWidth: 250 }}>{res.NOME}</td>
                  <td style={{ minWidth: 120 }}>
                    {maskCpf(res.CPFSEMFORMATO)}
                  </td>
                  <td>{res.CONTRATO}</td>
                  <td style={{ minWidth: 150 }}>{res.POSTO}</td>
                  <td>{res.FUNCAO}</td>
                  <td>
                    {res.DATA_INICIAL_FERIAS_AFASTAMENTO
                      ? formatarDataAnoMesDia(
                          res.DATA_INICIAL_FERIAS_AFASTAMENTO,
                        )
                      : '-'}
                  </td>
                  <td style={{ minWidth: 80 }}>
                    {res.DATA_FINAL_FERIAS_AFASTAMENTO
                      ? formatarDataAnoMesDia(res.DATA_FINAL_FERIAS_AFASTAMENTO)
                      : '-'}
                  </td>
                  <td style={{ minWidth: 80 }}>
                    {res.DATA_VOLTA_FERIAS_AFASTAMENTO
                      ? formatarDataAnoMesDia(res.DATA_VOLTA_FERIAS_AFASTAMENTO)
                      : '-'}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {valor === 8 && responseData.length > 0 && (
          <table id="afastamento">
            <HeaderTable headers={headersAfastamento} />
            <tbody>
              {responseData.map((res: ResponseGet, index) => (
                <tr key={res.CPFSEMFORMATO + index.toString()}>
                  <td>
                    <button
                      type="button"
                      onClick={() => {
                        onVisualize(res);
                      }}
                    >
                      <FiEye />
                    </button>
                  </td>
                  <td style={{ minWidth: 250 }}>{res.NOME_CLIENTE}</td>
                  <td style={{ minWidth: 250 }}>{res.NOME}</td>
                  <td style={{ minWidth: 120 }}>
                    {maskCpf(res.CPFSEMFORMATO)}
                  </td>
                  <td>{res.CONTRATO}</td>
                  <td style={{ minWidth: 150 }}>{res.POSTO}</td>
                  <td>{res.FUNCAO}</td>
                  <td style={{ minWidth: 100 }}>
                    {res.DATA_INICIAL_FERIAS_AFASTAMENTO
                      ? formatarDataAnoMesDia(
                          res.DATA_INICIAL_FERIAS_AFASTAMENTO,
                        )
                      : '-'}
                  </td>
                  <td style={{ minWidth: 100 }}>
                    {res.DATA_FINAL_FERIAS_AFASTAMENTO
                      ? formatarDataAnoMesDia(res.DATA_FINAL_FERIAS_AFASTAMENTO)
                      : '-'}
                  </td>
                  <td style={{ minWidth: 100 }}>
                    {res.DATA_VOLTA_FERIAS_AFASTAMENTO
                      ? formatarDataAnoMesDia(res.DATA_VOLTA_FERIAS_AFASTAMENTO)
                      : '-'}
                  </td>
                  <td style={{ minWidth: 200 }}>{res.MOTIVO_AFASTAMENTO}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {valor === 9 && responseData.length > 0 && (
          <table id="pontoDivergente">
            <HeaderTable headers={headersPontoDivergente} />
            <tbody>
              {responseData.map((res: ResponseGet, index) => (
                <tr key={res.CPFSEMFORMATO + index.toString()}>
                  <td>
                    <button
                      type="button"
                      onClick={() => {
                        onVisualize(res);
                      }}
                    >
                      <FiEye />
                    </button>
                  </td>
                  <td style={{ minWidth: 250 }}>{res.NOME_CLIENTE}</td>
                  <td style={{ minWidth: 250 }}>{res.NOME}</td>
                  <td style={{ minWidth: 120 }}>
                    {maskCpf(res.CPFSEMFORMATO)}
                  </td>
                  <td>{res.CONTRATO}</td>
                  <td style={{ minWidth: 150 }}>{res.POSTO}</td>
                  <td>{res.FUNCAO}</td>
                  {/* <td style={{ minWidth: 100 }}>
                    {formatarDataAnoMesDia(res.DATA_INICIAL_DIA_AGENDA)}
                  </td>
                  <td>{res.LOCAL_LATITUDE}</td>
                  <td>{res.LOCAL_LONGITUDE}</td>
                  <td style={{ minWidth: 150 }}>
                    <div style={{ textAlign: 'left' }}>
                      <p>
                        Entrada:{' '}
                        {res.PONTO_LATITUDE_ENTRADA
                          ? res.PONTO_LATITUDE_ENTRADA
                          : '-'}
                      </p>
                      <p>
                        Pausa:{' '}
                        {res.PONTO_LATITUDE_PAUSA
                          ? res.PONTO_LATITUDE_PAUSA
                          : '-'}
                      </p>
                      <p>
                        Volta:{' '}
                        {res.PONTO_LATITUDE_VOLTA
                          ? res.PONTO_LATITUDE_VOLTA
                          : '-'}
                      </p>
                      <p>
                        Saída:{' '}
                        {res.PONTO_LATITUDE_SAIDA
                          ? res.PONTO_LATITUDE_SAIDA
                          : '-'}
                      </p>
                    </div>
                  </td>
                  <td style={{ minWidth: 150 }}>
                    <div style={{ textAlign: 'left' }}>
                      <p>
                        Entrada:{' '}
                        {res.PONTO_LONGITUDE_ENTRADA
                          ? res.PONTO_LONGITUDE_ENTRADA
                          : '-'}
                      </p>
                      <p>
                        Pausa:{' '}
                        {res.PONTO_LONGITUDE_PAUSA
                          ? res.PONTO_LONGITUDE_PAUSA
                          : '-'}
                      </p>
                      <p>
                        Volta:{' '}
                        {res.PONTO_LONGITUDE_VOLTA
                          ? res.PONTO_LONGITUDE_VOLTA
                          : '-'}
                      </p>
                      <p>
                        Saída:{' '}
                        {res.PONTO_LONGITUDE_SAIDA
                          ? res.PONTO_LONGITUDE_SAIDA
                          : '-'}
                      </p>
                    </div>
                  </td>
                  <td style={{ minWidth: 150 }}>
                    <div style={{ textAlign: 'left' }}>
                      <p>
                        Entrada:{' '}
                        {res.PONTO_DIFERENCA_METROS_ENTRADA
                          ? `${res.PONTO_DIFERENCA_METROS_ENTRADA}m`
                          : '-'}
                      </p>
                      <p>
                        Pausa:{' '}
                        {res.PONTO_DIFERENCA_METROS_PAUSA
                          ? `${res.PONTO_DIFERENCA_METROS_PAUSA}m`
                          : '-'}
                      </p>
                      <p>
                        Volta:{' '}
                        {res.PONTO_DIFERENCA_METROS_VOLTA
                          ? `${res.PONTO_DIFERENCA_METROS_VOLTA}m`
                          : '-'}
                      </p>
                      <p>
                        Saída:{' '}
                        {res.PONTO_DIFERENCA_METROS_SAIDA
                          ? `${res.PONTO_DIFERENCA_METROS_SAIDA}m`
                          : '-'}
                      </p>
                    </div>
                        </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {valor === 11 && responseData.length > 0 && (
          <table id="horaDivergente">
            <HeaderTable headers={headersHoraDivergente} />
            <tbody>
              {responseData.map((res: ResponseGet, index) => (
                <tr key={res.CPFSEMFORMATO + index.toString()}>
                  <td>
                    <button
                      type="button"
                      onClick={() => {
                        onVisualize(res);
                      }}
                    >
                      <FiEye />
                    </button>
                  </td>
                  <td style={{ minWidth: 250 }}>{res.NOME_CLIENTE}</td>
                  <td style={{ minWidth: 250 }}>{res.NOME}</td>
                  <td style={{ minWidth: 120 }}>
                    {maskCpf(res.CPFSEMFORMATO)}
                  </td>
                  <td>{res.CONTRATO}</td>
                  <td style={{ minWidth: 150 }}>{res.POSTO}</td>
                  <td>{res.FUNCAO}</td>
                  {/* <td style={{ minWidth: 100 }}>
                    {formatarDataAnoMesDia(res.DATA_INICIAL_DIA_AGENDA)}
                  </td>
                  <td style={{ minWidth: 150 }}>
                    <div style={{ textAlign: 'left' }}>
                      <p>Entrada: {res.HORA_INICIAL}</p>
                      <p>Saída: {res.HORA_FINAL}</p>
                    </div>
                  </td>
                  <td style={{ minWidth: 150 }}>
                    <div style={{ textAlign: 'left' }}>
                      <p>
                        Entrada:{' '}
                        {res.PONTO_HORA_ENTRADA ? res.PONTO_HORA_ENTRADA : '-'}
                      </p>
                      <p>
                        Pausa:{' '}
                        {res.PONTO_HORA_PAUSA ? res.PONTO_HORA_PAUSA : '-'}
                      </p>
                      <p>
                        Volta:{' '}
                        {res.PONTO_HORA_VOLTA ? res.PONTO_HORA_VOLTA : '-'}
                      </p>
                      <p>
                        Saída:{' '}
                        {res.PONTO_HORA_SAIDA ? res.PONTO_HORA_SAIDA : '-'}
                      </p>
                    </div>
                    </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {valor === 12 && responseData.length > 0 && (
          <table id="abono">
            <HeaderTable headers={headersAbono} />
            <tbody>
              {responseData.map((res: ResponseGet, index) => (
                <tr key={res.CPFSEMFORMATO + index.toString()}>
                  <td>
                    <button
                      type="button"
                      onClick={() => {
                        onVisualize(res);
                      }}
                    >
                      <FiEye />
                    </button>
                  </td>
                  <td style={{ minWidth: 250 }}>{res.NOME_CLIENTE}</td>
                  <td style={{ minWidth: 250 }}>{res.NOME}</td>
                  <td style={{ minWidth: 120 }}>
                    {maskCpf(res.CPFSEMFORMATO)}
                  </td>
                  <td>{res.CONTRATO}</td>
                  <td style={{ minWidth: 150 }}>{res.POSTO}</td>
                  <td>{res.FUNCAO}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </ContainerTable>
      {responseData.length > 0 && (
        <ContainerPagination>
          <Pagination
            total={totalItems}
            itemsPerPage={ITEMS_PER_PAGE}
            currentPage={currentPage}
            onPageChange={(page: number) => setCurrentPage(page)}
          />
          <DropDown
            onChangeItems={(value: string) => {
              setCurrentPage(1);
              if (value === 'todos') {
                setITEMS_PER_PAGE(1);
              } else {
                setITEMS_PER_PAGE(Number(value));
              }
            }}
            objetoEnum={dropDown}
            minWidth={30}
          />
          {valor === 1 && (
            <ReactHTMLTableToExcel
              id="export-excel"
              className="btn"
              table="PontoTodosExcel"
              filename={`gerenciamento_ponto_todos_${dataInicial}_a_${dataFinal}`}
              sheet="tablexls"
              buttonText="Exportar Excel"
            />
          )}
          {valor === 1 && (
            <button
              className="buttonPdf"
              type="button"
              onClick={() => {
                exportPdfPontoDetalhado(
                  responseData,
                  dadosCompletos,
                  empresa,
                  dataInicial,
                  dataFinal,
                );
              }}
            >
              <GoFilePdf />
            </button>
          )}
          {valor === 2 && (
            <ReactHTMLTableToExcel
              id="export-excel"
              className="btn"
              table="horaExtra"
              filename="gerenciamento_ponto_hora_extra"
              sheet="tablexls"
              buttonText="Exportar Excel"
            />
          )}
          {valor === 3 && (
            <ReactHTMLTableToExcel
              id="export-excel"
              className="btn"
              table="fal"
              filename="gerenciamento_ponto_faltas"
              sheet="tablexls"
              buttonText="Exportar Excel"
            />
          )}
          {valor === 4 && (
            <ReactHTMLTableToExcel
              id="export-excel"
              className="btn"
              table="atraso"
              filename="gerenciamento_ponto_atraso"
              sheet="tablexls"
              buttonText="Exportar Excel"
            />
          )}
          {valor === 6 && (
            <ReactHTMLTableToExcel
              id="export-excel"
              className="btn"
              table="ferias"
              filename="gerenciamento_ponto_ferias"
              sheet="tablexls"
              buttonText="Exportar Excel"
            />
          )}
          {valor === 8 && (
            <ReactHTMLTableToExcel
              id="export-excel"
              className="btn"
              table="afastamento"
              filename="gerenciamento_ponto_afastamento"
              sheet="tablexls"
              buttonText="Exportar Excel"
            />
          )}
          {valor === 9 && (
            <ReactHTMLTableToExcel
              id="export-excel"
              className="btn"
              table="pontoDivergente"
              filename="gerenciamento_ponto_Ponto_divergente"
              sheet="tablexls"
              buttonText="Exportar Excel"
            />
          )}
          {valor === 11 && (
            <ReactHTMLTableToExcel
              id="export-excel"
              className="btn"
              table="horaDivergente"
              filename="gerenciamento_ponto_Hora_divergente"
              sheet="tablexls"
              buttonText="Exportar Excel"
            />
          )}
          {valor === 12 && (
            <ReactHTMLTableToExcel
              id="export-excel"
              className="btn"
              table="abono"
              filename="gerenciamento_ponto_abono"
              sheet="tablexls"
              buttonText="Exportar Excel"
            />
          )}
          <div style={{ marginLeft: 'auto' }}>
            <p>Pessoas: {totalTabela}</p>
          </div>
        </ContainerPagination>
      )}
    </>
  );
};
export default TabelaExibicao;
