import styled from 'styled-components';

export const ContainerTh = styled.th`
  max-width: 150px;
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  align-self: center;
  text-align: center;
  button {
    background-color: transparent;
    color: #fff;
    border: 0;
    margin-left: 5px;
  }
`;
