import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  max-width: auto;
  align-items: center;
  max-height: 100vh;
`;

export const ContainerPesquisaGeral = styled.div`
  width: 90%;
  margin: 0px auto;
  margin-top: 15px;
  margin-bottom: 80px;
  padding: 55px 10px 30px 10px;
  background-color: #3e3946;
  overflow-x: auto;
  border-radius: 5px;
  h3 {
    text-align: center;
  }
`;

export const ContainerSupFilter = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0px auto;
  justify-content: center;
  align-items: center;
  & > span {
    font-size: 27px;
    font-family: Arial;
    margin: 0 10px 60px 10px;
    text-align: center;
  }
`;

export const ContainerInfFilter = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  span {
    font-size: 17px;
    font-family: Arial;
    margin-right: 20px;
  }
  input {
    height: 20px;
    width: 20px;
  }
  label {
    font-size: 14px;
    text-align: center;
    font-family: Arial;
    width: 100px;
    padding: 10px 5px;
  }
  button:nth-child(2) {
    height: 40px;
    padding: 10px;
    background-color: #1661a7;
    color: #ffffff;
    border-radius: 5px;
    border: none;
    margin-left: 20px;
    &:hover {
      background-color: #1890ff;
    }
  }
  svg {
    margin-left: 3px;
  }
  select {
    margin: 0;
  }
`;

export const VisualizarPDF = styled.button`
  @media screen and (max-width: 442px) {
    display: none;
  }
  height: 40px;
  padding: 10px;
  background-color: #439133;
  color: #ffffff;
  border-radius: 5px;
  border: none;
  &:hover {
    background-color: #57bd42;
  }
`;

export const ContainerOpcoesPDF = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

export const ContainerPeriodo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-size: 1.3em;
  }
`;
