import React, { useRef, useCallback, useState } from 'react';
import { FiLock } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import getValidationErrors from '../../utils/getValidationErrors';
import { Loading } from '../../components/Loading';
import ModalConexao from '../../components/ModalConexao';
import logoImg from '../../assets/logo.png';
import Input from '../../components/Input';
import Button from '../../components/Button';
import api from '../../services/api';
import { Container, Content, Background, AnimationContainer } from './styles';

interface ResetPasswordFormData {
  password: string;
  password_confirmation: string;
}

const ResetPassword: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (data: ResetPasswordFormData) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          password: Yup.string().required('Senha Obrigatória!'),
          password_confirmation: Yup.string()
            .oneOf([Yup.ref('password'), null], '')
            .required('Confirmação Incorreta!'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const token = location.search.replace('?search=', '');
        if (!token) {
          throw new Error();
        }
        await api
          // qrcode - users/password/reset -- colaborador - passowod/reset
          .post('password/reset', {
            password: data.password,
            // passwordConfirmation: data.password_confirmation, // back qrcode
            password_confirmation: data.password_confirmation, // back colaborador
            token,
          })
          .then(() => {
            setLoading(false);
            Swal.fire({
              icon: 'success',
              title: 'Senha Recuperada com Sucesso!',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            });
            history.push('/');
          })
          .catch(() => {
            setLoading(false);
            Swal.fire({
              icon: 'error',
              title: 'Falha ao Recuperar Senha.',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            });
          });
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }
        Swal.fire({
          icon: 'error',
          title: 'Falha ao Recuperar Senha.',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        });
      }
    },
    [history, location.search],
  );

  return (
    <>
      <ModalConexao />
      <Container>
        <Content>
          <AnimationContainer>
            <img src={logoImg} alt="Logo Servnac" />

            <Form ref={formRef} onSubmit={handleSubmit}>
              <h1>Resetar Senha</h1>

              <Input
                name="password"
                icon={FiLock}
                tipo="password"
                placeholder="Nova senha"
              />
              <Input
                name="password_confirmation"
                icon={FiLock}
                tipo="password"
                placeholder="Confirmação da Senha"
              />

              <Button type="submit">Alterar Senha</Button>
            </Form>
          </AnimationContainer>
        </Content>
        <Background />
      </Container>
      {loading && <Loading />}
    </>
  );
};

export default ResetPassword;
