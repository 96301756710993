import styled from 'styled-components';
import { shade } from 'polished';

export default {
  normal: {
    background: '#004d00',
    color: '#ffffff',
  },
  hover: {
    background: shade(0.2, '#004d00'),
  },
};

export const Container = styled.div`
  svg {
    margin-right: 20px;
  }

  .ant-menu-submenu-title {
    &:hover {
      background: ${shade(0.2, '#004d00')};
      border-radius: 5px;
    }
  }
  /* .ant-menu {
    &:hover {
      background: ${shade(0.2, '#004d00')};
      border-radius: 5px;
    }
  } */

  .ant-menu-item {
    &:hover {
      background: ${shade(0.2, '#004d00')};
      border-radius: 5px;
    }
  }

  li.ant-menu-item {
    &:hover {
      background: ${shade(0.2, '#004d00')};
      border-radius: 5px;
    }
  }

  menuitem {
    &:hover {
      background: ${shade(0.2, '#004d00')};
      border-radius: 5px;
    }
  }

  /* .ant-menu-item-only-child {
    &:hover {
      background: ${shade(0.2, '#004d00')};
      border-radius: 5px;
    }
  } */

  /* .ant-menu-sub {
    &:hover {
      background: ${shade(0.2, '#004d00')};
      border-radius: 5px;
    }
  }
  
  .ant-menu-vertical {
    &:hover {
      background: ${shade(0.2, '#004d00')};
      border-radius: 5px;
    }
  } */
`;
