import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  width: 90%;
  left: 5%;
  top: 50px;
  min-height: 30em;
  background-color: #312e38;
  border: solid #439133;
`;

export const Header = styled.div`
  height: 3em;
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: center space-between;
  align-items: center;
  align-content: flex-start;
  background-color: #439133;
  padding: 0 10px;
`;
export const Content = styled.div`
  display: flex;
`;
export const JSONContainer = styled.div`
  min-width: 50%;
  border-right: solid #439133;
`;
export const ImagenDoc = styled.div`
  width: 50%;
  max-height: 30em;
  overflow-x: auto;
  img {
    width: 100%;
  }
`;
export const Row = styled.div`
  display: flex;
  width: 100%;
  border-bottom: solid 1px #439133;
`;
export const CName = styled.div`
  width: 30%;
  border-right: solid #439133;
`;
export const CValue = styled.div`
  width: 70%;
  text-align: left;
  padding-left: 10px;
`;

export const PdfView = styled.iframe`
  width: 100%;
  height: 30em;
`;
