import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  flex: 1;
  max-width: auto;
  align-items: center;
  max-height: 100vh;
`;

export const ContainerSearch = styled.div`
  display: flex;
  margin-left: 5%;
  margin-top: 40px;
`;

export const ContainerTable = styled.div`
  max-width: 90%;
  align-items: center;
  margin: 0px auto;
  max-height: 60vh;
  overflow-x: auto;
  table {
    width: 100%;
    margin: 10px auto;
    margin-top: 0px;
    border-collapse: collapse;
    border: 1px solid #439133;
    border-top: 0px;
    th {
      border-bottom: 1px solid #439133;
      justify-content: space-between;
      padding: 5px 30px;
      background-color: #439133;
      color: #fff;
      position: sticky;
      position: -webkit-sticky;
      top: 0;
    }
  }
  tbody {
    td {
      margin: 5px;
      padding: 5px;
      text-align: center;
      font-size: 14px;
      button {
        background-color: transparent;
        color: #fff;
        border: 0;
        & + button {
          margin-left: 20px;
        }
      }
    }
    tr {
      border-bottom: 1px solid #439133;
      /**funcao para fazer as linhas da tabela uma de cada cor de forma alternada.*/
      &:nth-child(even) {
        background-color: #323232;
      }
      &:hover {
        background-color: #454545;
      }
    }
  }
`;

export const ContainerButton = styled.div`
  max-width: 96%;
  margin-left: 140px;
  margin-top: 50px;
  button {
    margin-right: 10px;
    background-color: #439133;
    color: #fff;
    border-radius: 5px;
    padding: 10px;

    &:hover {
      filter: brightness(0.9);
    }
  }
`;

export const ContainerAccess = styled.div`
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  background-color: #3e3c44;
  padding: 40px 12px;
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-70px);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 0;

  animation: ${appearFromLeft} 1s;

  form {
    margin: 0px 0;
    width: 100%;
    text-align: center;
    align-items: center;
    padding: 10px;
  }
`;

export const ContainerForm = styled.div`
  max-width: 100%;
  display: flex;
  align-items: center;
`;

export const ContainerDrop = styled.div`
  display: flex;
  align-items: center;
`;

export const ContentSubBody = styled.main`
  width: 100%;
  height: 70px;
  display: flex;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

export const ContentSubLabel = styled.label`
  font-family: 'Arial', serif;
  background-color: #439133;
  color: #000000;
  height: 40px;
  padding: 12px;
  text-align: center;
  justify-content: center;
`;

export const ContainerPagination = styled.div`
  width: 90%;
  display: flex;
  margin: 0px auto;
  padding-bottom: 50px;
  justify-content: start;
  align-items: center;
  button {
    background-color: #439133;
    margin-left: 20px;
    border: none;
    height: 40px;
    width: 100px;
    padding: 0px 20px;
    border-radius: 5px;
    color: #ffffff;
    line-height: 20px;
  }
`;

export const ContainerVisualizarVazio = styled.div`
  display: flex;
  width: 100%;
  background-color: #4b4554;
  margin: 10px auto;
  border-radius: 10px;
  h3 {
    color: #ffffff;
    margin: 0px auto;
    font-size: 32px;
    font-family: Arial;
    padding: 40px;
  }
`;
