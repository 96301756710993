import jsPDF from 'jspdf';
import { ResponseGetSimples } from '../Interface/index';
import { maskCpf } from '../../../components/Input/mask';
import { formatarDataAnoMesDia } from '../../../components/Formulas/formulas';

export const exportPdfGeral = (
  dados: ResponseGetSimples[],
  nomeEmpresa: string,
  dataInicial: string,
  dataFinal: string,
): void => {
  const filial = nomeEmpresa.split('- ');

  const doc = new jsPDF('landscape'); // eslint-disable-line
  doc.setFontSize(16);
  doc.setTextColor(0, 0, 0);
  doc.setFontType('bold');
  doc.text(`Registro de Ponto - ${filial[1]}`, 147, 10, 'center');
  doc.setFontSize(12);
  doc.text(`Período: ${dataInicial} à ${dataFinal}`, 147, 15, 'center');
  // tamanho 207 / 294 tela a4
  let posY: number;
  let quebraLinha;
  let psY = 0;
  let contaLinha = 0;
  doc.setFontSize(10);
  posY = 20;
  posY += 5;
  doc.text('Empresa', 10, posY, 'left');
  doc.text('Nome', 45, posY, 'left'); // 33 // 35 - 2
  doc.text('CPF', 81, posY, 'left'); // 76 // 80 - 4
  doc.text('Contrato', 101, posY, 'left'); // 99 // 105 - 6
  doc.text('Posto', 127, posY, 'left'); // 122 // 130 - 8
  doc.text('Data', 148, posY, 'left'); // 145 // 153 - 10
  doc.text('Ponto', 163, posY, 'left'); // 153 //  165
  quebraLinha = doc.splitTextToSize(`Hora Extra`, 10); // 164 // 178
  doc.text(quebraLinha, 175, posY - 2, { align: 'left', maxWidth: 10 });
  doc.text('Falta', 185, posY, 'left'); // 175 // 189
  doc.text('Atraso', 195, posY, 'left'); // 186 // 200
  doc.text('Férias', 208, posY, 'left'); // 194 // 211
  quebraLinha = doc.splitTextToSize(`Afasta-mento`, 14); // 210 // 222
  doc.text(quebraLinha, 220, posY - 2, { align: 'left', maxWidth: 13 });
  quebraLinha = doc.splitTextToSize(`Local Divergente`, 20); // 226 // 237
  doc.text(quebraLinha, 234, posY - 2, { align: 'left', maxWidth: 20 });
  quebraLinha = doc.splitTextToSize(`Hora Divergente`, 20); // 256
  doc.text(quebraLinha, 254, posY - 2, { align: 'left', maxWidth: 20 });
  doc.text('Abono', 273, posY, 'left'); // 271

  posY += 10;
  doc.setFontSize(8);
  contaLinha = 0;
  doc.setFontType('normal');
  dados.forEach((dado, index) => {
    psY = posY;
    quebraLinha = doc.splitTextToSize(`${dado.NOME_CLIENTE}`, 20);
    doc.text(quebraLinha, 10, psY, { align: 'left', maxWidth: 20 });
    contaLinha = quebraLinha.length;
    quebraLinha = doc.splitTextToSize(`${dado.NOME}`, 40);
    doc.text(quebraLinha, 33, psY, { maxWidth: 40 });
    if (quebraLinha.length > contaLinha) {
      contaLinha = quebraLinha.length;
    }
    doc.text(`${maskCpf(dado.CPFSEMFORMATO)}`, 76, posY, { maxWidth: 20 });
    quebraLinha = doc.splitTextToSize(`${dado.CONTRATO}`, 20);
    doc.text(quebraLinha, 99, psY, { maxWidth: 20 });
    if (quebraLinha.length > contaLinha) {
      contaLinha = quebraLinha.length;
    }
    quebraLinha = doc.splitTextToSize(`${dado.POSTO}`, 20);
    doc.text(quebraLinha, 122, psY, { maxWidth: 20 });
    if (quebraLinha.length > contaLinha) {
      contaLinha = quebraLinha.length;
    }
    doc.text(
      `${
        dado.DATA_INICIAL_DIA_AGENDA
          ? formatarDataAnoMesDia(dado.DATA_INICIAL_DIA_AGENDA)
          : '-'
      }`,
      145,
      posY,
      { maxWidth: 15, align: 'left' },
    );
    doc.text(`${dado.COM_PONTO ? 'Sim' : 'Não'}`, 165, posY, { maxWidth: 8 });
    doc.text(`${dado.EXTRA ? 'Sim' : 'Não'}`, 176, posY, { maxWidth: 8 });
    doc.text(`${dado.SEM_PONTO ? 'Sim' : 'Não'}`, 187, posY, { maxWidth: 8 });
    doc.text(`${dado.ATRASO ? 'Sim' : 'Não'}`, 198, posY, { maxWidth: 8 });
    doc.text(`${dado.FERIAS ? 'Sim' : 'Não'}`, 211, posY, { maxWidth: 8 });
    doc.text(`${dado.AFASTAMENTO ? 'Sim' : 'Não'}`, 222, posY, { maxWidth: 8 });
    doc.text(`${dado.PONTO_DIVERGENTE ? 'Sim' : 'Não'}`, 238, posY);
    doc.text(`${dado.HORA_DIVERGENTE ? 'Sim' : 'Não'}`, 257, posY);
    doc.text(`${dado.ABONO ? 'Sim' : 'Não'}`, 276, posY);
    doc.setLineWidth(0.5);
    posY = 5 * contaLinha + posY;
    if (posY < 185 && index + 1 !== dados.length) {
      doc.line(7, posY - 4, 287, posY - 4);
    }
    if (posY >= 185) {
      if (index + 1 !== dados.length) {
        doc.rect(7, 19, 280, 9);
        doc.rect(7, 19, 280, posY - 5 * contaLinha - 4);
        doc.addPage();
        doc.setFontSize(16);
        doc.setTextColor(0, 0, 0);
        doc.setFontType('bold');
        doc.text(`Registro de Ponto - ${filial[1]}`, 147, 10, 'center');
        doc.setFontSize(12);
        doc.text(`Período: ${dataInicial} à ${dataFinal}`, 147, 15, 'center');
        doc.setFontSize(10);
        doc.text('Empresa', 10, 25, 'left');
        doc.text('Nome', 45, 25, 'left'); // 35
        doc.text('CPF', 81, 25, 'left'); // 80
        doc.text('Contrato', 101, 25, 'left'); // 105
        doc.text('Posto', 127, 25, 'left'); // 130
        doc.text('Data', 148, 25, 'left');
        doc.text('Ponto', 163, 25, 'left'); // 153
        quebraLinha = doc.splitTextToSize(`Hora Extra`, 10); // 164
        doc.text(quebraLinha, 175, 25 - 2, { align: 'left', maxWidth: 10 });
        doc.text('Falta', 185, 25, 'left'); // 175
        doc.text('Atraso', 195, 25, 'left'); // 186
        doc.text('Férias', 208, 25, 'left'); // 194
        quebraLinha = doc.splitTextToSize(`Afasta-mento`, 14); // 210
        doc.text(quebraLinha, 220, 25 - 2, { align: 'left', maxWidth: 13 });
        quebraLinha = doc.splitTextToSize(`Local Divergente`, 20); // 226
        doc.text(quebraLinha, 234, 25 - 2, { align: 'left', maxWidth: 20 });
        quebraLinha = doc.splitTextToSize(`Hora Divergente`, 20); // 250
        doc.text(quebraLinha, 254, 25 - 2, { align: 'left', maxWidth: 20 });
        doc.text('Abono', 273, 25, 'left'); // 271
        posY = 35;
        doc.setFontType('normal');
        doc.setFontSize(8);
      }
      contaLinha = 0;
    }
  });
  doc.rect(7, 19, 280, 9);
  doc.rect(7, 19, 280, psY - 4);
  doc.setFontSize(16);
  doc.setTextColor(0, 0, 0);
  doc.setFontType('bold');
  doc.text(`Registro de Ponto - ${filial[1]}`, 147, 10, 'center');
  doc.setFontSize(12);
  doc.text(`Período: ${dataInicial} à ${dataFinal}`, 147, 15, 'center');

  doc.save(`${filial[1]}_${dataInicial}_${dataFinal}_ponto.pdf`);
};
