import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { FiFileText } from 'react-icons/fi';

import { useAuth } from '../../hooks/auth';
import { Vagas } from '../../models/Vaga';
import ModalConexao from '../../components/ModalConexao';
import DrawerApp from '../../components/Drawer';
import Search from '../../components/Search';
import HeaderTable from '../../components/HeaderTable';
import DropDown from '../../components/DropDown';
import api from '../../services/api';
import { Loading } from '../../components/Loading';
import Pagination from '../../components/Pagination';
import { Curriculo } from '../../models/Curriculo';

import {
  Container,
  ContainerPagination,
  ContainerSearch,
  ContainerTable,
  ButtonBack,
  ContainerVisualizarVazio,
} from './styles';

const setores = [
  { valor: 'Trabalhe Conosco', id: 17, NivelAcesso: 1 },
  { valor: 'Todos', id: 15, NivelAcesso: 1 },
];

const headers = [
  { name: 'Nome', field: 'name' },
  // { name: 'E-mail', field: 'email' },
  { name: 'Currículo', field: 'curriculo' },
];

const dropDown = [
  { valor: '20', id: 20 },
  { valor: '30', id: 30 },
  { valor: '40', id: 40 },
  { valor: '50', id: 50 },
  { valor: 'todos', id: 1 },
];

type LocationState = {
  vaga: Vagas;
};

export const Candidaturas: React.FC = () => {
  const { vaga } = useLocation<LocationState>().state;
  const { userAcesso } = useAuth();
  const history = useHistory();

  const [searchNome, setSearchNome] = useState('');
  // const [searchEmail, setSearchEmail] = useState('');
  const [curriculos, setCurriculos] = useState<Curriculo[]>([]);
  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalTabela, setTotalTabela] = useState(0);
  const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState<number>(20);

  // function empresaCodForName(codigo: string): string {
  //   switch (codigo) {
  //     case '010101':
  //       return 'Fortal Empreendimentos';
  //     case '020101':
  //       return 'Fortal Tercerização';
  //     case '030101':
  //       return 'Real Serviços';
  //     case '040101':
  //       return 'Ello Serviços';
  //     case '050101':
  //       return 'Missao Serviços';
  //     default:
  //       return 'Fortal Empreendimentos';
  //   }
  // }

  useEffect(() => {
    let semAcesso = true;
    let access;
    userAcesso.forEach((user) => {
      access = setores.filter(
        (s) => s.id === user.SetorID && user.NivelACesso >= s.NivelAcesso,
      );
      if (access.length > 0) {
        semAcesso = false;
      }
    });
    if (semAcesso) {
      history.push('/dashboard');
      Swal.fire({
        icon: 'info',
        title:
          'Seu nível de acesso não tem permissão para acessar essa página!',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
    } else {
      setLoading(true);
      api
        .get(`/curriculos/all`)
        .then((response) => {
          // setResponse(data.data);
          // console.log(response.data);
          const curriculoResp: Curriculo[] = response.data;
          const curric = curriculoResp.filter(
            (item) => item.vagaId === vaga.id,
          );
          // console.log(curric);
          setCurriculos(curric);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          if (navigator.onLine) {
            Swal.fire({
              icon: 'info',
              title: 'Erro ao carregar dados, por favor atualize a página!',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            });
          }
        });
      setLoading(false);
    }
  }, [history, userAcesso, vaga.id]);

  const responseData = useMemo(() => {
    let computedResponses: Curriculo[] = [];
    computedResponses = curriculos;

    if (searchNome) {
      computedResponses = computedResponses.filter((res: Curriculo) =>
        res.name.toLowerCase().includes(searchNome.toLowerCase()),
      );
    }
    // if (searchEmail) {
    //   computedResponses = computedResponses.filter((res: Curriculo) =>
    //     res.EMAIL.toLowerCase().includes(searchEmail.toLowerCase()),
    //   );
    // }

    setTotalTabela(computedResponses.length);
    if (ITEMS_PER_PAGE === 1) {
      return computedResponses;
    }

    return computedResponses.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE,
    );
  }, [ITEMS_PER_PAGE, currentPage, curriculos, searchNome]);

  return (
    <Container>
      <ModalConexao />
      <DrawerApp />
      <div style={{ margin: '4%' }}>
        <div
          style={{
            backgroundColor: '#3e3946',
            width: '100%',
            paddingTop: 20,
            borderRadius: 20,
            padding: 20,
          }}
        >
          <h1>{vaga.Funcao?.name || ''}</h1>
          <h3 style={{ marginTop: -10 }}>{vaga.Filial?.name || ''}</h3>
          <ContainerSearch>
            <Search
              onSearch={(value: string) => {
                setSearchNome(value);
              }}
              nomePlaceHolder="Buscar Nome"
            />
            {/* <Search
              onSearch={(value: string) => {
                setSearchEmail(value);
              }}
              nomePlaceHolder="Buscar E-mail"
            /> */}
          </ContainerSearch>
          <ContainerTable>
            <table>
              <HeaderTable headers={headers} />
              <tbody>
                {responseData.map((item: Curriculo) => (
                  <tr key={item.id}>
                    <td>{item.name}</td>
                    {/* <td>{item.email}</td> */}
                    <td>
                      <a
                        href={item.curriculo}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FiFileText color="#46dd28" />
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </ContainerTable>
          {responseData.length > 0 ? (
            <div />
          ) : (
            <ContainerVisualizarVazio>
              <h3>Não possui currículos</h3>
            </ContainerVisualizarVazio>
          )}
          <ContainerPagination>
            <Pagination
              total={totalTabela}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page: number) => setCurrentPage(page)}
            />
            <DropDown
              onChangeItems={(value: string) => {
                setCurrentPage(1);
                if (value === 'todos') {
                  setITEMS_PER_PAGE(1);
                } else {
                  setITEMS_PER_PAGE(Number(value));
                }
              }}
              objetoEnum={dropDown}
              minWidth={30}
            />
            <ButtonBack href="/vagas">Voltar</ButtonBack>
          </ContainerPagination>
        </div>
      </div>
      {loading && <Loading />}
    </Container>
  );
};
