import React, { useState, useRef, useCallback, useEffect } from 'react';
import {
  FiList,
  FiEdit2,
  FiCheckCircle,
  FiXCircle,
  FiPlusCircle,
  FiSave,
} from 'react-icons/fi';
import { BsQuestionCircleFill } from 'react-icons/bs';
import { FormHandles } from '@unform/core';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import HeaderTable from '../../components/HeaderTable';
import api from '../../services/api';
import ModalConexao from '../../components/ModalConexao';
import { Loading } from '../../components/Loading';
import ModalInformacao from '../../components/ModalInformacao/index';
import Tooltip from '../../components/Tooltip/index';

import {
  Container,
  ContainerOptions,
  IconLista,
  ContainerTable,
  ContainerButtonCor,
  ContainerFormulario,
  ContainerTableAdd,
} from './styles';
import DrawerApp from '../../components/Drawer';

interface ResponseGet {
  ID: number;
  NOME: string;
  ATIVO: boolean;
}

const setores = [
  { valor: 'Todos', id: 15, NivelAcesso: 1 },
  { valor: 'Todos', id: 17, NivelAcesso: 8 },
];

const CadastroAbono: React.FC = () => {
  const { userAcesso } = useAuth();
  const history = useHistory();
  const [response, setResponse] = useState<ResponseGet[]>([]);
  const [userEdit, setUserEdit] = useState<ResponseGet>();
  const [refresh, setRefresh] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [textAbono, setTextAbono] = useState('');

  const [informacao, setInformacao] = useState(false);
  const [buttonCorListar, setButtonCorListar] = useState(true);
  const [buttonCorAdd, setButtonCorAdd] = useState(false);
  const [buttonCorEdit, setButtonCorEdit] = useState(false);
  const [buttonCorRemove, setButtonCorRemove] = useState(false);
  const [buttonCorActive, setButtonCorActive] = useState(false);

  const headers = [{ name: 'Nome do Abono', field: 'abono' }];
  const headersAdd = [{ name: 'Adicionar Abono', field: 'addAbono' }];

  useEffect(() => {
    let semAcesso = true;
    let access;
    userAcesso.forEach((user) => {
      access = setores.filter(
        (s) => s.id === user.SetorID && user.NivelACesso >= s.NivelAcesso,
      );
      if (access.length > 0) {
        semAcesso = false;
      }
    });
    if (semAcesso) {
      history.push('/dashboard');
      Swal.fire({
        icon: 'info',
        title:
          'Seu nível de acesso não tem permissão para acessar essa página!',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
    } else {
      setLoading(true);
      api
        .get(`/pontoEletronico/getMotivoAbonoWeb`)
        .then((data) => {
          setResponse(data.data);
          // console.log(data.data);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          if (navigator.onLine) {
            Swal.fire({
              icon: 'info',
              title: 'Erro ao carregar dados, por favor atualize a página!',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            });
          }
        });
    }
  }, [history, refresh, userAcesso]);

  const testarNomeUnico = useCallback(
    (nome: string): boolean => {
      const access = response.filter((dado) => dado.NOME === nome);
      if (access.length > 0) {
        return false;
      }
      return true;
    },
    [response],
  );

  const handleSubmit = useCallback(async () => {
    if (textAbono) {
      if (testarNomeUnico(textAbono)) {
        try {
          setLoading(true);
          formRef.current?.setErrors({});
          await api
            .post('pontoEletronico/saveMotivoAbono', {
              motivo: textAbono,
            })
            .then(() => {
              setLoading(false);
              setButtonCorAdd(false);
              Swal.fire({
                icon: 'success',
                title: 'Abono Criado com Sucesso!',
                showConfirmButton: false,
                timer: 1500,
              });
              setRefresh(!refresh);
            })
            .catch(() => {
              setLoading(false);
              Swal.fire({
                icon: 'error',
                title: 'Erro ao criar Abono.',
                showClass: {
                  popup: 'animate__animated animate__fadeInDown',
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp',
                },
              });
            });
        } catch (err) {
          setLoading(false);
          Swal.fire({
            icon: 'error',
            title: 'Erro ao criar Abono.',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          });
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Já exite esse Abono ',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        });
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Nome do Abono é obrigatório.',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
    }
  }, [refresh, testarNomeUnico, textAbono]);

  const handleSubmitEdit = useCallback(
    async (abonoId: number) => {
      if (textAbono) {
        if (testarNomeUnico(textAbono)) {
          try {
            setLoading(true);
            formRef.current?.setErrors({});
            await api
              .patch('/pontoEletronico/inativaAtivaEditMotivoAbono', {
                id: abonoId,
                acao: true,
                novaDesc: textAbono,
              })
              .then(() => {
                setLoading(false);
                Swal.fire({
                  icon: 'success',
                  title: 'Abono Atualizado com Sucesso!',
                  showConfirmButton: false,
                  timer: 1500,
                });
                setRefresh(!refresh);
              })
              .catch(() => {
                setLoading(false);
                Swal.fire({
                  icon: 'error',
                  title: 'Erro ao Atualizar Abono.',
                  showClass: {
                    popup: 'animate__animated animate__fadeInDown',
                  },
                  hideClass: {
                    popup: 'animate__animated animate__fadeOutUp',
                  },
                });
              });
          } catch (err) {
            setLoading(false);
            Swal.fire({
              icon: 'error',
              title: 'Erro ao salvar Abono',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            });
          }
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Já exite esse Abono ',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          });
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Nome do Abono é obrigatório.',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        });
      }
    },
    [refresh, testarNomeUnico, textAbono],
  );

  const handleDelete = useCallback(
    async (abonoId: number, acao: boolean) => {
      try {
        setLoading(true);
        await api
          .patch(`/pontoEletronico/inativaAtivaEditMotivoAbono`, {
            id: abonoId,
            acao,
          })
          .then(() => {
            setLoading(false);
            acao
              ? Swal.fire({
                  icon: 'success',
                  title: 'Abono foi Inativado!',
                  showConfirmButton: false,
                  timer: 1500,
                })
              : Swal.fire({
                  icon: 'success',
                  title: 'Abono foi Ativado!',
                  showConfirmButton: false,
                  timer: 1500,
                });
            setRefresh(!refresh);
          })
          .catch(() => {
            setLoading(false);
            acao
              ? Swal.fire({
                  icon: 'error',
                  title: 'Erro ao Inativar Abono',
                  showClass: {
                    popup: 'animate__animated animate__fadeInDown',
                  },
                  hideClass: {
                    popup: 'animate__animated animate__fadeOutUp',
                  },
                })
              : Swal.fire({
                  icon: 'error',
                  title: 'Erro ao Ativar Abono',
                  showClass: {
                    popup: 'animate__animated animate__fadeInDown',
                  },
                  hideClass: {
                    popup: 'animate__animated animate__fadeOutUp',
                  },
                });
          });
      } catch (err) {
        setLoading(false);
        acao
          ? Swal.fire({
              icon: 'error',
              title: 'Erro ao Inativar Abono',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            })
          : Swal.fire({
              icon: 'error',
              title: 'Erro ao Ativar Abono',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            });
      }
    },
    [refresh],
  );

  const handleCertezaDelete = useCallback(
    (abonoId: number, acao: boolean) => {
      Swal.fire({
        title: 'Tem certeza que deseja Inativar o Abono?',
        // text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Não',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.isConfirmed) {
          handleDelete(abonoId, acao);
        }
      });
    },
    [handleDelete],
  );

  const handleCertezaActive = useCallback(
    (abonoId: number, acao: boolean) => {
      Swal.fire({
        title: 'Tem certeza que deseja Ativar o Abono?',
        // text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Não',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.isConfirmed) {
          handleDelete(abonoId, acao);
        }
      });
    },
    [handleDelete],
  );

  return (
    <Container>
      <ModalConexao />
      <DrawerApp />
      {/* <Header /> */}
      <ContainerOptions>
        <ContainerButtonCor cor={buttonCorListar ? '#006600' : '#ffffff'}>
          <button
            type="button"
            onClick={() => {
              setButtonCorListar(true);
              setButtonCorAdd(false);
              setButtonCorEdit(false);
              setButtonCorRemove(false);
              setButtonCorActive(false);
            }}
          >
            <div style={{ display: 'flex' }}>
              <FiList />
              <p>
                <Tooltip
                  title="Listar todos os Abonos."
                  className="classList"
                  // tamanho={item.larguraInfo}
                >
                  <BsQuestionCircleFill className="iconList" size={15} />
                </Tooltip>
              </p>
            </div>
          </button>
        </ContainerButtonCor>
        <ContainerButtonCor cor={buttonCorAdd ? '#006600' : '#ffffff'}>
          <button
            type="button"
            onClick={() => {
              setButtonCorListar(false);
              setButtonCorAdd(true);
              setButtonCorEdit(false);
              setButtonCorRemove(false);
              setButtonCorActive(false);
            }}
          >
            <div style={{ display: 'flex' }}>
              <FiPlusCircle />
              <p>
                <Tooltip title="Adicionar Abono." className="classList">
                  <BsQuestionCircleFill className="iconList" size={15} />
                </Tooltip>
              </p>
            </div>
          </button>
        </ContainerButtonCor>
        <ContainerButtonCor cor={buttonCorEdit ? '#006600' : '#ffffff'}>
          <button
            type="button"
            onClick={() => {
              setButtonCorListar(false);
              setButtonCorAdd(false);
              setButtonCorEdit(true);
              setButtonCorRemove(false);
              setButtonCorActive(false);
              if (!userEdit) {
                setInformacao(true);
              }
            }}
          >
            <div style={{ display: 'flex' }}>
              <FiEdit2 />
              <p>
                <Tooltip title="Editar Abono." className="classList">
                  <BsQuestionCircleFill className="iconList" size={15} />
                </Tooltip>
              </p>
            </div>
          </button>
        </ContainerButtonCor>
        <ContainerButtonCor cor={buttonCorRemove ? '#006600' : '#ffffff'}>
          <button
            type="button"
            onClick={() => {
              setButtonCorListar(false);
              setButtonCorAdd(false);
              setButtonCorEdit(false);
              setButtonCorRemove(true);
              setButtonCorActive(false);
              if (!userEdit) {
                setInformacao(true);
              } else {
                handleCertezaDelete(userEdit.ID, false);
              }
            }}
          >
            <div style={{ display: 'flex' }}>
              <FiXCircle />
              <p>
                <Tooltip title="Inativar Abono." className="classList">
                  <BsQuestionCircleFill className="iconList" size={15} />
                </Tooltip>
              </p>
            </div>
          </button>
        </ContainerButtonCor>
        <ContainerButtonCor cor={buttonCorActive ? '#006600' : '#ffffff'}>
          <button
            type="button"
            onClick={() => {
              setButtonCorListar(false);
              setButtonCorAdd(false);
              setButtonCorEdit(false);
              setButtonCorRemove(false);
              setButtonCorActive(true);
              if (!userEdit) {
                setInformacao(true);
              } else {
                handleCertezaActive(userEdit.ID, true);
              }
            }}
          >
            <div style={{ display: 'flex' }}>
              <FiCheckCircle />
              <p>
                <Tooltip title="Ativar Abono." className="classList">
                  <BsQuestionCircleFill className="iconList" size={15} />
                </Tooltip>
              </p>
            </div>
          </button>
        </ContainerButtonCor>
      </ContainerOptions>
      {buttonCorAdd && (
        <ContainerTableAdd>
          <table>
            <HeaderTable headers={headersAdd} />
            <tbody>
              <tr>
                <td>
                  <ContainerFormulario>
                    <input
                      type="text"
                      onBlur={(e) => setTextAbono(e.target.value)}
                    />
                    <button
                      className="buttonOptions"
                      type="button"
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      <FiSave />
                    </button>
                  </ContainerFormulario>
                </td>
              </tr>
            </tbody>
          </table>
        </ContainerTableAdd>
      )}
      <ContainerTable>
        <table>
          <HeaderTable headers={headers} />
          <tbody>
            {response.map((res: ResponseGet, index) => (
              <tr
                key={`${res.NOME + index}`}
                style={{
                  backgroundColor: userEdit?.NOME === res.NOME ? '#595959' : '',
                }}
              >
                {res.NOME === userEdit?.NOME && buttonCorEdit && (
                  <td>
                    <ContainerFormulario>
                      <input
                        type="text"
                        defaultValue={res.NOME}
                        onBlur={(e) => setTextAbono(e.target.value)}
                      />
                      <button
                        className="buttonOptions"
                        type="button"
                        onClick={() => {
                          setUserEdit(res);
                          handleSubmitEdit(res.ID);
                        }}
                      >
                        <FiSave />
                      </button>
                    </ContainerFormulario>
                  </td>
                )}
                {res.NOME === userEdit?.NOME && buttonCorRemove && (
                  <td>
                    <ContainerFormulario>
                      <p style={{ width: 250 }}>{res.NOME}</p>
                      <IconLista cor={res.ATIVO ? '#439133' : '#ff0000'}>
                        {res.ATIVO && <FiCheckCircle />}
                        {!res.ATIVO && <FiXCircle />}
                      </IconLista>
                    </ContainerFormulario>
                  </td>
                )}
                {!(
                  res.NOME === userEdit?.NOME &&
                  (buttonCorEdit || buttonCorRemove)
                ) && (
                  <td>
                    <button
                      type="button"
                      onClick={() => {
                        setUserEdit(res);
                      }}
                    >
                      <div style={{ display: 'flex' }}>
                        <p style={{ minWidth: 250 }}>{res.NOME}</p>
                        <IconLista cor={res.ATIVO ? '#439133' : '#ff0000'}>
                          {res.ATIVO && <FiCheckCircle />}
                          {!res.ATIVO && <FiXCircle />}
                        </IconLista>
                      </div>
                    </button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </ContainerTable>
      {informacao && (
        <ModalInformacao
          title="Ajuda"
          content="Para Editar, Ativar ou Inativar clique no elemento da lista!"
          onClose={() => {
            setInformacao(false);
          }}
        />
      )}
      {loading && <Loading />}
    </Container>
  );
};

export default CadastroAbono;
