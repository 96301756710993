import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  max-width: auto;
  align-items: center;
  max-height: 100vh;
`;

export const ContainerPesquisaGeral = styled.div`
  width: 90%;
  margin: 0px auto;
  margin-top: 15px;
  margin-bottom: 20px;
  padding: 55px 10px 30px 10px;
  background-color: #3e3946;
  overflow-x: auto;
  border-radius: 5px;
  h3 {
    text-align: center;
  }
`;

export const ContainerSupFilter = styled.div`
  display: flex;
  width: 100%;
  margin: 0px auto;
  justify-content: center;
  p {
    font-size: 17px;
    font-family: Arial;
    margin: 7px 5px 0px 10px;
  }
  select {
    background-color: #f1f1f1;
    color: #439133;
    border-radius: 10px;
    margin: 0px;
    margin-right: 10px;
    border: none;
    justify-content: center;
    width: 220px;
    padding: 5px 10px;
  }
  input {
    background-color: #f1f1f1;
    color: #439133;
    border-radius: 10px;
    border: none;
    padding: 10px;
    height: 40px;
    width: 120px;
    margin-left: 10px;
  }
`;

export const ContainerInfFilter = styled.div`
  display: flex;
  margin-left: 20px;
  justify-content: center;
  p {
    font-size: 17px;
    font-family: Arial;
    margin: 10px;
  }
  input {
    height: 20px;
    width: 20px;
  }
  label {
    font-size: 14px;
    text-align: center;
    font-family: Arial;
    width: 100px;
    padding: 10px 5px;
  }
  button {
    height: 40px;
    padding: 0px 10px;
    justify-content: center;
    align-content: center;
    background-color: #439133;
    color: #ffffff;
    border-radius: 5px;
    border: none;
    &:hover {
      background-color: #57bd42;
    }
  }
  svg {
    margin-left: 3px;
  }
`;

export const ContainerImprimir = styled.div`
  display: flex;
  margin-left: 20px;
  justify-content: center;
  a {
    padding: 10px;
    background-color: #1661a7;
    color: #ffffff;
    border-radius: 5px;
    border: none;
    &:hover {
      background-color: #1890ff;
    }
  }
`;

export const ContainerVisualizarPDF = styled.div`
  background: white;
  color: black;
  margin: 10px 0;
  td {
    color: black;
    strong {
      color: black;
    }
  }
`;

export const ContainerTable = styled.div`
  //padding: 0px 20px;
  width: auto;
  margin-left: 40px;
  margin-right: 40px;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  //max-width: 98%;
  max-height: 50vh;
  overflow-x: auto; /** table fica do tamanho da tela com scroll. */
  table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #666666;
    border-top: none;
    th {
      //margin-top: 20px;
      //height: 30px;
      border-bottom: 1px solid #006600;
      justify-content: space-between;
      padding: 5px 40px;
      background-color: #004d00;
      color: #ffffff;
      position: sticky;
      position: -webkit-sticky;
      top: 0;
    }
  }
  tbody {
    border-bottom: 2px solid #666666;
    td {
      border-bottom: 1px solid #004600;
      margin: 20px;
      padding: 5px;
      text-align: center;
      font-size: 14px;

      button {
        background-color: transparent;
        border: none;
        color: #fff !important;

        &:hover {
          color: ${shade(0.3, '#fff')} !important;
        }
      }
    }
    tr {
      /**funcao para fazer as linhas da tabela uma de cada cor de forma alternada.*/
      &:nth-child(even) {
        background-color: #323232;
      }
      &:hover {
        background-color: #454545;
      }
    }
  }
`;

