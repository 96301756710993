import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { FiEdit2, FiTrash, FiPlusCircle } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import Search from '../../components/Search';
import HeaderTable from '../../components/HeaderTable';
import Button from '../../components/Button';
import DropDown from '../../components/DropDown';
import api from '../../services/api';
import { maskCpf } from '../../components/Input/mask';
import ModalConexao from '../../components/ModalConexao';
import { Loading } from '../../components/Loading';
import Pagination from '../../components/Pagination';

import {
  Container,
  ContainerSearch,
  ContainerTable,
  ContainerAccess,
  AnimationContainer,
  ContentSubBody,
  ContentSubLabel,
  ContainerPagination,
} from './styles';
import DrawerApp from '../../components/Drawer';

interface FormData {
  usuario: string;
  setor: string;
  nivelAcesso: string;
}

interface ResponseGet {
  ID: number;
  CPF: string;
  EMAIL: string;
  NivelACesso: number;
  Nome: string;
  Setor: string;
  SetorID: number;
  idUsuario: number;
}

const setores = [
  { valor: 'Administrativo', id: 7, NivelAcesso: 1 },
  { valor: 'Todos', id: 15, NivelAcesso: 1 },
];

const UserAccess: React.FC = () => {
  const { userAcesso } = useAuth();
  const history = useHistory();
  const [search, setSearch] = useState('');
  const [searchCPF, setSearchCPF] = useState('');
  const [searchEmail, setSearchEmail] = useState('');
  const [createUser, setCreateUser] = useState(false); // botao ativa true para abir tela de cadastro de user
  const [editUser, setEditUser] = useState(false); // editar cadastro do user
  const [setor, setSetor] = useState(1);
  const [nivelAcesso, setNivelAcesso] = useState(1);
  const [response, setResponse] = useState<ResponseGet[]>([]);
  const [userAdd, setUserAdd] = useState<ResponseGet>(); // dados do usuario para add nivel de acesso
  const [userEdit, setUserEdit] = useState<ResponseGet>(); // dados do usuario para edit nivel de acesso
  const [refresh, setRefresh] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalTabela, setTotalTabela] = useState(0);
  const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState<number>(20);

  const dropDownSetor = [
    { valor: 'Nenhum', id: 1 },
    { valor: 'Operacional', id: 2 },
    { valor: 'Call Center', id: 3 },
    { valor: 'Diretoria', id: 4 },
    { valor: 'Controladoria', id: 5 },
    { valor: 'Sesmt', id: 6 },
    { valor: 'Administrativo', id: 7 },
    { valor: 'Financeiro', id: 8 },
    { valor: 'Jurídico', id: 9 },
    { valor: 'Compras', id: 10 },
    { valor: 'Estoque', id: 11 },
    { valor: 'Faturamento', id: 12 },
    { valor: 'Recursos Humanos', id: 13 },
    { valor: 'Departamento Pessoal', id: 14 },
    { valor: 'Marketing', id: 16 },
    { valor: 'OAK', id: 17 },
    { valor: 'Todos', id: 15 },
  ];

  const dropDownNivelAcesso = [
    { valor: 'Estagiário', id: 1 },
    { valor: 'Auxiliar', id: 2 },
    { valor: 'Assistente', id: 3 },
    { valor: 'Analista', id: 4 },
    { valor: 'Supervisor', id: 5 },
    { valor: 'Gerente', id: 6 },
    { valor: 'Diretor', id: 7 },
    { valor: 'Presidente', id: 8 },
  ];

  const headers = [
    { name: 'Nome', field: 'name' },
    { name: 'CPF', field: 'cpf' },
    { name: 'E-mail', field: 'email' },
    { name: 'Setor', field: 'setor' },
    { name: 'Nível de Acesso', field: 'nivelAcesso' },
    { name: 'Editar/Remover', field: 'editar' },
  ];

  const dropDown = [
    { valor: '20', id: 20 },
    { valor: '30', id: 30 },
    { valor: '40', id: 40 },
    { valor: '50', id: 50 },
    { valor: 'todos', id: 1 },
  ];

  useEffect(() => {
    let semAcesso = true;
    let access;
    userAcesso.forEach((user) => {
      access = setores.filter(
        (s) => s.id === user.SetorID && user.NivelACesso >= s.NivelAcesso,
      );
      if (access.length > 0) {
        semAcesso = false;
      }
    });
    if (semAcesso) {
      history.push('/dashboard');
      Swal.fire({
        icon: 'info',
        title:
          'Seu nível de acesso não tem permissão para acessar essa página!',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
    } else {
      setLoading(true);
      api
        .get(`/nivelAcesso/getNivelAcesso`)
        .then((data) => {
          setResponse(data.data);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          if (navigator.onLine) {
            Swal.fire({
              icon: 'info',
              title: 'Erro ao carregar dados, por favor atualize a página!',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            });
          }
        });
    }
  }, [history, refresh, userAcesso]);

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});
      await api
        .post('nivelAcesso/', {
          user_id: userAdd?.idUsuario,
          nivelAcesso,
          setor,
        })
        .then(() => {
          setLoading(false);
          Swal.fire({
            icon: 'success',
            title: 'Nível de Acesso do Usuário Criado com Sucesso!',
            showConfirmButton: false,
            timer: 1500,
          });
          setRefresh(!refresh);
          setNivelAcesso(1);
          setSetor(1);
          setCreateUser(false);
        })
        .catch(() => {
          setLoading(false);
          Swal.fire({
            icon: 'error',
            title: 'Usuário já está associado a um setor.',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          });
        });
    } catch (err) {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Erro ao salvar Nível de Acesso do Usuário',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
    }
  }, [setor, nivelAcesso, userAdd, refresh]);

  const handleSubmitEdit = useCallback(async () => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});
      await api
        .put('/nivelAcesso/updateAcesso', {
          user_id: userEdit?.idUsuario,
          NovoNivelAcesso: nivelAcesso,
          NovoSetor: setor,
          setor: userEdit?.SetorID,
        })
        .then(() => {
          setLoading(false);
          Swal.fire({
            icon: 'success',
            title: 'Nível de Acesso do Usuário Atualizado com Sucesso!',
            showConfirmButton: false,
            timer: 1500,
          });
          setRefresh(!refresh);
          setNivelAcesso(1);
          setSetor(1);
          setEditUser(false);
        })
        .catch(() => {
          setLoading(false);
          Swal.fire({
            icon: 'error',
            title: 'Erro ao Atualizar Nível de Acesso do Usuário',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          });
        });
    } catch (err) {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Erro ao salvar Nível de Acesso do Usuário',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
    }
  }, [userEdit, nivelAcesso, setor, refresh]);

  const handleDelete = useCallback(
    async (userId: number, setorId: number) => {
      try {
        setLoading(true);
        await api
          .delete(`nivelAcesso/removeAcesso/`, {
            data: {
              user_id: userId,
              setor: setorId,
            },
          })
          .then(() => {
            setLoading(false);
            Swal.fire({
              icon: 'success',
              title: 'Nível de Acesso do Usuário foi Deletado!',
              showConfirmButton: false,
              timer: 1500,
            });
            setRefresh(!refresh);
          })
          .catch(() => {
            setLoading(false);
            Swal.fire({
              icon: 'error',
              title: 'Erro ao Deletar Nível de Acesso do Usuário',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            });
          });

        setNivelAcesso(1);
        setSetor(1);
      } catch (err) {
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Erro ao Deletar Nível de Acesso do Usuário',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        });
      }
    },
    [refresh],
  );

  const handleCertezaDelete = useCallback(
    (userId: number, setorId: number) => {
      Swal.fire({
        title: 'Tem certeza que deseja Apagar?',
        // text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Não',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.isConfirmed) {
          handleDelete(userId, setorId);
        }
      });
    },
    [handleDelete],
  );

  function nivelAcessoNumberToString(id: number): string {
    const data = dropDownNivelAcesso.filter((dados) => id === dados.id);
    return data[0].valor;
  }

  function setorAndNivelStringToNumber(valor: string, tipo: string): number {
    if (tipo === 'setor') {
      const data = dropDownSetor.filter((dados) => valor === dados.valor);
      return data[0].id;
    }
    const data = dropDownNivelAcesso.filter((dados) => valor === dados.valor);
    return data[0].id;
  }

  const responseData = useMemo(() => {
    let computedResponses: ResponseGet[] = [];
    computedResponses = response;

    if (search) {
      computedResponses = computedResponses.filter((res: ResponseGet) =>
        res.Nome.toLowerCase().includes(search.toLowerCase()),
      );
    }
    if (searchCPF) {
      let CPF: string = searchCPF.replace('.', '');
      CPF = CPF.replace('.', '');
      CPF = CPF.replace('-', '');
      computedResponses = computedResponses.filter((res: ResponseGet) =>
        res.CPF.toLowerCase().includes(CPF.toLowerCase()),
      );
    }
    if (searchEmail) {
      computedResponses = computedResponses.filter((res: ResponseGet) =>
        res.EMAIL.toLowerCase().includes(searchEmail.toLowerCase()),
      );
    }

    setTotalTabela(computedResponses.length);
    if (ITEMS_PER_PAGE === 1) {
      return computedResponses;
    }

    return computedResponses.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE,
    );
  }, [ITEMS_PER_PAGE, currentPage, response, search, searchCPF, searchEmail]);

  return (
    <Container>
      <ModalConexao />
      <DrawerApp />
      {/* <Header /> */}
      <ContainerSearch>
        <Search
          onSearch={(value: string) => {
            setSearch(value);
          }}
          nomePlaceHolder="Buscar Nome"
        />
        <Search
          onSearch={(value: string) => {
            setSearchCPF(value);
          }}
          nomePlaceHolder="Buscar CPF"
        />
        <Search
          onSearch={(value: string) => {
            setSearchEmail(value);
          }}
          nomePlaceHolder="Buscar E-mail"
        />
      </ContainerSearch>
      <ContainerTable>
        <table>
          <HeaderTable headers={headers} />
          <tbody>
            {responseData.map((res: ResponseGet) => (
              <tr key={res.CPF + res.Setor}>
                <td style={{ minWidth: 250 }}>{res.Nome}</td>
                <td style={{ minWidth: 120 }}>{maskCpf(res.CPF)}</td>
                <td>{res.EMAIL}</td>
                <td style={{ minWidth: 150 }}>{res.Setor}</td>
                <td style={{ minWidth: 150 }}>
                  {nivelAcessoNumberToString(res.NivelACesso)}
                </td>
                <td
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    padding: 5,
                  }}
                >
                  <button
                    type="button"
                    onClick={() => {
                      setCreateUser(true);
                      setEditUser(false);
                      setUserAdd(res);
                    }}
                  >
                    <FiPlusCircle />
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setEditUser(true);
                      setCreateUser(false);
                      setUserEdit(res);
                    }}
                  >
                    <FiEdit2 />
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      handleCertezaDelete(res.idUsuario, res.SetorID);
                    }}
                  >
                    <FiTrash />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </ContainerTable>
      <ContainerPagination>
        <Pagination
          total={totalTabela}
          itemsPerPage={ITEMS_PER_PAGE}
          currentPage={currentPage}
          onPageChange={(page: number) => setCurrentPage(page)}
        />
        <DropDown
          onChangeItems={(value: string) => {
            setCurrentPage(1);
            if (value === 'todos') {
              setITEMS_PER_PAGE(1);
            } else {
              setITEMS_PER_PAGE(Number(value));
            }
          }}
          objetoEnum={dropDown}
          minWidth={30}
        />
      </ContainerPagination>
      <ContainerTable>
        {editUser && (
          <>
            <ContainerAccess>
              <AnimationContainer>
                <Form ref={formRef} onSubmit={handleSubmitEdit}>
                  <ContentSubBody style={{ height: 50 }}>
                    <ContentSubLabel
                      style={{
                        borderTopLeftRadius: 15,
                        borderBottomLeftRadius: 15,
                        paddingLeft: 15,
                      }}
                    />
                    <p
                      style={{
                        fontFamily: 'Arial',
                        alignSelf: 'center',
                        justifySelf: 'center',
                        padding: 8,
                        margin: 0,
                        height: 40,
                        minWidth: 150,
                        maxWidth: 450,
                        backgroundColor: '#ffffff',
                        color: '#888888',
                      }}
                    >
                      {userEdit?.Nome}
                    </p>
                    <ContentSubLabel style={{ marginLeft: 0, marginRight: 0 }}>
                      Setor
                    </ContentSubLabel>
                    <DropDown
                      onChangeItems={(value: string) => {
                        setSetor(setorAndNivelStringToNumber(value, 'setor'));
                      }}
                      objetoEnum={dropDownSetor}
                      minWidth={150}
                      style={{ margin: 50 }}
                    />
                    <ContentSubLabel style={{ marginLeft: 0, marginRight: 0 }}>
                      Acesso
                    </ContentSubLabel>
                    <DropDown
                      onChangeItems={(value: string) => {
                        setNivelAcesso(
                          setorAndNivelStringToNumber(value, 'NA'),
                        );
                      }}
                      objetoEnum={dropDownNivelAcesso}
                      minWidth={150}
                    />
                    <ContentSubLabel
                      style={{
                        marginLeft: 0,
                        borderTopRightRadius: 15,
                        borderBottomRightRadius: 15,
                        paddingLeft: 15,
                      }}
                    />
                    <Button
                      type="submit"
                      style={{
                        maxWidth: 150,
                        marginLeft: 'auto',
                        marginRight: 3,
                        maxHeight: 40,
                        marginTop: 0,
                      }}
                    >
                      Atualizar
                    </Button>
                    <Button
                      type="button"
                      style={{
                        maxWidth: 150,
                        marginLeft: 0,
                        maxHeight: 40,
                        marginTop: 0,
                        backgroundColor: '#b30000',
                      }}
                      onClick={() => setEditUser(!editUser)}
                    >
                      Cancelar Edição
                    </Button>
                  </ContentSubBody>
                </Form>
              </AnimationContainer>
            </ContainerAccess>
          </>
        )}
        {createUser && (
          <>
            <ContainerAccess>
              <AnimationContainer>
                <Form ref={formRef} onSubmit={handleSubmit}>
                  <ContentSubBody style={{ height: 50 }}>
                    <ContentSubLabel
                      style={{
                        borderTopLeftRadius: 15,
                        borderBottomLeftRadius: 15,
                        paddingLeft: 15,
                      }}
                    />
                    <p
                      style={{
                        fontFamily: 'Arial',
                        alignSelf: 'center',
                        justifySelf: 'center',
                        padding: 8,
                        margin: 0,
                        height: 40,
                        minWidth: 150,
                        maxWidth: 450,
                        backgroundColor: '#ffffff',
                        color: '#888888',
                      }}
                    >
                      {userAdd?.Nome}
                    </p>
                    <ContentSubLabel style={{ marginLeft: 0, marginRight: 0 }}>
                      Setor
                    </ContentSubLabel>
                    <DropDown
                      onChangeItems={(value: string) => {
                        setSetor(setorAndNivelStringToNumber(value, 'setor'));
                      }}
                      objetoEnum={dropDownSetor}
                      minWidth={150}
                      style={{ margin: 50 }}
                    />
                    <ContentSubLabel style={{ marginLeft: 0, marginRight: 0 }}>
                      Acesso
                    </ContentSubLabel>
                    <DropDown
                      onChangeItems={(value: string) => {
                        setNivelAcesso(
                          setorAndNivelStringToNumber(value, 'NA'),
                        );
                      }}
                      objetoEnum={dropDownNivelAcesso}
                      minWidth={150}
                    />
                    <ContentSubLabel
                      style={{
                        marginLeft: 0,
                        borderTopRightRadius: 15,
                        borderBottomRightRadius: 15,
                        paddingLeft: 15,
                      }}
                    />
                    <Button
                      type="submit"
                      style={{
                        maxWidth: 150,
                        marginLeft: 'auto',
                        marginRight: 3,
                        maxHeight: 40,
                        marginTop: 0,
                      }}
                    >
                      Cadastrar
                    </Button>
                    <Button
                      type="button"
                      style={{
                        maxWidth: 150,
                        marginLeft: 0,
                        maxHeight: 40,
                        marginTop: 0,
                        backgroundColor: '#b30000',
                      }}
                      onClick={() => setCreateUser(false)}
                    >
                      Cancelar Adição
                    </Button>
                  </ContentSubBody>
                </Form>
              </AnimationContainer>
            </ContainerAccess>
          </>
        )}
      </ContainerTable>
      {loading && <Loading />}
    </Container>
  );
};

export default UserAccess;
