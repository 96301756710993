import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }
  .react-datepicker, .react-datepicker-popper{
    z-index:3
  }
  &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px #595959;
      border-radius: 10px;
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 12px;
      height: 12px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px #595959;
      background-color: #404040;
    }

  body {
    background: #312E38;
    color: #FFF;
    -webkit-font-smooothing: antialiased;
  }

  border-style, input, button {
    font-size: 16px;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
    color: #ffffff;
  }

  input, textarea {
    color: #000;
  }


  button {
    cursor: pointer;
  }
`;
