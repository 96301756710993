import React, { useState } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import ModalConexao from '../../components/ModalConexao';
import { Loading } from '../../components/Loading';
import DrawerApp from '../../components/Drawer';
import { useAuth } from '../../hooks/auth';
import {
  Container,
  ContainerPesquisaGeral,
  ContainerSupFilter,
  ContainerInfFilter,
  VisualizarPDF,
  ContainerOpcoesPDF,
  ContainerPeriodo,
} from './styles';
import DropDown from '../../components/DropDown';

const ContraCheque: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { user } = useAuth();
  const [anoDropdown, setAnoDropdown] = useState('');

  const anosDropdown = [{ valor: '2024', id: 2024 }];
  const handleSubmit = (view: boolean): void => {
    setLoading(true);
    axios
      .get(
        `https://app-colaborador-comprovante-pdf.s3.us-east-1.amazonaws.com/2024/${user.cpf}.pdf`,
        { responseType: 'arraybuffer' },
      )
      .then(({ data: dados }: any) => {
        const bytes = new Uint8Array(dados);
        const blob = new Blob([bytes], { type: 'application/pdf' });
        if (view) {
          const fileURL = URL.createObjectURL(blob);
          window.open(fileURL);
        } else {
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          const fileName = 'Imposto de renda';
          link.download = fileName;
          link.click();
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title:
            'Não foi possível encontrar uma declaração de rendimentos nessa data informada.',
          showClass: {
            popup: 'animate_animated animate_fadeInDown',
          },
          hideClass: {
            popup: 'animate_animated animate_fadeOutUp',
          },
        });
      });
  };

  return (
    <Container>
      <ModalConexao />
      <DrawerApp />
      <ContainerPesquisaGeral>
        <ContainerSupFilter>
          <span>
            Comprovante de Rendimentos para Declaração de Imposto de Renda
          </span>
          <ContainerInfFilter>
            <ContainerPeriodo>
              <span>Período:</span>
              <DropDown
                onChangeItems={(value: string) => {
                  setAnoDropdown(value);
                }}
                objetoEnum={anosDropdown}
                minWidth={30}
              />
            </ContainerPeriodo>
            <ContainerOpcoesPDF>
              <VisualizarPDF type="button" onClick={() => handleSubmit(true)}>
                Visualizar
              </VisualizarPDF>
              <button type="button" onClick={() => handleSubmit(false)}>
                Imprimir
              </button>
            </ContainerOpcoesPDF>
          </ContainerInfFilter>
        </ContainerSupFilter>
      </ContainerPesquisaGeral>
      {loading && <Loading />}
    </Container>
  );
};

export default ContraCheque;
