import { ResponseGet } from '../Interface/index';

function preencher(data: ResponseGet): ResponseGet {
  const result: ResponseGet[] = [];
  result.push({
    NOME_CLIENTE: data.NOME_CLIENTE,
    NOME: data.NOME,
    CPFSEMFORMATO: data.CPFSEMFORMATO,
    CONTRATO: data.CONTRATO,
    POSTO: data.POSTO,
    FUNCAO: data.FUNCAO,
    ATRASO: data.ATRASO,
    ESCALA: data.ESCALA,
    HORA_FINAL: data.HORA_FINAL,
    HORA_INICIAL: data.HORA_INICIAL,
    TURNO_DESC: data.TURNO_DESC,
    AFASTAMENTO: data.AFASTAMENTO,
    FERIAS: data.FERIAS,
    SEM_PONTO: data.SEM_PONTO,
    COD_SITUACAO: data.COD_SITUACAO,
    MOTIVO_AFASTAMENTO: data.MOTIVO_AFASTAMENTO,
    DATA_INICIAL_FERIAS_AFASTAMENTO: data.DATA_INICIAL_FERIAS_AFASTAMENTO,
    DATA_FINAL_FERIAS_AFASTAMENTO: data.DATA_FINAL_FERIAS_AFASTAMENTO,
    DATA_VOLTA_FERIAS_AFASTAMENTO: data.DATA_VOLTA_FERIAS_AFASTAMENTO,
    LOCAL_LATITUDE: data.LOCAL_LATITUDE,
    LOCAL_LONGITUDE: data.LOCAL_LONGITUDE,
    PONTO_DIVERGENTE: data.PONTO_DIVERGENTE,
    PONTO_DIFERENCA_METROS_ENTRADA: data.PONTO_DIFERENCA_METROS_ENTRADA,
    PONTO_DIFERENCA_METROS_PAUSA: data.PONTO_DIFERENCA_METROS_PAUSA,
    PONTO_DIFERENCA_METROS_SAIDA: data.PONTO_DIFERENCA_METROS_SAIDA,
    PONTO_DIFERENCA_METROS_VOLTA: data.PONTO_DIFERENCA_METROS_VOLTA,
    PONTO_LATITUDE_ENTRADA: data.PONTO_LATITUDE_ENTRADA,
    PONTO_LATITUDE_PAUSA: data.PONTO_LATITUDE_PAUSA,
    PONTO_LATITUDE_SAIDA: data.PONTO_LATITUDE_SAIDA,
    PONTO_LATITUDE_VOLTA: data.PONTO_LATITUDE_VOLTA,
    PONTO_LONGITUDE_ENTRADA: data.PONTO_LONGITUDE_ENTRADA,
    PONTO_LONGITUDE_PAUSA: data.PONTO_LONGITUDE_PAUSA,
    PONTO_LONGITUDE_SAIDA: data.PONTO_LONGITUDE_SAIDA,
    PONTO_LONGITUDE_VOLTA: data.PONTO_LONGITUDE_VOLTA,
    DATA_INICIAL_DIA_AGENDA: data.DATA_INICIAL_DIA_AGENDA,
    PONTO_DATA_ENTRADA: data.PONTO_DATA_ENTRADA,
    PONTO_DATA_PAUSA: data.PONTO_DATA_PAUSA,
    PONTO_DATA_SAIDA: data.PONTO_DATA_SAIDA,
    PONTO_DATA_VOLTA: data.PONTO_DATA_VOLTA,
    PONTO_DATA_FINAL_PONTO: data.PONTO_DATA_FINAL_PONTO,
    PONTO_DATA_INICIAL_PONTO: data.PONTO_DATA_INICIAL_PONTO,
    PONTO_HORA_ENTRADA: data.PONTO_HORA_ENTRADA,
    PONTO_HORA_PAUSA: data.PONTO_HORA_PAUSA,
    PONTO_HORA_SAIDA: data.PONTO_HORA_SAIDA,
    PONTO_HORA_VOLTA: data.PONTO_HORA_VOLTA,
    PONTO_LINK_FOTO_ENTRADA: data.PONTO_LINK_FOTO_ENTRADA,
    PONTO_LINK_FOTO_PAUSA: data.PONTO_LINK_FOTO_PAUSA,
    PONTO_LINK_FOTO_SAIDA: data.PONTO_LINK_FOTO_SAIDA,
    PONTO_LINK_FOTO_VOLTA: data.PONTO_LINK_FOTO_VOLTA,
    HORAS_A_TRABALHAR: data.HORAS_A_TRABALHAR,
    LIMITE_METROS: data.LIMITE_METROS,
    EXTRA: data.EXTRA,
    HORAS_TRABALHADAS_MENOS_HORA_PAUSA: data.HORAS_TRABALHADAS_MENOS_HORA_PAUSA,
    HORA_DIVERGENTE: data.HORA_DIVERGENTE,
    COM_PONTO: data.COM_PONTO,
    ABONO: data.ABONO,
    DATA_ABONO: data.DATA_ABONO,
    DATA_ABONO_FALTA: data.DATA_ABONO_FALTA,
    MOTIVO_ABONO: data.MOTIVO_ABONO,
    Id_UsersAppColaborador: data.Id_UsersAppColaborador,
    USER_ACEITOU_ABONO: data.USER_ACEITOU_ABONO,
    Id_PontoEletronico: data.Id_PontoEletronico,
    ENTRADA_ABONADA: data.ENTRADA_ABONADA,
    PAUSA_ABONADA: data.PAUSA_ABONADA,
    VOLTA_ABONADA: data.VOLTA_ABONADA,
    SAIDA_ABONADA: data.SAIDA_ABONADA,
    DIA_ABONADO: data.DIA_ABONADO,
    PERIODO_ABONADO: data.PERIODO_ABONADO,
    FALTA_ABONADA: data.FALTA_ABONADA,
    DESC_FALTA: data.DESC_FALTA,
    DESC_DIA: data.DESC_DIA,
    DESC_PERIODO: data.DESC_PERIODO,
    DESC_ENTRADA: data.DESC_ENTRADA,
    DESC_MOTIVO_ABONO: data.DESC_MOTIVO_ABONO,
    DESC_PAUSA: data.DESC_PAUSA,
    DESC_SAIDA: data.DESC_SAIDA,
    DESC_VOLTA: data.DESC_VOLTA,
  });
  return result[0];
}

export const arrayUnique = (data: ResponseGet[]): ResponseGet[] => {
  const result: ResponseGet[] = [];
  const map = new Map();
  data.forEach((item) => {
    if (!item.SEM_PONTO) {
      if (!map.has(item.CPFSEMFORMATO) && item.CPFSEMFORMATO !== null) {
        map.set(item.CPFSEMFORMATO, true);
        result.push(preencher(item));
      }
    }
  });
  return result;
};

export const arrayUniquePontoDivergente = (
  data: ResponseGet[],
): ResponseGet[] => {
  const result: ResponseGet[] = [];
  const map = new Map();
  data.forEach((item) => {
    if (item.PONTO_DIVERGENTE) {
      if (!map.has(item.CPFSEMFORMATO) && item.CPFSEMFORMATO !== null) {
        map.set(item.CPFSEMFORMATO, true);
        result.push(preencher(item));
      }
    }
  });
  return result;
};

export const arrayUniqueFalta = (data: ResponseGet[]): ResponseGet[] => {
  const result: ResponseGet[] = [];
  const map = new Map();
  data.forEach((item) => {
    if (item.SEM_PONTO) {
      if (!map.has(item.CPFSEMFORMATO) && item.CPFSEMFORMATO !== null) {
        map.set(item.CPFSEMFORMATO, true);
        result.push(preencher(item));
      }
    }
  });
  return result;
};

export const arrayUniqueAtraso = (data: ResponseGet[]): ResponseGet[] => {
  const result: ResponseGet[] = [];
  const map = new Map();
  data.forEach((item) => {
    if (item.ATRASO) {
      if (!map.has(item.CPFSEMFORMATO) && item.CPFSEMFORMATO !== null) {
        map.set(item.CPFSEMFORMATO, true);
        result.push(preencher(item));
      }
    }
  });
  return result;
};

export const arrayUniqueAfastamento = (data: ResponseGet[]): ResponseGet[] => {
  const result: ResponseGet[] = [];
  const map = new Map();
  data.forEach((item) => {
    if (item.AFASTAMENTO) {
      if (!map.has(item.CPFSEMFORMATO) && item.CPFSEMFORMATO !== null) {
        map.set(item.CPFSEMFORMATO, true);
        result.push(preencher(item));
      }
    }
  });
  return result;
};

export const arrayUniqueFerias = (data: ResponseGet[]): ResponseGet[] => {
  const result: ResponseGet[] = [];
  const map = new Map();
  data.forEach((item) => {
    if (item.FERIAS) {
      if (!map.has(item.CPFSEMFORMATO) && item.CPFSEMFORMATO !== null) {
        map.set(item.CPFSEMFORMATO, true);
        result.push(preencher(item));
      }
    }
  });
  return result;
};

export const arrayUniqueHoraDivergente = (
  data: ResponseGet[],
): ResponseGet[] => {
  const result: ResponseGet[] = [];
  const map = new Map();
  data.forEach((item) => {
    if (item.HORA_DIVERGENTE) {
      if (!map.has(item.CPFSEMFORMATO) && item.CPFSEMFORMATO !== null) {
        map.set(item.CPFSEMFORMATO, true);
        result.push(preencher(item));
      }
    }
  });
  return result;
};

export const arrayUniqueHoraExtra = (data: ResponseGet[]): ResponseGet[] => {
  const result: ResponseGet[] = [];
  const map = new Map();
  data.forEach((item) => {
    if (
      // item.LOCAL_LATITUDE === '-3.841418' &&
      // item.LOCAL_LONGITUDE === '-38.513317' &&
      item.EXTRA
    ) {
      if (!map.has(item.CPFSEMFORMATO) && item.CPFSEMFORMATO !== null) {
        map.set(item.CPFSEMFORMATO, true);
        result.push(preencher(item));
      }
    }
  });
  return result;
};

export const arrayUniqueAbono = (data: ResponseGet[]): ResponseGet[] => {
  const result: ResponseGet[] = [];
  const map = new Map();
  data.forEach((item) => {
    if (item.ABONO || item.FALTA_ABONADA) {
      if (!map.has(item.CPFSEMFORMATO) && item.CPFSEMFORMATO !== null) {
        map.set(item.CPFSEMFORMATO, true);
        result.push(preencher(item));
      }
    }
  });
  return result;
};
