import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  justify-content: 'center';
  align-items: 'center';
`;

export const Content = styled.main`
  max-width: 1120px;
  margin: 60px auto;
  padding-bottom: 30px;
  border-radius: 7px;
  background-color: #f2f2f2;
`;

export const ContentButton = styled.main`
  max-width: 1120px;
  margin: 60px auto;
`;

export const ContentSubHeader = styled.main`
  width: 100%;
  height: 55px;
  display: flex;
  padding-left: 15px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  background-color: #ccffdd;
  border-bottom: 2px solid #00b33c;
  align-items: center;
`;

export const ContentTitle = styled.b`
  font-family: 'Arial', serif;
  font-size: 20px;
  color: #000000;
`;

export const ContentSubBody = styled.main`
  width: 100%;
  height: 70px;
  display: flex;
  padding-left: 15px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  background-color: #f2f2f2;
  align-items: center;
  flex-direction: row;
`;

export const ContentText = styled.p`
  font-family: 'Arial', serif;
  font-size: 18px;
  color: #000000;
  margin-right: 15px;
  padding-left: 25px;
  border-width: 2px;
  border-color: #cccccc;
`;

export const Line = styled.hr`
  width: 92%;
  margin-top: 40px;
  align-self: center;
  margin: 0px 0px 0px 30px;
  height: 1px;
  background-color: #c2c2c2;
  border: none;
`;

export const ContentSubLabel = styled.label`
  font-family: 'Arial', serif;
  background-color: #00cc44;
  margin-left: 28px;
  margin-top: -20px;
  margin-bottom: 10px;
  color: #000000;
  height: 40px;
  padding: 12px;
  text-align: center;
`;
