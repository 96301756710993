import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  //display: flex;
  position: fixed;
  width: 100%;
  z-index: 1;
`;

export const ContainerLinha = styled.div`
  border-bottom: 1px solid #f1f1f1;
  margin-bottom: 10px;
`;

export const Profile = styled.div`
  //display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;
  margin-top: 0;
  margin-bottom: 0.6rem;
  overflow-x: auto;
  max-width: 280px;
  a {
    text-decoration: none;
    img {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      margin-bottom: 10px;
      border: 2px solid;
      border-color: #00cc00;
    }
  }
  div {
    display: flex;
    flex-direction: column;
    //margin-left: 16px;
    line-height: 20px;
    justify-content: center;
    align-items: center;

    span {
      color: #fff;
      font-size: 16px;
    }

    strong {
      //margin-left: 16px;
      color: ${shade(0.2, '#fff')};
      font-size: 12px;
      //margin-right: 20px;
    }
  }
`;

export const ContainerButtonDrawer = styled.div`
  display: flex;
  height: 55px;
  background-color: #28262e;
  align-items: center;
  a {
    img {
      margin-left: 20px;
      height: 37px;
    }
  }
  Button {
    height: 55px;
    margin-top: 3px;
  }
`;

export const ButtonSignOut = styled.div`
  margin-left: auto;
  margin-right: 60px;
  background-color: #660000;
  border-radius: 15px;
  border: 0px;
  padding: 5px 8px;
  cursor: pointer;
`;
