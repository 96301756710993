import React from 'react';
import HeaderTable from '../../../components/HeaderTable';
import {
  formatarDataAnoMesDia,
  formatarHoraMin,
} from '../../../components/Formulas/formulas';
import { ContainerTable } from '../styles';
import { ResponseGet } from '../Interface/index';

interface DadosTable {
  dados: ResponseGet[];
  dadosCompletos: ResponseGet[];
}

const PontoTodosDetalhado: React.FC<DadosTable> = ({
  dados,
  dadosCompletos,
}) => {
  const headersEscala = [
    { name: 'Dados Pessoais', field: 'dadosPessoais' },
    { name: 'Abono', field: 'abono' },
    { name: 'Tipo Abono', field: 'tipoAbono' },
    { name: 'Motivo', field: 'motivo' },
    { name: 'Data Escala', field: 'dataEscala' },
    { name: 'Latitude Ponto', field: 'latitudePonto' },
    { name: 'Longitude Ponto', field: 'longitudePonto' },
    { name: 'Tipo Ponto', field: 'tipoPonto' },
    { name: 'Data', field: 'data' },
    { name: 'Hora', field: 'hora' },
    { name: 'Foto', field: 'foto' },
    { name: 'Latitude', field: 'latitude' },
    { name: 'Longitude', field: 'longitude' },
    { name: 'Diferença', field: 'diferenca' },
  ];

  const filterUserResponse = (
    data: ResponseGet,
    dadosCompetos: ResponseGet[],
  ): ResponseGet[] => {
    let computedFilter: ResponseGet[] = dadosCompetos;
    computedFilter = computedFilter.filter(
      (res) => res.CPFSEMFORMATO === data.CPFSEMFORMATO,
    );
    return computedFilter;
  };

  return (
    <ContainerTable>
      <table id="PontoTodosExcel">
        <HeaderTable headers={headersEscala} />
        {dados.map((dado: ResponseGet) =>
          filterUserResponse(dado, dadosCompletos).map(
            (res: ResponseGet, index) => (
              <tbody key={`${res.CPFSEMFORMATO + index.toString()}.0`}>
                <tr key={`${res.CPFSEMFORMATO + index.toString()}.1`}>
                  <td>{res.NOME_CLIENTE}</td>
                  <td rowSpan={5} style={{ minWidth: 80 }}>
                    {res.ABONO || res.FALTA_ABONADA ? 'Sim' : 'Não'}
                  </td>
                  {(res.FALTA_ABONADA ||
                    res.DIA_ABONADO ||
                    res.PERIODO_ABONADO) && (
                    <td rowSpan={5} style={{ minWidth: 80 }}>
                      dia
                    </td>
                  )}
                  {(res.FALTA_ABONADA ||
                    res.DIA_ABONADO ||
                    res.PERIODO_ABONADO) && (
                    <td rowSpan={5} style={{ minWidth: 80 }}>
                      {`${res.DIA_ABONADO ? res.DESC_DIA : ''} ${
                        res.PERIODO_ABONADO ? res.DESC_PERIODO : ''
                      }
                      ${res.FALTA_ABONADA ? res.DESC_FALTA : ''}`}
                    </td>
                  )}
                  {(res.ENTRADA_ABONADA ||
                    res.PAUSA_ABONADA ||
                    res.VOLTA_ABONADA ||
                    res.SAIDA_ABONADA) && (
                    <td rowSpan={5} style={{ minWidth: 80 }}>
                      {`${res.ENTRADA_ABONADA ? 'Entrada' : ''} ${
                        res.PAUSA_ABONADA ? 'Pausa' : ''
                      } ${res.VOLTA_ABONADA ? 'Volta' : ''} ${
                        res.SAIDA_ABONADA ? 'Saída' : ''
                      }`}
                    </td>
                  )}
                  {(res.ENTRADA_ABONADA ||
                    res.PAUSA_ABONADA ||
                    res.VOLTA_ABONADA ||
                    res.SAIDA_ABONADA) && (
                    <td rowSpan={5} style={{ minWidth: 80 }}>
                      {`${
                        res.ENTRADA_ABONADA
                          ? `Entrada: ${res.DESC_ENTRADA}`
                          : ''
                      } ${
                        res.PAUSA_ABONADA ? ` Pausa: ${res.DESC_PAUSA}` : ''
                      } ${
                        res.VOLTA_ABONADA ? ` Volta: ${res.DESC_VOLTA}` : ''
                      } ${
                        res.SAIDA_ABONADA ? ` Saída: ${res.DESC_SAIDA}` : ''
                      }`}
                    </td>
                  )}
                  {/* se nao tiver abono imprime a coluna em branco tipo abono e motivo */}
                  {!(
                    res.ENTRADA_ABONADA ||
                    res.PAUSA_ABONADA ||
                    res.VOLTA_ABONADA ||
                    res.SAIDA_ABONADA
                  ) &&
                    !(
                      res.FALTA_ABONADA ||
                      res.DIA_ABONADO ||
                      res.PERIODO_ABONADO
                    ) && (
                      <td rowSpan={5} style={{ minWidth: 80 }}>
                        <p> </p>
                      </td>
                    )}
                  {!(
                    res.ENTRADA_ABONADA ||
                    res.PAUSA_ABONADA ||
                    res.VOLTA_ABONADA ||
                    res.SAIDA_ABONADA
                  ) &&
                    !(
                      res.FALTA_ABONADA ||
                      res.DIA_ABONADO ||
                      res.PERIODO_ABONADO
                    ) && (
                      <td rowSpan={5} style={{ minWidth: 80 }}>
                        <p> </p>
                      </td>
                    )}
                  <td rowSpan={5} style={{ minWidth: 80 }}>
                    {formatarDataAnoMesDia(res.DATA_INICIAL_DIA_AGENDA)}
                  </td>
                  <td rowSpan={5}>{res.LOCAL_LATITUDE}</td>
                  <td rowSpan={5}>{res.LOCAL_LONGITUDE}</td>
                  <td>Entrada</td>
                  <td style={{ minWidth: 80 }}>
                    {res.PONTO_DATA_ENTRADA
                      ? formatarDataAnoMesDia(res.PONTO_DATA_ENTRADA)
                      : '-'}
                  </td>
                  <td>
                    {res.PONTO_HORA_ENTRADA
                      ? formatarHoraMin(res.PONTO_HORA_ENTRADA)
                      : '-'}
                  </td>
                  <td>
                    <a href={res.PONTO_LINK_FOTO_ENTRADA}>
                      {res.PONTO_LINK_FOTO_ENTRADA
                        ? res.PONTO_LINK_FOTO_ENTRADA
                        : 'Sem foto'}
                    </a>
                  </td>
                  <td>
                    {res.PONTO_LATITUDE_ENTRADA
                      ? res.PONTO_LATITUDE_ENTRADA
                      : '-'}
                  </td>
                  <td>
                    {res.PONTO_LONGITUDE_ENTRADA
                      ? res.PONTO_LONGITUDE_ENTRADA
                      : '-'}
                  </td>
                  <td>
                    {res.PONTO_DIFERENCA_METROS_ENTRADA
                      ? `${res.PONTO_DIFERENCA_METROS_ENTRADA} m`
                      : '-'}
                  </td>
                </tr>
                <tr key={`${res.CPFSEMFORMATO + index.toString()}.2`}>
                  <td>{res.NOME}</td>
                  <td>Pausa</td>
                  <td style={{ minWidth: 80 }}>
                    {res.PONTO_DATA_PAUSA
                      ? formatarDataAnoMesDia(res.PONTO_DATA_PAUSA)
                      : '-'}
                  </td>
                  <td>
                    {res.PONTO_HORA_PAUSA
                      ? formatarHoraMin(res.PONTO_HORA_PAUSA)
                      : '-'}
                  </td>
                  <td>
                    <a href={res.PONTO_LINK_FOTO_PAUSA}>
                      {res.PONTO_LINK_FOTO_PAUSA
                        ? res.PONTO_LINK_FOTO_PAUSA
                        : 'Sem foto'}
                    </a>
                  </td>
                  <td>
                    {res.PONTO_LATITUDE_PAUSA ? res.PONTO_LATITUDE_PAUSA : '-'}
                  </td>
                  <td>
                    {res.PONTO_LONGITUDE_PAUSA
                      ? res.PONTO_LONGITUDE_PAUSA
                      : '-'}
                  </td>
                  <td>
                    {res.PONTO_DIFERENCA_METROS_PAUSA
                      ? `${res.PONTO_DIFERENCA_METROS_PAUSA} m`
                      : '-'}
                  </td>
                </tr>
                <tr key={`${res.CPFSEMFORMATO + index.toString()}.3`}>
                  <td>{res.CONTRATO}</td>
                  <td>Volta</td>
                  <td style={{ minWidth: 80 }}>
                    {res.PONTO_DATA_VOLTA
                      ? formatarDataAnoMesDia(res.PONTO_DATA_VOLTA)
                      : '-'}
                  </td>
                  <td>
                    {res.PONTO_HORA_VOLTA
                      ? formatarHoraMin(res.PONTO_HORA_VOLTA)
                      : '-'}
                  </td>
                  <td>
                    <a href={res.PONTO_LINK_FOTO_VOLTA}>
                      {res.PONTO_LINK_FOTO_VOLTA
                        ? res.PONTO_LINK_FOTO_VOLTA
                        : 'Sem foto'}
                    </a>
                  </td>
                  <td>
                    {res.PONTO_LATITUDE_VOLTA ? res.PONTO_LATITUDE_VOLTA : '-'}
                  </td>
                  <td>
                    {res.PONTO_LONGITUDE_VOLTA
                      ? res.PONTO_LONGITUDE_VOLTA
                      : '-'}
                  </td>
                  <td>
                    {res.PONTO_DIFERENCA_METROS_VOLTA
                      ? `${res.PONTO_DIFERENCA_METROS_VOLTA} m`
                      : '-'}
                  </td>
                </tr>
                <tr key={`${res.CPFSEMFORMATO + index.toString()}.4`}>
                  <td>{res.POSTO}</td>
                  <td>Saída</td>
                  <td style={{ minWidth: 80 }}>
                    {res.PONTO_DATA_SAIDA
                      ? formatarDataAnoMesDia(res.PONTO_DATA_SAIDA)
                      : '-'}
                  </td>
                  <td>
                    {res.PONTO_HORA_SAIDA
                      ? formatarHoraMin(res.PONTO_HORA_SAIDA)
                      : '-'}
                  </td>
                  <td>
                    <a href={res.PONTO_LINK_FOTO_SAIDA}>
                      {res.PONTO_LINK_FOTO_SAIDA
                        ? res.PONTO_LINK_FOTO_SAIDA
                        : 'Sem foto'}
                    </a>
                  </td>
                  <td>
                    {res.PONTO_LATITUDE_SAIDA ? res.PONTO_LATITUDE_SAIDA : '-'}
                  </td>
                  <td>
                    {res.PONTO_LONGITUDE_SAIDA
                      ? res.PONTO_LONGITUDE_SAIDA
                      : '-'}
                  </td>
                  <td>
                    {res.PONTO_DIFERENCA_METROS_SAIDA
                      ? `${res.PONTO_DIFERENCA_METROS_SAIDA} m`
                      : '-'}
                  </td>
                </tr>
                <tr key={`${res.CPFSEMFORMATO + index.toString()}.5`}>
                  <td>{res.FUNCAO}</td>
                </tr>
              </tbody>
            ),
          ),
        )}
      </table>
    </ContainerTable>
  );
};

export default PontoTodosDetalhado;
