import styled from 'styled-components';

interface ContainerProps {
  cor: boolean;
}

export const Container = styled.div`
  /**Tem 100% da parte visível da tela que o usuário consegue ver */
  height: auto;
  /**Faz os items de dentro ficar todos um do lado do outro. */
  flex: 1;
  /**Faz os items de dentro ter o mesmo tamanho, no caso, o tamanho de
    100vh */
  align-items: center;
  justify-content: center;
  overflow-x: auto;
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
`;

export const ContainerSupSearch = styled.div`
  display: flex;
  width: 100%;
  margin: 0px auto;
  margin-top: 15px;
  /*padding-left: 5%;*/
  select {
    background-color: #f1f1f1;
    color: #111111;
    border-radius: 0px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    /*margin-left: 20px;*/
    margin-left: 0px !important;
    border: none;
    justify-content: center;
    width: 240px;
    padding: 5px 10px;
  }
  .searchDate {
    margin-left: 15px;
    input {
      background-color: #f1f1f1;
      color: #111111;
      border-top-left-radius: 5px;
      border: none;
      padding: 10px;
      width: 110px;
      height: 40px;
      text-align: center;
      width: 120px;
      margin-left: 10px;
    }
  }
  .searchDateFim {
    input {
      background-color: #f1f1f1;
      color: #111111;
      border-top-right-radius: 5px;
      border: none;
      padding: 10px;
      width: 110px;
      height: 40px;
      text-align: center;
      width: 120px;
      margin-left: 2px;
    }
  }
`;

export const ContainerSearch = styled.div`
  width: 100%;
  display: flex;
  /* margin: 0px auto; */
  margin-top: 2px;
  /*padding-left: 5%;*/

  .searchNome {
    div {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      padding-bottom: 1px;
      margin-left: 0px !important;
    }
    input {
      width: 200px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      margin-right: 25px !important;
    }
  }
  .searchCpf {
    div {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      margin-left: 0px !important;
    }
    input {
      width: 202px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      margin-right: 0px !important;
    }
  }
`;

export const Aba = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  .buttonAba {
    button {
      margin-right: 10px;
    }
  }
`;

export const FilterDiv = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const ContainerAba = styled.div<ContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    height: 40px;
    background-color: ${(props) => (props.cor ? ' #f1f1f1' : '#a6a6a6')};
    color: #111111;
    border-width: 0px;
    padding-left: 15px;

    padding-right: 15px;

    min-width: 70px;
    border: 0px solid #a6a6a6;
    &:hover {
      filter: brightness(1.1);
    }
    svg {
      margin-left: 3px;
    }
  }
  h3 {
    margin-left: 10px;
    color: #ffffff;
    padding: 0px 0px;
    min-width: 150px;
  }
`;

export const SearchContentRight = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  flex: 1;
  max-width: 600px;
  padding-left: 25px;
`;

export const SearchFilter = styled.div`
  display: flex;
  flex-direction: row;

  .searchUp {
    display: flex;
    flex-direction: row;
    flex: 1;
  }
`;

export const ButtonStyle = styled.button<ContainerProps>`
  display: flex;
  width: 100%;
  height: 40px;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.cor ? ' #f1f1f1' : '#a6a6a6')};
  color: #1a1a1a;
  border: 0px solid #a6a6a6;
  &:hover {
    filter: brightness(1.1);
  }
`;

export const ContainerTable = styled.div`
  width: 100%;
  align-items: center;
  margin: 0px auto;
  padding: 0px;
  max-height: 55vh;
  overflow-x: auto;
  table {
    width: 100%;
    padding: 10px 40px;
    margin: 0px auto;
    border-collapse: collapse;
    border: 1px solid #006600;
    border-top: 0px;
    th {
      border-bottom: 1px solid #006600;
      justify-content: space-between;
      /* padding: 5px 5px; */
      background-color: #439133;
      color: #fff;
      /* width: 132px; */
      position: sticky;
      position: -webkit-sticky;
      top: 0;
    }
  }
  tbody {
    td {
      margin: 5px;
      padding: 5px;
      text-align: center;
      font-size: 14px;
      white-space: nowrap;
      button {
        background-color: transparent;
        color: #fff;
        border: 0;
        & + button {
          margin-left: 20px;
        }
      }
      a {
        text-decoration: none;
        size: 25px;
        color: #ffffff;
      }
      textarea {
        border-radius: 5px;
        padding: 3px 10px;
        height: 50px;
        font-size: 14px;
        font-family: Arial;
      }
    }
    tr {
      border-bottom: 1px solid #006600;
      /**funcao para fazer as linhas da tabela uma de cada cor de forma alternada.*/
      &:nth-child(even) {
        background-color: #323232;
      }
      &:hover {
        background-color: #454545;
      }
    }
  }
`;

export const ButtonBorderIcon = styled.div`
  height: 23px;
  width: 23px;
  border-radius: 50%;
  svg {
    margin-top: -1px;
    margin-left: -1px;
  }
`;

export const ContainerExport = styled.div`
  width: 50%;
  display: flex;
  justify-content: start;
  button {
    background-color: #006600;
    margin-left: 20px;
    border: none;
    height: 40px;
    width: 40px;
    padding: 5px;
    border-radius: 5px;
    color: #ffffff;
    line-height: 20px;
    svg {
      height: 30px;
      width: 20px;
    }
  }
`;

export const ButtonBorder = styled.div`
  display: flex;
  width: 50%;
  justify-content: end;
  align-self: end;
  button {
    width: 120px;
    height: 40px;
    margin-left: auto;

    background-color: #006600;
    align-items: center;
    margin-bottom: 10px;
    border-radius: 15px;
    border: 0px;
    color: #ffffff;
    cursor: pointer;
  }
  svg {
    margin-right: 5px;
    color: #ffffff;
    size: 20px;
  }
`;

export const ContainerVisualizarVazio = styled.div`
  display: flex;
  width: 1120px;
  background-color: #3e3946;
  margin: 10px auto;
  border-radius: 10px;
  h3 {
    color: #ffffff;
    margin: 0px auto;
    font-size: 32px;
    font-family: Arial;
    padding: 40px;
  }
`;
