import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

import DrawerApp from '../../components/Drawer';
import ModalConexao from '../../components/ModalConexao';
import { useAuth } from '../../hooks/auth';

import { Container } from './styles';

const setores = [{ valor: 'Todos', id: 15, NivelAcesso: 8 }];

interface ItemTabela {
  page: string;
  url: string;
  setor: number;
  nivel: number;
}

interface NivelAcesso {
  name: string;
  valor: number;
}

const setoresAll: NivelAcesso[] = [
  { name: 'Nenhum', valor: 1 },
  { name: 'Operacional', valor: 2 },
  { name: 'Call Center', valor: 3 },
  { name: 'Diretoria', valor: 4 },
  { name: 'Controladoria', valor: 5 },
  { name: 'Sesmt', valor: 6 },
  { name: 'Administrativo', valor: 7 },
  { name: 'Financeiro', valor: 8 },
  { name: 'Jurídico', valor: 9 },
  { name: 'Compras', valor: 10 },
  { name: 'Estoque', valor: 11 },
  { name: 'Faturamento', valor: 12 },
  { name: 'Recursos Humanos', valor: 13 },
  { name: 'Departamento Pessoal', valor: 14 },
  { name: 'Todos', valor: 15 },
  { name: 'Marketing', valor: 16 },
  { name: 'Ponto Eletônico', valor: 17 },
];

const niveisAcesso: NivelAcesso[] = [
  { name: 'Estagiário', valor: 1 },
  { name: 'Auxiliar', valor: 2 },
  { name: 'Assistente', valor: 3 },
  { name: 'Analista', valor: 4 },
  { name: 'Supervisor', valor: 5 },
  { name: 'Gerente', valor: 6 },
  { name: 'Diretor', valor: 7 },
  { name: 'Presidente', valor: 8 },
];

export const PagesAcesso: React.FC = () => {
  const { userAcesso } = useAuth();
  const history = useHistory();

  const loadingData = useCallback(async () => {
    let semAcesso = true;
    let access;
    userAcesso.forEach((user) => {
      access = setores.filter(
        (s) => s.id === user.SetorID && user.NivelACesso >= s.NivelAcesso,
      );
      if (access.length > 0) {
        semAcesso = false;
      }
    });

    if (semAcesso) {
      history.push('/dashboard');
      Swal.fire({
        icon: 'info',
        title:
          'Seu nível de acesso não tem permissão para acessar essa página!',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
    } else {
      console.log('ETSTSA');
    }
  }, [history, userAcesso]);

  useEffect(() => {
    loadingData();
  }, [loadingData]);

  return (
    <>
      <ModalConexao />
      <DrawerApp />
      <Container />
    </>
  );
};
