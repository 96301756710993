import React, { useState, useCallback, useMemo, useEffect } from 'react';
import {
  FiEdit,
  FiClipboard,
  FiXCircle,
  FiCheckCircle,
  FiToggleLeft,
  FiToggleRight,
} from 'react-icons/fi';
import Swal from 'sweetalert2';
import { Link, useHistory } from 'react-router-dom';
import { registerLocale } from 'react-datepicker';
import { ptBR } from 'date-fns/locale';
import ModalConexao from '../../components/ModalConexao';
import DrawerApp from '../../components/Drawer';
import Search from '../../components/Search';
import HeaderTable from '../../components/HeaderTable';
import Pagination from '../../components/Pagination';
import { Formulario } from './Formulario';
import {
  Container,
  Aba,
  ContainerAba,
  ContainerSearch,
  ContainerPagination,
  ContainerTable,
  ContainerSupSearch,
  SearchContentRight,
  SearchFilter,
  ButtonStyle,
} from './styles';
import DropDown from '../../components/DropDown';
import { Loading } from '../../components/Loading';
import { VagaForm, Vagas } from '../../models/Vaga';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import 'react-datepicker/dist/react-datepicker.css';
import { Filial } from '../../models/Filial';

registerLocale('pt-BR', ptBR);

const dropDown = [
  { valor: '20', id: 20 },
  { valor: '30', id: 30 },
  { valor: '40', id: 40 },
  { valor: '50', id: 50 },
  { valor: 'todos', id: 1 },
];

const headers = [
  { name: 'Função', field: 'funcao' },
  { name: 'Empresa', field: 'empresa' },
  // { name: 'Contato', field: 'contato' },
  // { name: 'Período', field: 'periodo' },
  { name: 'Ativo', field: 'Ativo' },
  { name: 'Candidaturas', field: 'candidatura' },
  { name: 'Opções', field: 'option' },
];

const setores = [
  { valor: 'Trabalhe Conosco', id: 17, NivelAcesso: 1 },
  { valor: 'Todos', id: 15, NivelAcesso: 1 },
];

// const dropDownFilial = [
//   { valor: 'Nenhum', id: 0, codigo: '000000' },
//   { valor: '01 - Fortal Empreendimentos', id: 1, codigo: '010101' },
//   { valor: '02 - Fortal Tercerizações', id: 2, codigo: '020101' },
//   { valor: '03 - Real Serviços', id: 3, codigo: '030101' },
//   { valor: '04 - Ello Serviços', id: 4, codigo: '040101' },
//   { valor: '05 - Missao Servicos', id: 5, codigo: '050101' },
// ];

// const vencidos = 'Data final do atestado é anterior a data de hoje.';
// const naoVencidos =
//   'Data final do atestado é Igual ou Posterior a data de Hoje.';
// const todosAlert = 'Todas as requisicões de atestado. Vencidos ou não.';

const GestaoVagas: React.FC = () => {
  const { userAcesso } = useAuth();
  const history = useHistory();
  const [searchNome, setSearchNome] = useState('');
  const [option, setOption] = useState(true);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editVaga, setEditVaga] = useState<Vagas>({} as Vagas);
  const [vagas, setVagas] = useState<Vagas[]>([]);
  const [filiais, setFiliais] = useState<Filial[]>([]);

  // Filtro
  const [filial, setFilial] = useState('');
  const [ativo, setAtivo] = useState(true);

  // const [maxDate, setMaxDate] = useState('');
  // const [dataInicial, setDataInicial] = useState();
  // const [dataFinal, setDataFinal] = useState();
  // const [dataInicialFilter, setDataInicialFilter] = useState('');
  // const [dataFinalFilter, setDataFinalFilter] = useState('');

  const [currentPage, setCurrentPage] = useState(1);
  const [totalTabela, setTotalTabela] = useState(0);
  const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState<number>(20);

  const handleEdit = useCallback((item: Vagas) => {
    // console.log('Edit');
    setEditVaga(item);
    setOption(false);
    setEdit(true);
  }, []);

  const handleSubmitCreate = useCallback(
    async (data: VagaForm) => {
      setLoading(true);
      // console.log('Cadastro', data);
      await api
        .post('/vagas', data)
        .then(() => {
          setLoading(false);
          // console.log(resp.data);
          Swal.fire({
            icon: 'success',
            title: 'Registro salvo com Sucesso!',
            showConfirmButton: true,
          }).then(() => {
            history.go(0);
          });
        })
        .catch((err) => {
          console.log('Error', err.response);
          setLoading(false);
          Swal.fire({
            icon: 'error',
            title: 'Erro, ao salvar Registro.',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          });
        });
    },
    [history],
  );

  const handleSubmitEdit = useCallback(
    async (data: VagaForm) => {
      setLoading(true);
      // console.log('Editar', data);
      await api
        .put('/vagas', data)
        .then(() => {
          // console.log(resp.data);
          setLoading(false);
          Swal.fire({
            icon: 'success',
            title: 'Registro atualizado com Sucesso!',
            showConfirmButton: true,
          }).then(() => {
            history.go(0);
          });
        })
        .catch((err) => {
          console.log('Error', err.response);
          setLoading(false);
          Swal.fire({
            icon: 'error',
            title: 'Erro, ao atualizar Registro.',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          });
        });
    },
    [history],
  );

  const handleActiveOrDesactive = useCallback(
    async (vaga: Vagas, ativoUpdate: boolean) => {
      setLoading(true);
      const data = {
        id: vaga.id,
        active: ativoUpdate,
      };
      await api
        .put('/vagas', data)
        .then(() => {
          // console.log(resp.data);
          setLoading(false);
          Swal.fire({
            icon: 'success',
            title: 'Registro atualizado com Sucesso!',
            showConfirmButton: true,
          }).then(() => {
            history.go(0);
          });
        })
        .catch((err) => {
          console.log('Error', err.response);
          setLoading(false);
          Swal.fire({
            icon: 'error',
            title: 'Erro, ao atualizar Registro.',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          });
        });
    },
    [history],
  );

  const handleCancelEdit = useCallback(() => {
    setOption(true);
    setEdit(false);
  }, []);

  const FormRender = useCallback((): JSX.Element => {
    if (edit) {
      return (
        <Formulario
          titulo="Editar Vaga"
          submitForm={handleSubmitEdit}
          submitCancel={handleCancelEdit}
          dados={editVaga}
          dataFilial={filiais}
        />
      );
    }
    return (
      <Formulario
        titulo="Cadastrar Vaga"
        submitForm={handleSubmitCreate}
        dataFilial={filiais}
      />
    );
  }, [
    edit,
    editVaga,
    filiais,
    handleCancelEdit,
    handleSubmitCreate,
    handleSubmitEdit,
  ]);

  const empresaCodForName = useCallback(
    (codigo: string): string => {
      const find = filiais.find((item) => item.importId === codigo);
      if (find) {
        return find.name;
      }
      return '';
    },
    [filiais],
  );

  const responseListVaga = useMemo(() => {
    let computedResponses: Vagas[] = [];
    computedResponses = vagas;

    // if (searchNome) {
    //   computedResponses = computedResponses.filter((res: Vagas) =>
    //     res.Funcao.name.toLowerCase().includes(searchNome.toLowerCase()),
    //   );
    // }

    if (filial !== '000000' && filial !== '') {
      computedResponses = computedResponses.filter(
        (res: Vagas) =>
          res.Filial.importId !== null && res.Filial.name.includes(filial),
      );
    }

    computedResponses = computedResponses.filter(
      (res: Vagas) => res.active === ativo,
    );

    // if (dataInicialFilter && dataFinalFilter) {
    //   const dateInit = new Date(dataInicialFilter).getTime();
    //   const dateFim = new Date(dataFinalFilter).getTime();
    //   computedResponses = computedResponses.filter(
    //     (item: Vagas) =>
    //       new Date(item.dateAbertura).getTime() <= dateFim &&
    //       new Date(item.dateFechamento).getTime() >= dateInit,
    //   );
    // }

    setTotalTabela(computedResponses.length);
    if (ITEMS_PER_PAGE === 1) {
      return computedResponses;
    }

    return computedResponses.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE,
    );
  }, [ITEMS_PER_PAGE, ativo, currentPage, filial, vagas]);

  const getFilial = useCallback(() => {
    api
      .get('/filiais/all')
      .then((respFiliais) => {
        const filAll: Filial[] = respFiliais.data;
        setFiliais(filAll);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  const loadingData = useCallback(async () => {
    let semAcesso = true;
    let access;
    userAcesso.forEach((user) => {
      access = setores.filter(
        (s) => s.id === user.SetorID && user.NivelACesso >= s.NivelAcesso,
      );
      if (access.length > 0) {
        semAcesso = false;
      }
    });

    if (semAcesso) {
      history.push('/dashboard');
      Swal.fire({
        icon: 'info',
        title:
          'Seu nível de acesso não tem permissão para acessar essa página!',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
    } else {
      setLoading(true);
      getFilial();
      await api
        .get('/vagas/all')
        .then((response) => {
          // console.log(response.data);
          const respVagas: Vagas[] = response.data;
          setVagas(respVagas);
        })
        .catch((err) => {
          console.log(err);
        });
      setLoading(false);
    }
  }, [getFilial, history, userAcesso]);

  // const formatarDataDiaMesAno = (data: string): string => {
  //   const ano = data.substring(0, 4);
  //   const mes = data.substring(5, 7);
  //   const dia = data.substring(8, 10);
  //   return `${dia}/${mes}/${ano}`;
  // };

  useEffect(() => {
    loadingData();
  }, [loadingData]);

  return (
    <>
      <ModalConexao />
      <DrawerApp />
      <Container style={{ margin: '4%' }}>
        <Aba>
          <ContainerAba className="aba" cor={option}>
            <button
              style={{ borderTopLeftRadius: 7, borderBottomLeftRadius: 7 }}
              type="button"
              onClick={() => {
                setOption(true);
              }}
            >
              Listar Vagas
            </button>
          </ContainerAba>

          <ContainerAba className="aba1" cor={!option}>
            <button
              style={{ borderTopRightRadius: 7, borderBottomRightRadius: 7 }}
              type="button"
              onClick={() => {
                setOption(false);
              }}
            >
              Formulário de Vagas
            </button>
          </ContainerAba>
        </Aba>
        <div
          style={{
            backgroundColor: '#3e3946',
            width: '100%',
            paddingTop: 20,
            borderRadius: 20,
            borderTopLeftRadius: 0,
          }}
        >
          {option ? (
            <div style={{ padding: 20 }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  <ContainerSupSearch>
                    <DropDown
                      onChangeItems={(value: string) => {
                        setFilial(value);
                      }}
                      objetoEnum={filiais.map((item, index) => {
                        return {
                          id: index,
                          valor: item.name,
                        };
                      })}
                    />
                  </ContainerSupSearch>
                  <ContainerSearch>
                    <div className="searchNome">
                      <Search
                        onSearch={(value: string) => {
                          setSearchNome(value);
                        }}
                        nomePlaceHolder="Buscar Funcao"
                      />
                    </div>
                    {/* <div className="searchDate">
                      <DatePicker
                        locale="pt-BR"
                        selected={dataInicial}
                        onChange={(date) => {
                          setDataInicialFilter(
                            formatarData(date?.toLocaleString()),
                          );
                          setDataInicial(date);
                          setMaxDate(formatarMaxData(date?.toLocaleString()));
                        }}
                        selectsStart
                        startDate={dataInicial}
                        endDate={dataFinal}
                        dateFormat="P"
                        placeholderText="Data Inicial"
                      />
                    </div>
                    <div className="searchDateFim">
                      <DatePicker
                        locale="pt-BR"
                        selected={dataFinal}
                        onChange={(date) => {
                          setDataFinalFilter(
                            formatarData(date?.toLocaleString()),
                          );
                          setDataFinal(date);
                        }}
                        selectsEnd
                        startDate={dataInicial}
                        endDate={dataFinal}
                        minDate={dataInicial}
                        maxDate={new Date(maxDate)}
                        dateFormat="P"
                        placeholderText="Data Final"
                      />
                    </div> */}
                  </ContainerSearch>
                </div>
                <SearchContentRight>
                  <div style={{ height: 42 }} />
                  <SearchFilter style={{ marginBottom: '2px' }}>
                    <div className="searchUp" style={{ marginRight: '2px' }}>
                      <ButtonStyle
                        style={{ borderTopLeftRadius: 7 }}
                        cor={ativo}
                        onClick={() => setAtivo(true)}
                      >
                        Ativo
                      </ButtonStyle>
                      <ButtonStyle
                        style={{ borderTopRightRadius: 7 }}
                        cor={!ativo}
                        onClick={() => setAtivo(false)}
                      >
                        Desativo
                      </ButtonStyle>
                    </div>
                  </SearchFilter>
                </SearchContentRight>
              </div>
              <ContainerTable>
                <table>
                  <HeaderTable headers={headers} />
                  <tbody>
                    {responseListVaga.map((res: Vagas) => (
                      <tr key={res.id}>
                        <td>{res.Funcao?.name || ''}</td>
                        <td>{empresaCodForName(res.filialId)}</td>
                        {/* <td>{res.email}</td> */}
                        {/* <td>
                          {formatarDataDiaMesAno(res.dateAbertura)} até{' '}
                          {formatarDataDiaMesAno(res.dateFechamento)}
                        </td> */}
                        <td>
                          {res.active ? (
                            <FiCheckCircle size={20} color="#439133" />
                          ) : (
                            <FiXCircle size={20} color="#cc0000" />
                          )}
                        </td>
                        <td>
                          <Link
                            to={{
                              pathname: `/vagas/${res.id}/candidaturas`,
                              state: { vaga: res },
                            }}
                          >
                            <FiClipboard size={20} color="#ffffff" />
                          </Link>
                        </td>
                        <td>
                          <button type="button" onClick={() => handleEdit(res)}>
                            <FiEdit size={20} />
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              Swal.fire({
                                title: res.active
                                  ? 'Tem certeza que deseja desativar a vaga?'
                                  : 'Tem certeza que deseja ativar a vaga?',
                                icon: 'warning',
                                showCancelButton: true,
                                cancelButtonText: 'Não',
                                confirmButtonColor: '#009900',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Sim',
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  handleActiveOrDesactive(res, !res.active);
                                  // console.log('deu ok');
                                }
                              });
                            }}
                          >
                            {res.active ? (
                              <FiToggleRight size={20} color="#FFFFFF" />
                            ) : (
                              <FiToggleLeft size={20} color="#FFFFFF" />
                            )}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </ContainerTable>
              <ContainerPagination style={{ marginTop: 10 }}>
                <Pagination
                  total={totalTabela}
                  itemsPerPage={ITEMS_PER_PAGE}
                  currentPage={currentPage}
                  onPageChange={(page: number) => setCurrentPage(page)}
                />
                <DropDown
                  onChangeItems={(value: string) => {
                    setCurrentPage(1);
                    if (value === 'todos') {
                      setITEMS_PER_PAGE(1);
                    } else {
                      setITEMS_PER_PAGE(Number(value));
                    }
                  }}
                  objetoEnum={dropDown}
                  minWidth={30}
                />
              </ContainerPagination>
            </div>
          ) : (
            FormRender()
          )}
        </div>
      </Container>
      {loading && (
        <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
          }}
        >
          <Loading />
        </div>
      )}
    </>
  );
};

export default GestaoVagas;
