import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import { FiPrinter } from 'react-icons/fi';
import { Checkbox } from 'antd';
import { uuid } from 'uuidv4';
import HeaderTable from '../../components/HeaderTable';
import { apiSesmt } from '../../services/api';
import { useAuth } from '../../hooks/auth';
import DropDown from '../../components/DropDown';
import Pagination from '../../components/Pagination';
import { Loading } from '../../components/Loading';
import { maskCpf } from '../../components/Input/mask';
import ModalConexao from '../../components/ModalConexao';
import Search from '../../components/Search';
import ModalDeclaracaoVacinacao from '../../components/ModalDeclaracaoVacinacao';
import DrawerApp from '../../components/Drawer';
import { Container, ContainerBusca, ContainerTable } from './styles';

interface ResponseGet {
  CENTRO: string;
  CENTRO_CUSTO_DESC: string;
  CNPJ: string;
  COD_FUNCAO: string;
  CPF: string;
  DESC_FUNCAO: string;
  EMPRESA: string;
  EMPRESA_NOME: string;
  ENDERECO: string;
  MATRICULA: string;
  NOME: string;
  POSTO: string;
  SITUACAO: string;
  checked: boolean;
}

const DeclaracaoVacinacao: React.FC = () => {
  const { userAcesso } = useAuth();
  const history = useHistory();
  const [response, setResponse] = useState<ResponseGet[]>([]);
  const [responseChecked, setResponseChecked] = useState<ResponseGet[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [search, setSearch] = useState('');
  const [searchCPF, setSearchCPF] = useState('');
  const [searchFuncao, setSearchFuncao] = useState('');
  const [searchPosto, setSearchPosto] = useState('');

  const [porcentagem, setPorcentagem] = useState(0);
  const [loading, setLoading] = useState(false);
  const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(20);

  const [declaracao, setDeclaracao] = useState(false);

  const setores = [
    { valor: 'Sesmt', id: 6, NivelAcesso: 1 },
    { valor: 'Todos', id: 15, NivelAcesso: 1 },
  ];

  const dropDown = [
    { valor: '20', id: 20 },
    { valor: '40', id: 40 },
    { valor: '80', id: 80 },
    { valor: '160', id: 160 },
    { valor: 'Todos', id: 1 },
  ];

  const headers = [
    { name: 'Colaborador', field: 'NOME' },
    { name: 'CPF', field: 'CPF' },
    { name: 'Função', field: 'DESC_FUNCAO' },
    { name: 'Posto', field: 'POSTO' },
    { name: 'Empresa', field: 'EMPRESA_NOME' },
    { name: 'CNPJ', field: 'CNPJ' },
    { name: 'Endereço', field: 'ENDERECO' },
    { name: '', field: 'checkbox' },
  ];

  useEffect(() => {
    let semAcesso = true;
    let access;
    userAcesso.forEach((user) => {
      access = setores.filter(
        (s) => s.id === user.SetorID && user.NivelACesso >= s.NivelAcesso,
      );
      if (access.length > 0) {
        semAcesso = false;
      }
    });

    if (semAcesso) {
      history.push('/dashboard');
      Swal.fire({
        icon: 'info',
        title:
          'Seu nível de acesso não tem permissão para acessar essa página!',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
    } else {
      setLoading(true);
      apiSesmt
        .get(`/funcionariosVacinacao`, {
          onDownloadProgress(progress) {
            setPorcentagem((progress.loaded * 100) / progress.total);
          },
        })
        .then((data) => {
          const dadosCopiaTemp = data.data.teste.map((dado: ResponseGet) => {
            return { ...dado, checked: false };
          });
          setResponse(dadosCopiaTemp);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          if (navigator.onLine) {
            Swal.fire({
              icon: 'info',
              title: 'Erro ao Carregar dados! Tente Regarregar a Página.',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            });
          }
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, userAcesso]);

  const onButtonClick = useCallback(
    (dadoParam) => {
      const newResponse = response.map((dado) => {
        return JSON.stringify(dado) === JSON.stringify(dadoParam)
          ? { ...dado, checked: !dado.checked }
          : dado;
      });

      const arrayResponseChecked: ResponseGet[] = [];
      newResponse.forEach((data) => {
        if (data.checked) arrayResponseChecked.push(data);
      });

      setResponse(newResponse);
      setResponseChecked(arrayResponseChecked);
    },
    [response],
  );

  const mostrarModal = useCallback(() => {
    if (!responseChecked.length) {
      Swal.fire({
        icon: 'info',
        title: 'Favor selecionar ao menos um registro.',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
    } else {
      setDeclaracao(true);
    }
  }, [responseChecked]);

  const responseData = useMemo(() => {
    let computedResponse: ResponseGet[] = [];
    computedResponse = response;

    if (search) {
      computedResponse = computedResponse.filter((data) =>
        data.NOME.toLowerCase().includes(search.toLowerCase()),
      );
    }

    if (searchCPF) {
      let CPF: string = searchCPF.replace('.', '');
      CPF = CPF.replace('.', '');
      CPF = CPF.replace('-', '');
      computedResponse = computedResponse.filter((data) =>
        data.CPF.toLowerCase().includes(CPF.toLowerCase()),
      );
    }

    if (searchFuncao) {
      computedResponse = computedResponse.filter((data) =>
        data.DESC_FUNCAO.toLowerCase().includes(searchFuncao.toLowerCase()),
      );
    }

    if (searchPosto) {
      computedResponse = computedResponse.filter((data) =>
        data.POSTO.toLowerCase().includes(searchPosto.toLowerCase()),
      );
    }

    setTotalItems(computedResponse.length);
    if (ITEMS_PER_PAGE === 1) {
      return computedResponse;
    }

    return computedResponse.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE,
    );
  }, [
    response,
    search,
    searchCPF,
    searchFuncao,
    searchPosto,
    ITEMS_PER_PAGE,
    currentPage,
  ]);

  const onButtonClickHeader = useCallback(
    (dataTemp: boolean) => {
      const newResponse: ResponseGet[] = [];
      const arrayResponseChecked: ResponseGet[] = [];

      if (dataTemp) {
        let cont = 0;
        for (let i = 0; i < response.length; i += 1) {
          for (let j = 0; j < responseData.length; j += 1) {
            if (
              JSON.stringify(response[i]) === JSON.stringify(responseData[j])
            ) {
              newResponse.push({ ...response[i], checked: true });
              cont = 1;
            } else if (j === responseData.length - 1 && cont === 0) {
              newResponse.push({ ...response[i] });
            }
          }
          cont = 0;
        }

        newResponse.forEach((data) => {
          if (data.checked) arrayResponseChecked.push(data);
        });
      } else {
        let cont = 0;
        for (let i = 0; i < response.length; i += 1) {
          for (let j = 0; j < responseData.length; j += 1) {
            if (
              JSON.stringify(response[i]) === JSON.stringify(responseData[j])
            ) {
              newResponse.push({ ...response[i], checked: false });
              cont = 1;
            } else if (j === responseData.length - 1 && cont === 0) {
              newResponse.push({ ...response[i] });
            }
          }
          cont = 0;
        }

        newResponse.forEach((data) => {
          if (data.checked) arrayResponseChecked.push(data);
        });
      }

      setResponse(newResponse);
      setResponseChecked(arrayResponseChecked);
    },
    [response, responseData],
  );

  return (
    <>
      {declaracao && (
        <ModalDeclaracaoVacinacao
          dadosGeral={responseChecked}
          onClose={() => setDeclaracao(false)}
        />
      )}
      <ModalConexao />
      <DrawerApp />

      <ContainerBusca>
        <div className="divBusca">
          <div className="divSearch">
            <Search
              onSearch={(value: string) => {
                setSearch(value);
                setCurrentPage(1);
              }}
              nomePlaceHolder="Buscar Nome"
            />
          </div>
          <div className="divSearch">
            <Search
              onSearch={(value: string) => {
                setSearchCPF(value);
                setCurrentPage(1);
              }}
              nomePlaceHolder="Buscar CPF"
            />
          </div>
          <div className="divSearch">
            <Search
              onSearch={(value: string) => {
                setSearchFuncao(value);
                setCurrentPage(1);
              }}
              nomePlaceHolder="Buscar Função"
            />
          </div>
          <div className="divSearch">
            <Search
              onSearch={(value: string) => {
                setSearchPosto(value);
                setCurrentPage(1);
              }}
              nomePlaceHolder="Buscar Posto"
            />
          </div>
        </div>
      </ContainerBusca>

      <ContainerTable>
        <table id="tableDeclaracaoVacinacaoUsuarios">
          <HeaderTable
            headers={headers}
            checkboxList={(data: boolean) => onButtonClickHeader(data)}
          />
          <tbody>
            {responseData.map((data: ResponseGet) => (
              <tr key={uuid()}>
                <td>{data.NOME === null ? '-' : data.NOME}</td>
                <td>{data.CPF === null ? '-' : maskCpf(data.CPF)}</td>
                <td>{data.DESC_FUNCAO === null ? '-' : data.DESC_FUNCAO}</td>
                <td>{data.POSTO === null ? '-' : data.POSTO}</td>
                <td>{data.EMPRESA_NOME === null ? '-' : data.EMPRESA_NOME}</td>
                <td>{data.CNPJ === null ? '-' : data.CNPJ}</td>
                <td>{data.ENDERECO === null ? '-' : data.ENDERECO}</td>
                <td>
                  <Checkbox
                    onChange={() => onButtonClick(data)}
                    checked={data.checked}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </ContainerTable>

      <Container>
        <div className="divFooter">
          <Pagination
            total={totalItems}
            itemsPerPage={ITEMS_PER_PAGE}
            currentPage={currentPage}
            onPageChange={(page: number) => setCurrentPage(page)}
          />
          <DropDown
            onChangeItems={(value: string) => {
              setCurrentPage(1);
              if (value === 'Todos') {
                setITEMS_PER_PAGE(1);
              } else {
                setITEMS_PER_PAGE(Number(value));
              }
            }}
            objetoEnum={dropDown}
            minWidth={30}
          />
          <button type="button" className="btn" onClick={() => mostrarModal()}>
            <FiPrinter size={20} /> Gerar Declaração
          </button>
        </div>

        <div className="divContador">
          <span>{`Quantidade de registros: ${responseData.length}`}</span>
        </div>

        {loading && <Loading porcentagem={porcentagem} />}
      </Container>
    </>
  );
};

export default DeclaracaoVacinacao;
