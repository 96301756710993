import styled from 'styled-components';

export const ContainerModal = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: 'center';
  align-items: 'center';
  z-index: 3;
`;

export const Container = styled.div`
  position: relative;
  width: 70%;
  height: 60%;
  background-color: #ffffff;
  color: #000000;
  border-radius: 5px;
  margin: auto auto;
  p {
    margin-top: 20px;
    font-size: 16px;
    text-align: center;
  }
`;

export const ContainerButton = styled.div`
  width: auto;
  display: flex;
  button {
    border: transparent;
    background-color: transparent;
    padding: 2px;
    margin-left: auto;
  }
`;

export const ContainerHeaderDados = styled.div`
  display: flex;
  margin: 0px auto;
  justify-content: center;
  p {
    margin-top: 10px;
    font-size: 20px;
    word-wrap: break-word;
    padding: 0px 20px 0px 20px;
    text-align: center;
    font-weight: bold;
    font-family: Arial;
  }
`;

export const ContainerDados = styled.div`
  width: 400px;
  height: 100px;
  margin: 10% auto 5% auto;
  justify-content: center;
  align-self: center;
  display: flex;
  select {
    background-color: #4d4d4d;
    color: #f1f1f1;
    height: 40px;
    margin-top: 7px;
    border-radius: 10px;
    border: none;
    justify-content: center;
    padding: 0px 10px;
  }
`;

export const ContainerDadosArquivo = styled.div`
  flex: 1;
  height: 100px;
  flex-direction: column;
  text-align: center;
  p {
    margin-top: 10px;
    margin-left: 20px;
    font-weight: normal;
    font-size: 16px;
    word-wrap: break-word;
    text-align: center;
  }
`;
export const ContainerButtonSalvar = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: -20px;
  left: 70%;
  right: 10%;
  button {
    padding: 10px;
    background-color: #439133;
    color: #ffffff;
    border-radius: 5px;
    border: none;
    min-width: 200px;
  }
`;

export const ContainerButtonCancelar = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: -20px;
  left: 10%;
  right: 70%;
  button {
    padding: 10px;
    background-color: #660000;
    color: #ffffff;
    border-radius: 5px;
    border: none;
    min-width: 200px;
  }
`;
