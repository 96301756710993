import React, { useState, useEffect, useMemo } from 'react';
import Swal from 'sweetalert2';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import api from '../../services/api';
import ModalConexao from '../../components/ModalConexao';
import { Loading } from '../../components/Loading';
import DrawerApp from '../../components/Drawer';
import { Container, ContainerTable } from './styles';
import HeaderTable from '../../components/HeaderTable';
import { Filial } from '../../models/Filial';
import { ILog } from '../../models/Logs';
import NewImportExel from '../../components/NewImportExel';

const ErrorLogs: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataInicial, setDataInicial] = useState(new Date());
  const [dataFinal, setDataFinal] = useState(new Date());
  const [logs, setLogs] = useState<ILog[]>([]);
  const [search, setSearch] = useState('');
  const [searchDate, setSearchDate] = useState('');
  const [searchCod, setSearchCod] = useState('');
  const [filiais, setFiliais] = useState<Filial[]>([]);

  const getEmpresas = (id: string): string => {
    const find = filiais.find((val) => val.importId === id);

    if (find) {
      return find.name;
    }
    return '-';
  };

  const logsFilter = useMemo(() => {
    let filter = logs;

    if (search !== '') {
      filter = filter.filter((val) =>
        val.nome.toLocaleLowerCase().includes(search.toLocaleLowerCase()),
      );
    }
    if (searchDate !== '') {
      filter = filter.filter((val) =>
        moment(val.createdAt).format('DD/MM/YYYY').includes(searchDate),
      );
    }
    if (searchCod !== '') {
      filter = filter.filter((val) => val.companyId === searchCod);
    }
    return filter;
  }, [search, searchDate, logs, searchCod]);

  const headers = [
    { name: 'Empresa', field: 'value' },
    { name: 'Nome', field: 'name' },
    { name: 'Descricao', field: 'description' },
    { name: 'Data', field: 'data' },
  ];

  function retornarEndereco(endereco: string): string[] {
    return endereco.split(',');
  }

  function moedaReal(moeda: number): string {
    return moeda.toLocaleString('pt-br', { minimumFractionDigits: 2 });
  }

  const handleSubmit = () => {
    setLoading(true);

    api
      .get(`logs/all`)
      .then(async (Response) => {
        setLogs(Response.data);
        setLoading(false);

        const apFilial = await api.get('filiais/all');
        setFiliais(apFilial.data);
      })
      .catch(() => {
        setLogs([]);
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Logs não encontrados.',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        });
      });
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  return (
    <Container>
      <ModalConexao />
      <DrawerApp />

      <select
        value={searchCod}
        onChange={(event) => {
          setSearchCod(event.currentTarget.value);
        }}
      >
        <option value="">Todos</option>
        {filiais.map((item) => {
          return (
            <option key={item.importId} value={item.importId}>
              {item.importId}-{item.name}
            </option>
          );
        })}
      </select>

      <input
        placeholder="Nome"
        onChange={(event) => {
          setSearch(event.currentTarget.value);
        }}
      />

      <input
        placeholder="DD/MM/YYYY"
        onChange={(event) => {
          const data = event.currentTarget.value;
          setSearchDate(data);
        }}
      />

      <ContainerTable>
        <table id="tableListagemLogs">
          <HeaderTable headers={headers} />
          <tbody>
            {logsFilter.map((item) => {
              return (
                <tr key={item.id}>
                  <td>{getEmpresas(item.companyId)}</td>
                  <td>{item.nome}</td>
                  <td>{item.error}</td>
                  <td>
                    {moment(item.createdAt).format('DD/MM/YYYY • HH:mm:ss')}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </ContainerTable>

      <NewImportExel tableId="tableListagemLogs" fileName="Listagem_Logs" />

      {loading && <Loading />}
    </Container>
  );
};

export default ErrorLogs;
