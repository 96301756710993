import React, { useState, useRef } from 'react';
import { FiSearch } from 'react-icons/fi';
import { maskCpf } from '../Input/mask';

import { Container, ContainerLupa } from './styles';

interface SearchInterface {
  className?: string;
  nomePlaceHolder: string;
  onSearch: (arg: string) => void;
}

const Search: React.FC<SearchInterface> = ({
  onSearch,
  className,
  nomePlaceHolder,
}: SearchInterface) => {
  const [search, setSearch] = useState('');

  const InputRef = useRef<HTMLInputElement>(null);

  const onInputChange = (value: string): void => {
    setSearch(value);
    onSearch(value);
  };

  return (
    <Container>
      <ContainerLupa
        classNameProps={className || 'search1'}
        onClick={() => InputRef.current?.focus()}
      >
        <FiSearch color="#000000" size={22} />
      </ContainerLupa>
      <input
        ref={InputRef}
        type="text"
        className={className}
        placeholder={nomePlaceHolder}
        value={search}
        onChange={(e) => {
          nomePlaceHolder === 'Buscar CPF'
            ? onInputChange((e.target.value = maskCpf(e.target.value)))
            : onInputChange(e.target.value);
        }}
      />
    </Container>
  );
};

export default Search;
