/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-param-reassign */
import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from 'react';
import Swal from 'sweetalert2';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { ptBR } from 'date-fns/locale';
import DatePicker, { registerLocale } from 'react-datepicker';

import { RiFileExcel2Line } from 'react-icons/ri';
import { BsArrowLeft, BsArrowReturnRight, BsArrowRight } from 'react-icons/bs';

import { useHistory } from 'react-router-dom';
import { IoPersonCircle, IoPersonOutline } from 'react-icons/io5';
import { FiFileText } from 'react-icons/fi';
import { FaRegClock } from 'react-icons/fa';
import { AiFillCaretDown, AiFillCaretLeft } from 'react-icons/ai';
import { format } from 'date-fns-tz';

import moment from 'moment';
import ModalConexao from '../../components/ModalConexao';
import { Loading } from '../../components/Loading';
import Search from '../../components/Search';
import HeaderPonto from './header';
import DropDown from '../../components/DropDown';
import Pagination from '../../components/Pagination';
import Tooltip from '../../components/Tooltip/index';
import {
  formatarData,
  formatarDataAnoMesDia,
  formatarMaxData,
} from '../../components/Formulas/formulas';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import 'react-datepicker/dist/react-datepicker.css';
import DrawerApp from '../../components/Drawer';
import ModalInformacao from '../../components/ModalInformacao/index';
import {
  Container,
  ContainerSearchTodos,
  ContainerPesquisaGeral,
  ContainerSupFilter,
  ContainerInfFilter,
  ContainerFiltro,
  ContainerTable,
  ContainerPagination,
  TdIcons,
  DivButtonExcel,
  TabList,
  TabButton,
  Aba,
  AbaDeButtons,
  ContainerTableEscala,
  DivAbaDeButtons,
  ContainerTableEventos,
} from './styles';
import { Filial } from '../../models/Filial';
import { ResponseGet, ResponseGetSimples } from '../VFiltroPonto/Interface';
import { maskCpf } from '../../components/Input/mask';
import TablePonto from '../VFiltroPonto/TablesExportExcel/pontoEspecifico';
import HeaderTable from '../../components/HeaderTable';
import ModalData from '../VFiltroPonto/ModalData/indexModal';
import ProgressBar from '../../components/ProgressBar';

registerLocale('pt-BR', ptBR);

const setores = [
  { valor: 'Todos', id: 15, NivelAcesso: 1 },
  { valor: 'OAK', id: 17, NivelAcesso: 4 },
];

interface DataEventos extends ResponseGet {
  type: string;
  date: string;
}
export const differenceTime = (dado: string, dado2: string): number => {
  if (dado && dado2) {
    const diff = moment(new Date(dado2), 'DD/MM/YYYY HH:mm:ss').diff(
      moment(new Date(dado), 'DD/MM/YYYY HH:mm:ss'),
    );
    return diff;
  }
  return 0;
};
export const horasATrabalharEmMS = (dado: string): number => {
  return (
    parseInt(dado ? dado.split(':')[0] : '0', 10) * 3600000 +
    parseInt(dado ? dado.split(':')[1] : '0', 10) * 60000 +
    parseInt(dado ? dado.split(':')[2] : '0', 10) * 1000
  );
};

export function tempoMsParaHorasEMin(ms: number): string {
  const hours = Math.floor(ms / (60 * 60 * 1000));
  const minutes = Math.floor((ms % (60 * 60 * 1000)) / (60 * 1000));

  const paddedHours = String(hours).padStart(2, '0');
  const paddedMinutes = String(minutes).padStart(2, '0');

  return `${paddedHours}:${paddedMinutes}`;
}
const ViewPonto: React.FC = () => {
  const { user, userAcesso } = useAuth();
  const history = useHistory();

  const [response, setResponse] = useState<ResponseGet[]>([]);
  const [responseEventos, setResponseEventos] = useState<DataEventos[]>([]);
  const [responseEventosBackup, setResponseEventosBackup] = useState<
    DataEventos[]
  >([]);
  const [responseSimple, setResponseSimple] = useState<ResponseGetSimples[]>(
    [],
  );
  const [responseSimpleBackup, setResponseSimpleBackup] = useState<
    ResponseGetSimples[]
  >([]);
  const [responseUser, setResponseUser] = useState<ResponseGet[]>([]);
  const [dropDownPostos, setDropDownPostos] = useState<
    { id: number; valor: string }[]
  >([]);

  const [intervalBool, setIntervalBool] = useState(false);

  const [controleTab, setControleTab] = useState(true);
  const [controleGeralTab, setControleGeralTab] = useState(true);
  const [controleFaltososTab, setControleFaltososTab] = useState(false);
  const [statusTab, setStatusTab] = useState(false);
  const [eventosTab, setEventosTab] = useState(false);
  const [frequenciaTab, setFrequenciaTab] = useState(false);
  const [filial, setFilial] = useState('');
  const [maxDate, setMaxDate] = useState('');
  const [dataInicial, setDataInicial] = useState<Date | null>(new Date());
  const [dataFinal, setDataFinal] = useState<Date | null>(new Date());
  const [verModalInformacao, setVerModalInformacao] = useState(false);
  const [searchContratoSimple, setSearchContratoSimple] = useState('');
  const [searchNomeSimple, setSearchNomeSimple] = useState('');
  const [searchCPFSimple, setSearchCPFSimple] = useState('');
  const [porcentagem, setPorcentagem] = useState(0);
  const [loading, setLoading] = useState(false);
  const [verPonto, setVerPonto] = useState(false);
  const [verPerfil, setVerPerfil] = useState(false);

  const [lastCodigo, setLastCodigo] = useState('');
  const [lastDataInicial, setLastDataInicial] = useState('');
  const [lastDataFinal, setLastDataFinal] = useState('');
  const [lastFiltrarStatus, setLastFiltrarStatus] = useState('Todos');
  const [lastFiltrarStatusControle, setLastFiltrarStatusControle] = useState(
    'Todos',
  );
  const [lastFiltrarPosto, setLastFiltrarPosto] = useState('Posto');
  const [lastFiltrarTipo, setLastFiltrarTipo] = useState('Todos os tipos');

  const [visualizarModalPdf, setVisualizarModalPdf] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalTabela, setTotalTabela] = useState(0);
  const [totalTabelaEventos, setTotalTabelaEventos] = useState(0);
  const [totalTabelaFrequencia, setTotalTabelaFrequencia] = useState(0);
  const [totalItemsGeral, setTotalItemsGeral] = useState(0);
  const [totalItemsGeralEventos, setTotalItemsGeralEventos] = useState(0);
  const [totalItemsGeralFrequencia, setTotalItemsGeralFrequencia] = useState(0);
  const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState<number>(20);
  const [filiais, setFiliais] = useState<Filial[]>([]);

  const [setaExportar, setSetaExportar] = useState('AiFillCaretLeft');

  const [copiaResponseUser, setCopiaResponseUser] = useState<ResponseGet[]>([]);

  const [diaAtual, setDiaAtual] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [horaAtual, setHoraAtual] = useState(format(new Date(), 'HH:mm'));

  const headersEscala = [
    { name: 'Data Escala', field: 'dataEscala' },
    { name: 'Latitude Ponto', field: 'latitudePonto' },
    { name: 'Longitude Ponto', field: 'longitudePonto' },
    { name: 'Tipo Ponto', field: 'tipoPonto' },
    { name: 'Data', field: 'data' },
    { name: 'Hora', field: 'hora' },
    { name: 'Foto', field: 'foto' },
    { name: 'Latitude', field: 'latitude' },
    { name: 'Longitude', field: 'longitude' },
    { name: 'Diferença', field: 'diferenca' },
  ];

  const headersPontualidade = [
    { name: 'Data Escala', field: 'dataEscala' },
    { name: 'Horas normais', field: 'horasNormais' },
    { name: 'Horas faltantes', field: 'horasFaltantes' },
    { name: 'Horas extras', field: 'horasExtras' },
  ];

  const headersEventos = [
    { name: 'Data', field: 'date' },
    { name: 'Nome', field: 'nome' },
    { name: 'Tipo', field: 'type' },
    { name: 'CPF', field: 'cpf' },
    { name: 'Posto', field: 'posto' },
    { name: '', field: '' },
  ];

  const dropDownFiltro = [
    { valor: 'Todos', id: 0 },
    { valor: 'Em jornada', id: 1 },
    { valor: 'Intervalo', id: 2 },
    { valor: 'Expediente encerrado', id: 3 },
    { valor: 'Férias', id: 4 },
    { valor: 'Atrasado', id: 5 },
    { valor: 'Afastado', id: 6 },
    { valor: 'Se adiantou', id: 7 },
    { valor: 'Se atrasou', id: 8 },
  ];

  const dropDownFiltroControle = [
    { valor: 'Todos', id: 0 },
    { valor: 'Atrasado', id: 1 },
    { valor: 'Se atrasou', id: 2 },
    { valor: 'Em jornada', id: 3 },
    { valor: 'Se adiantou', id: 4 },
    { valor: 'Intervalo', id: 5 },
    { valor: 'Expediente encerrado', id: 6 },
    { valor: 'Afastado', id: 7 },
  ];

  const dropDownTipoRegistro = [
    { valor: 'Todos os tipos', id: 0 },
    { valor: 'Entrada', id: 1 },
    { valor: 'Pausa', id: 2 },
    { valor: 'Volta', id: 3 },
    { valor: 'Saída', id: 4 },
  ];

  const dropDown = [
    { valor: '20', id: 20 },
    { valor: '30', id: 30 },
    { valor: '40', id: 40 },
    { valor: '50', id: 50 },
    { valor: 'todos', id: 1 },
  ];

  const headers = [
    { name: 'Nome', field: 'nome', id: 0 },
    { name: 'Cliente', field: 'cliente', id: 0 },
    { name: 'CPF', field: 'cpf', id: 0 },
    { name: 'Contrato', field: 'contrato', id: 0 },
    { name: 'Posto', field: 'posto', id: 0 },
    // { name: 'Data', field: 'posto', id: 0 },
    { name: '', field: '', id: 0 },
  ];
  const headersControle = [
    { name: 'Nome', field: 'nome', id: 0 },
    { name: 'Cliente', field: 'cliente', id: 0 },
    { name: 'CPF', field: 'cpf', id: 0 },
    { name: 'Posto', field: 'posto', id: 0 },
    { name: 'Hora agendada', field: 'hr_agend', id: 0 },
    { name: 'Hora do ponto', field: 'hr_ponto', id: 0 },
    { name: '', field: '', id: 0 },
  ];
  const headersControleFaltosos = [
    { name: 'Nome', field: 'nome', id: 0 },
    { name: 'Cliente', field: 'cliente', id: 0 },
    { name: 'CPF', field: 'cpf', id: 0 },
    { name: 'Posto', field: 'posto', id: 0 },
    { name: 'Horas a trabalhar', field: 'hr_a_trab', id: 0 },
    { name: 'Horas trabalhadas', field: 'hr_trab', id: 0 },
    { name: '', field: '', id: 0 },
  ];
  const headersFrequencia = [
    { name: 'Nome', field: 'nome', id: 0 },
    { name: 'Data', field: 'posto', id: 0 },
    { name: 'Cliente', field: 'cliente', id: 0 },
    { name: 'CPF', field: 'cpf', id: 0 },
    { name: 'Contrato', field: 'contrato', id: 0 },
    { name: 'Posto', field: 'posto', id: 0 },
    { name: '', field: '', id: 0 },
  ];

  interface Acesso {
    CPF: number;
    EMAIL: string;
    ID: number;
    NivelACesso: number;
    Nome: string;
    Nome_NivelAcesso: string;
    Setor: string;
    SetorID: number;
    idUsuario: number;
  }

  interface Setor {
    valor: string;
    id: number;
    NivelAcesso: number;
  }

  function uniqueBy<T>(objects: T[], uniqueKey: keyof T): T[] {
    const ids = objects.map((object) => object[uniqueKey]);
    return objects.filter(
      (object, index) => !ids.includes(object[uniqueKey], index + 1),
    );
  }

  const transfomaNomeToCodigo = useCallback(
    (value: string): string => {
      const data = filiais.filter((r) => `${r.importId} - ${r.name}` === value);
      if (data.length > 0) {
        return data[0].importId;
      }
      return '';
    },
    [filiais],
  );

  const filterUserResponse = useCallback(
    (data: ResponseGetSimples): ResponseGet[] => {
      let computedFilter: ResponseGet[] = response;
      computedFilter = computedFilter.filter(
        (res) => res.CPFSEMFORMATO === data.CPFSEMFORMATO,
      );
      setCopiaResponseUser(JSON.parse(JSON.stringify(computedFilter)));
      return computedFilter;
    },
    [response],
  );

  const useInterval = (callback: Function, delay: number): void => {
    useEffect(() => {
      const interval = setInterval(() => {
        callback();
      }, delay);
      return () => clearInterval(interval);
    }, [callback, delay]);
  };

  useEffect(() => {
    let semAcesso = true;
    let access;

    userAcesso.forEach((u: Acesso): void | Setor => {
      access = setores.filter((setor) => {
        if (setor.id === u.SetorID && u.NivelACesso >= setor.NivelAcesso) {
          // if (setor.id === 17) {
          //   // setAcessoOak(true);
          // }
          return setor;
        }
        return null;
      });

      if (access.length > 0) {
        semAcesso = false;
      }
    });

    if (semAcesso) {
      history.push('/dashboard');
      Swal.fire({
        icon: 'info',
        text: 'Seu nível de acesso não tem permissão para acessar essa página!',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
    } else {
      setLoading(true);
      api
        .get('filiais/all')
        .then((resp) => {
          setLoading(false);
          setFiliais(resp.data);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [history, userAcesso]);

  const filtrarStatus = (
    value: string,
    respBackup?: ResponseGetSimples[],
  ): void => {
    if (value === 'Férias') {
      const respTemp = (respBackup ?? responseSimpleBackup).filter(
        (res) =>
          response.find(
            (a) =>
              a.CPFSEMFORMATO === res.CPFSEMFORMATO &&
              a.DATA_INICIAL_DIA_AGENDA === res.DATA_INICIAL_DIA_AGENDA,
          )?.FERIAS,
      );
      setResponseSimple(respTemp);
    } else if (value === 'Se atrasou' || value === 'Se adiantou') {
      const respTemp = (respBackup ?? responseSimpleBackup).filter(
        (res) =>
          response
            .filter((a) => !a.AFASTAMENTO && a.PONTO_HORA_ENTRADA)
            .find(
              (a) =>
                a.CPFSEMFORMATO === res.CPFSEMFORMATO &&
                a.DATA_INICIAL_DIA_AGENDA === res.DATA_INICIAL_DIA_AGENDA,
            )?.STATUS_BATIDA === value,
      );
      setResponseSimple(respTemp);
    } else if (value !== 'Todos') {
      const respTemp = (respBackup ?? responseSimpleBackup).filter(
        (res) =>
          response.find(
            (a) =>
              a.CPFSEMFORMATO === res.CPFSEMFORMATO &&
              a.DATA_INICIAL_DIA_AGENDA === res.DATA_INICIAL_DIA_AGENDA,
          )?.STATUS === value,
      );
      setResponseSimple(respTemp);
    } else {
      setResponseSimple(respBackup ?? responseSimpleBackup);
    }
    setLastFiltrarStatusControle(value);
    setLastFiltrarStatus(value);
  };
  const filtrarStatusControle = (
    value: string,
    respBackup?: ResponseGetSimples[],
  ): void => {
    if (value === 'Se atrasou' || value === 'Se adiantou') {
      const respTemp = (respBackup ?? responseSimpleBackup).filter(
        (res) =>
          response
            .filter((a) => !a.AFASTAMENTO && a.PONTO_HORA_ENTRADA)
            .find(
              (a) =>
                a.CPFSEMFORMATO === res.CPFSEMFORMATO &&
                a.DATA_INICIAL_DIA_AGENDA === res.DATA_INICIAL_DIA_AGENDA,
            )?.STATUS_BATIDA === value,
      );
      setResponseSimple(respTemp);
    } else if (value !== 'Todos') {
      const respTemp = (respBackup ?? responseSimpleBackup).filter(
        (res) =>
          response.find(
            (a) =>
              a.CPFSEMFORMATO === res.CPFSEMFORMATO &&
              a.DATA_INICIAL_DIA_AGENDA === res.DATA_INICIAL_DIA_AGENDA,
          )?.STATUS === value,
      );
      setResponseSimple(respTemp);
    } else {
      setResponseSimple(respBackup ?? responseSimpleBackup);
    }
    setLastFiltrarStatusControle(value);
  };

  const filtrarPostos = (
    value: string,
    tab: 'eventos' | 'status' | 'frequencia',
    respBackup?: ResponseGetSimples[],
    eventBackup?: DataEventos[],
  ): void => {
    if (tab === 'status' || tab === 'frequencia') {
      if (value === 'Posto')
        setResponseSimple(respBackup ?? responseSimpleBackup);
      else
        setResponseSimple(
          (respBackup ?? responseSimpleBackup).filter((a) => a.POSTO === value),
        );
    } else if (tab === 'eventos') {
      if (value === 'Posto')
        setResponseEventos(eventBackup ?? responseEventosBackup);
      else
        setResponseEventos(
          (eventBackup ?? responseEventosBackup).filter(
            (a) => a.POSTO === value,
          ),
        );
    }
    setLastFiltrarPosto(value);
  };

  const filtrarTipoRegistro = (
    value: string,
    eventBackup?: DataEventos[],
  ): void => {
    if (value === 'Todos os tipos') setResponseEventos(responseEventosBackup);
    else
      setResponseEventos(
        (eventBackup ?? responseEventosBackup).filter((a) => a.type === value),
      );
    setLastFiltrarTipo(value);
  };

  const toHourAndMinute = (dado: number): string => {
    return `${Math.floor(dado / 3600000)}h${Math.floor(
      (dado / 60000) % 60,
    )}min`;
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const getData = (
    codigo: string,
    dataInicialGet: string,
    dataFinalGet: string,
  ) =>
    api
      .get(
        `/pontoEletronico/getEscalaWeb/${codigo}/${dataInicialGet}/${dataFinalGet}`,
        {
          onDownloadProgress(progress) {
            setPorcentagem((progress.loaded * 100) / progress.total);
          },
        },
      )
      .then(async (data) => {
        const tempData = data.data.map((dado: ResponseGet) => {
          const diferencaPontoAtual = Math.floor(
            differenceTime(
              `${dado?.DATA_INICIAL_DIA_AGENDA}T${dado?.HORA_INICIAL}`,
              `${diaAtual}T${horaAtual}`,
            ) / 60000,
          );
          const diferencaPontoBatida = Math.floor(
            differenceTime(
              `${dado?.DATA_INICIAL_DIA_AGENDA}T${dado?.HORA_INICIAL}`,
              `${dado?.DATA_INICIAL_DIA_AGENDA}T${dado?.PONTO_HORA_ENTRADA}`,
            ) / 60000,
          );

          let status: ResponseGet['STATUS'] = 'Atrasado';
          let statusBatida: ResponseGet['STATUS_BATIDA'] = 'Se adiantou';

          if (
            dado.PONTO_HORA_ENTRADA &&
            dado.PONTO_HORA_PAUSA &&
            !dado.PONTO_HORA_VOLTA
          )
            status = 'Intervalo';
          if (dado.PONTO_HORA_SAIDA) status = 'Expediente encerrado';
          else status = 'Em jornada';

          if (!dado.PONTO_HORA_ENTRADA) status = 'Atrasado';
          if (dado.AFASTAMENTO) status = 'Afastado';

          const diferencaTempoEmMs =
            horasATrabalharEmMS(dado.HORAS_A_TRABALHAR) -
            ((dado.PONTO_HORA_ENTRADA && dado.PONTO_HORA_PAUSA
              ? differenceTime(
                  `${dado.PONTO_DATA_ENTRADA}T${dado.PONTO_HORA_ENTRADA}`,
                  `${dado.PONTO_DATA_PAUSA}T${dado.PONTO_HORA_PAUSA}`,
                )
              : 0) +
              (dado.PONTO_HORA_VOLTA && dado.PONTO_HORA_SAIDA
                ? differenceTime(
                    `${dado.PONTO_DATA_VOLTA}T${dado.PONTO_HORA_VOLTA}`,
                    `${dado.PONTO_DATA_SAIDA}T${dado.PONTO_HORA_SAIDA}`,
                  )
                : 0));

          if (diferencaPontoBatida > 0) statusBatida = 'Se atrasou';
          return {
            ...dado,
            DIFERENCA_PONTO_MARCADO_E_ATUAL: diferencaPontoAtual,
            DIFERENCA_PONTO_MARCADO_E_BATIDO: diferencaPontoBatida,
            STATUS: status,
            STATUS_BATIDA: statusBatida,
            HORAS_DENTRO: diferencaTempoEmMs <= 0 ? diferencaTempoEmMs * -1 : 0,
            HORAS_FALTANTES: diferencaTempoEmMs > 0 ? diferencaTempoEmMs : 0,
          };
        });
        setResponse(tempData);
        // console.log(tempData.filter((a: any) => a.CPF === '058.542.873-58'));
        setCurrentPage(1);
        const list = tempData.filter(
          (a: ResponseGet) =>
            a.PONTO_HORA_ENTRADA !== null ||
            a.PONTO_HORA_PAUSA !== null ||
            a.PONTO_HORA_SAIDA !== null ||
            a.PONTO_HORA_VOLTA !== null,
        ) as any[];

        if (tempData.length > 0) {
          const hoursTypes = [
            { field: 'PONTO_HORA_ENTRADA', type: 'Entrada' },
            { field: 'PONTO_HORA_PAUSA', type: 'Pausa' },
            { field: 'PONTO_HORA_VOLTA', type: 'Volta' },
            { field: 'PONTO_HORA_SAIDA', type: 'Saída' },
          ];

          const newList: any[] = [];

          hoursTypes.forEach((hoursType) => {
            list.forEach((point) => {
              if (point[hoursType.field]) {
                newList.push({
                  type: hoursType.type,
                  date: `${point.DATA_INICIAL_DIA_AGENDA}T${
                    point[hoursType.field]
                  }`,
                  ...point,
                });
              }
            });
          });

          const sortedList = newList.sort(
            (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
          );
          setResponseEventos(sortedList);
          setResponseEventosBackup(sortedList);
          if (intervalBool) {
            if (eventosTab) filtrarTipoRegistro(lastFiltrarTipo);
            else if (eventosTab)
              filtrarPostos(lastFiltrarPosto, 'eventos', undefined, sortedList);
          }
        }

        await api
          .get(
            `/pontoEletronico/getEscalaWebPorFuncionario/${codigo}/${dataInicialGet}/${dataFinalGet}`,
          )
          .then((dados) => {
            const dataSimples: ResponseGetSimples[] = dados.data;
            const dataComplete: ResponseGet[] = data.data;
            const responseTotal: ResponseGetSimples[] = [];
            dataSimples.forEach((itemSimp) => {
              const find = dataComplete.filter(
                (itemComplete) =>
                  itemSimp.CPFSEMFORMATO === itemComplete.CPFSEMFORMATO,
              );
              find.forEach((i) => {
                const horasATrabalhar = find
                  .map((a) => horasATrabalharEmMS(a.HORAS_A_TRABALHAR))
                  .reduce((a, b) => a + b, 0);
                const horasTrabalhadas = find
                  .map(
                    (dado) =>
                      (dado.PONTO_HORA_ENTRADA && dado.PONTO_HORA_PAUSA
                        ? differenceTime(
                            `${dado.PONTO_DATA_ENTRADA}T${dado.PONTO_HORA_ENTRADA}`,
                            `${dado.PONTO_DATA_PAUSA}T${dado.PONTO_HORA_PAUSA}`,
                          )
                        : 0) +
                      (dado.PONTO_HORA_VOLTA && dado.PONTO_HORA_SAIDA
                        ? differenceTime(
                            `${dado.PONTO_DATA_VOLTA}T${dado.PONTO_HORA_VOLTA}`,
                            `${dado.PONTO_DATA_SAIDA}T${dado.PONTO_HORA_SAIDA}`,
                          )
                        : 0) +
                      (!dado.PONTO_HORA_VOLTA &&
                      !dado.PONTO_HORA_PAUSA &&
                      dado.PONTO_HORA_ENTRADA &&
                      dado.PONTO_HORA_SAIDA
                        ? differenceTime(
                            `${dado.PONTO_DATA_ENTRADA}T${dado.PONTO_HORA_ENTRADA}`,
                            `${dado.PONTO_DATA_SAIDA}T${dado.PONTO_HORA_SAIDA}`,
                          )
                        : 0),
                  )
                  .reduce((a, b) => a + b, 0);
                responseTotal.push({
                  ABONO: i.ABONO,
                  AFASTAMENTO: i.AFASTAMENTO,
                  ATRASO: i.ATRASO,
                  COM_PONTO: !i.SEM_PONTO,
                  CONTRATO: i.CONTRATO,
                  CPFSEMFORMATO: i.CPFSEMFORMATO,
                  DIFERENCA_HORAS_TRABALHAR_TRABALHADA_MS:
                    horasATrabalhar - horasTrabalhadas,
                  DATA_INICIAL_DIA_AGENDA: i.DATA_INICIAL_DIA_AGENDA,
                  EXTRA: i.EXTRA,
                  FERIAS: i.FERIAS,
                  HORA_DIVERGENTE: i.HORA_DIVERGENTE,
                  HORAS_A_TRABALHAR_MS: horasATrabalhar,
                  HORAS_TRABALHADAS_MS: horasTrabalhadas,
                  NOME: i.NOME,
                  NOME_CLIENTE: i.NOME_CLIENTE,
                  PONTO_DIVERGENTE: i.PONTO_DIVERGENTE,
                  POSTO: i.POSTO,
                  SEM_PONTO: i.SEM_PONTO,
                });
              });
            });
            const filter = [
              ...new Map(
                responseTotal.map((item) => [
                  JSON.stringify(item), // `${item.CPFSEMFORMATO}-${item.DATA_INICIAL_DIA_AGENDA}`,
                  item,
                ]),
              ).values(),
            ];
            // const finalResponse = filter.map(a=> {
            //   dataComplete.filter(
            //     (itemComplete) =>
            //       itemSimp.CPFSEMFORMATO === itemComplete.CPFSEMFORMATO,
            //   )
            // })
            setResponseSimple(filter);
            setResponseSimpleBackup(filter);
            const dropDownTemp = filter.map((a, i) => ({
              valor: a.POSTO,
              id: i + 1,
            }));
            setDropDownPostos(uniqueBy(dropDownTemp, 'valor'));
            setResponseUser([]);
            setSearchCPFSimple('');
            setSearchNomeSimple('');
            setSearchContratoSimple('');
            if (intervalBool) {
              if (controleTab) {
                filtrarStatusControle(lastFiltrarStatusControle, filter);
              }
              if (statusTab) filtrarPostos(lastFiltrarPosto, 'status', filter);
              else if (frequenciaTab)
                filtrarPostos(lastFiltrarPosto, 'frequencia', filter);
            }
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
            Swal.fire({
              icon: 'error',
              text: 'Erro ao carregar dados, por favor atualize a página!',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            });
          });
      })
      .catch(() => {
        setLoading(false);
        Swal.fire({
          icon: 'info',
          text: 'Erro ao carregar dados, por favor atualize a página!',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        });
      });

  const handlePesquisar = useCallback(async () => {
    if (
      filial === '' ||
      filial === 'nenhuma' ||
      !(dataInicial !== null && dataFinal !== null)
    ) {
      Swal.fire({
        icon: 'info',
        text: 'Preencha os dados para Pesquisar',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
    } else {
      const codigo: string = transfomaNomeToCodigo(filial);
      const dataInicialGet = formatarData(dataInicial.toLocaleString());
      const dataFinalGet = formatarData(dataFinal.toLocaleString());

      setLastCodigo(codigo);
      setLastDataInicial(dataInicialGet);
      setLastDataFinal(dataFinalGet);
      window.scrollTo(0, 0);
      setPorcentagem(0);
      setLoading(true);
      getData(codigo, dataInicialGet, dataFinalGet);
    }
  }, [dataFinal, dataInicial, filial, getData, transfomaNomeToCodigo]);

  const responseDataSimple = useMemo(() => {
    let computedResponses: ResponseGetSimples[] = [];
    computedResponses = responseSimple;
    if (searchContratoSimple) {
      computedResponses = computedResponses.filter((res: ResponseGetSimples) =>
        res.CONTRATO.toLowerCase().includes(searchContratoSimple.toLowerCase()),
      );
    }
    if (searchNomeSimple) {
      computedResponses = computedResponses.filter((res: ResponseGetSimples) =>
        res.NOME.toLowerCase().includes(searchNomeSimple.toLowerCase()),
      );
    }
    if (searchCPFSimple) {
      let CPF: string = searchCPFSimple.replace('.', '');
      CPF = CPF.replace('.', '');
      CPF = CPF.replace('-', '');
      computedResponses = computedResponses.filter((res: ResponseGetSimples) =>
        res.CPFSEMFORMATO.toLowerCase().includes(CPF.toLowerCase()),
      );
    }
    const finalResponse = uniqueBy(computedResponses, 'CPFSEMFORMATO');
    // console.log(
    //   'computedResponsesFreq: ',
    //   finalResponse,
    // );
    setTotalTabela(finalResponse.length);
    setTotalItemsGeral(finalResponse.length);
    if (ITEMS_PER_PAGE === 1) {
      return finalResponse;
    }

    return finalResponse.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE,
    );
  }, [
    ITEMS_PER_PAGE,
    currentPage,
    responseSimple,
    searchCPFSimple,
    searchContratoSimple,
    searchNomeSimple,
  ]);

  const responseData = useMemo(() => {
    let computedResponses: DataEventos[] = [];
    computedResponses = responseEventos;
    if (searchContratoSimple) {
      computedResponses = computedResponses.filter((res: ResponseGet) =>
        res.CONTRATO.toLowerCase().includes(searchContratoSimple.toLowerCase()),
      );
    }
    if (searchNomeSimple) {
      computedResponses = computedResponses.filter((res: ResponseGet) =>
        res.NOME.toLowerCase().includes(searchNomeSimple.toLowerCase()),
      );
    }
    if (searchCPFSimple) {
      let CPF: string = searchCPFSimple.replace('.', '');
      CPF = CPF.replace('.', '');
      CPF = CPF.replace('-', '');
      computedResponses = computedResponses.filter((res: ResponseGet) =>
        res.CPFSEMFORMATO.toLowerCase().includes(CPF.toLowerCase()),
      );
    }
    setTotalTabelaEventos(computedResponses.length);
    setTotalItemsGeralEventos(computedResponses.length);
    if (ITEMS_PER_PAGE === 1) {
      return computedResponses;
    }
    // console.log('response', computedResponses);
    return computedResponses.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE,
    );
  }, [
    ITEMS_PER_PAGE,
    currentPage,
    responseEventos,
    searchCPFSimple,
    searchContratoSimple,
    searchNomeSimple,
  ]);

  const responseDataFrequencia = useMemo(() => {
    let computedResponses: ResponseGet[] = [];
    computedResponses = response;
    if (searchContratoSimple) {
      computedResponses = computedResponses.filter((res: ResponseGet) =>
        res.CONTRATO.toLowerCase().includes(searchContratoSimple.toLowerCase()),
      );
    }
    if (searchNomeSimple) {
      computedResponses = computedResponses.filter((res: ResponseGet) =>
        res.NOME.toLowerCase().includes(searchNomeSimple.toLowerCase()),
      );
    }
    if (searchCPFSimple) {
      let CPF: string = searchCPFSimple.replace('.', '');
      CPF = CPF.replace('.', '');
      CPF = CPF.replace('-', '');
      computedResponses = computedResponses.filter((res: ResponseGet) =>
        res.CPFSEMFORMATO.toLowerCase().includes(CPF.toLowerCase()),
      );
    }

    setTotalTabelaFrequencia(computedResponses.length);
    setTotalItemsGeralFrequencia(computedResponses.length);
    if (ITEMS_PER_PAGE === 1) {
      return computedResponses;
    }
    return computedResponses.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE,
    );
  }, [
    ITEMS_PER_PAGE,
    currentPage,
    response,
    searchCPFSimple,
    searchContratoSimple,
    searchNomeSimple,
  ]);

  const preencherObjeto = useCallback(() => {
    let numeroId = -1;
    copiaResponseUser.forEach((dado) => {
      if (dado.Id_PontoEletronico === null) {
        dado.Id_PontoEletronico = numeroId;
        numeroId += -1;
      }
    });
  }, [copiaResponseUser]);

  // useInterval(() => {
  //   if (
  //     lastCodigo &&
  //     !verPonto &&
  //     !verPerfil &&
  //     (eventosTab || controleTab) &&
  //     !searchContratoSimple &&
  //     !searchNomeSimple &&
  //     !searchCPFSimple
  //   )
  //     getData(lastCodigo, lastDataInicial, lastDataFinal).then(() => {
  //       setIntervalBool(true);
  //     });
  //   setDiaAtual(format(new Date(), 'yyyy-MM-dd'));
  //   setHoraAtual(format(new Date(), 'HH:mm'));
  // }, 10000);

  return (
    <>
      <ModalConexao />
      <DrawerApp />
      <ContainerPesquisaGeral>
        <ContainerSupFilter>
          <TabList>
            <div>
              <TabButton
                active={controleTab}
                onClick={() => {
                  setControleTab(true);
                  setControleGeralTab(true);
                  setControleFaltososTab(false);
                  setStatusTab(false);
                  setEventosTab(false);
                  setFrequenciaTab(false);
                }}
                type="button"
              >
                Controle
              </TabButton>

              {controleTab && (
                <>
                  <BsArrowRight />

                  <TabButton
                    active={controleGeralTab}
                    onClick={() => {
                      setControleGeralTab(true);
                      setControleFaltososTab(false);
                      setStatusTab(false);
                      setEventosTab(false);
                      setFrequenciaTab(false);
                    }}
                    type="button"
                  >
                    Geral
                  </TabButton>
                </>
              )}
            </div>
            <div>
              <TabButton
                active={statusTab}
                onClick={() => {
                  setStatusTab(true);
                  setEventosTab(false);
                  setFrequenciaTab(false);
                  setControleTab(false);
                  setControleGeralTab(false);
                  setControleFaltososTab(false);
                }}
                type="button"
              >
                Status
              </TabButton>
              {controleTab && (
                <>
                  <BsArrowReturnRight height="18px" />
                  <TabButton
                    active={controleFaltososTab}
                    onClick={() => {
                      setControleTab(true);
                      setControleGeralTab(false);
                      setControleFaltososTab(true);
                      setStatusTab(false);
                      setEventosTab(false);
                      setFrequenciaTab(false);
                    }}
                    type="button"
                  >
                    Faltosos
                  </TabButton>
                </>
              )}
            </div>
            <TabButton
              active={eventosTab}
              onClick={() => {
                setStatusTab(false);
                setEventosTab(true);
                setFrequenciaTab(false);
                setControleTab(false);
                setControleGeralTab(false);
                setControleFaltososTab(false);
              }}
              type="button"
            >
              Eventos
            </TabButton>
            <TabButton
              active={frequenciaTab}
              onClick={() => {
                setStatusTab(false);
                setEventosTab(false);
                setFrequenciaTab(true);
                setControleTab(false);
                setControleGeralTab(false);
                setControleFaltososTab(false);
              }}
              type="button"
            >
              Frequencia
            </TabButton>
          </TabList>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {statusTab && dropDownPostos.length > 0 && (
              <DropDown
                onChangeItems={(value: string) => {
                  filtrarStatus(value);
                }}
                objetoEnum={dropDownFiltro}
              />
            )}
            {controleTab && dropDownPostos.length > 0 && (
              <DropDown
                onChangeItems={(value: string) => {
                  filtrarStatusControle(value);
                }}
                objetoEnum={dropDownFiltroControle}
              />
            )}
            {eventosTab && (
              <DropDown
                onChangeItems={(value: string) => {
                  filtrarTipoRegistro(value);
                }}
                objetoEnum={dropDownTipoRegistro}
              />
            )}
            {dropDownPostos.length > 0 && (
              <DropDown
                onChangeItems={(value: string) => {
                  if (statusTab) filtrarPostos(value, 'status');
                  else if (eventosTab) filtrarPostos(value, 'eventos');
                  else if (frequenciaTab) filtrarPostos(value, 'frequencia');
                  else filtrarPostos(value, 'frequencia');
                }}
                objetoEnum={[{ id: 0, valor: 'Posto' }, ...dropDownPostos]}
              />
            )}
            <DropDown
              onChangeItems={(value: string) => {
                setFilial(value);
              }}
              objetoEnum={[
                { valor: 'Nenhum', id: 0 },
                ...filiais.map((item, index) => ({
                  id: index + 1,
                  valor: `${item.importId} - ${item.name}`,
                })),
                // { valor: '9999 - Backup Fortes', id: 9999 },
              ]}
            />

            <p style={{ marginRight: -3, marginLeft: 5 }}>Período: </p>
            <DatePicker
              locale="pt-BR"
              selected={dataInicial}
              onChange={(date) => {
                setDataInicial(date as Date);
                setMaxDate(formatarMaxData(date?.toLocaleString()));
              }}
              selectsStart
              startDate={dataInicial}
              endDate={dataFinal}
              dateFormat="P"
              placeholderText="dd/mm/aaaa"
            />
            <DatePicker
              locale="pt-BR"
              selected={dataFinal}
              onChange={(date) => {
                setDataFinal(date as Date);
              }}
              selectsEnd
              startDate={dataInicial}
              endDate={dataFinal}
              minDate={dataInicial}
              maxDate={new Date(maxDate)}
              dateFormat="P"
              placeholderText="dd/mm/aaaa"
            />
            <ContainerInfFilter>
              <button type="button" onClick={() => handlePesquisar()}>
                Pesquisar
              </button>
            </ContainerInfFilter>
          </div>
        </ContainerSupFilter>
      </ContainerPesquisaGeral>

      {(verPonto || verPerfil) && (
        <AbaDeButtons style={{ width: '90%', margin: '0px auto' }}>
          <div className="geralAba">
            <DivAbaDeButtons className="aba" cor={false}>
              <Tooltip
                title="Voltar para seleção de filtros."
                className="Branco"
                tamanho={230}
                marginLeftComponent={50}
                marginRightComponent={56}
                marginBottomComponent={0}
                widthComponent={0}
              >
                <button
                  className="buttonVoltar"
                  type="button"
                  onClick={() => {
                    setSearchCPFSimple('');
                    setSearchNomeSimple('');
                    setSearchContratoSimple('');
                    setVerPonto(false);
                    setVerPerfil(false);
                  }}
                >
                  <BsArrowLeft size={40} color="#ffffff" />
                </button>
              </Tooltip>
            </DivAbaDeButtons>
          </div>
        </AbaDeButtons>
      )}
      {!verPerfil && !verPonto && response.length > 0 && (
        <ContainerFiltro style={{ backgroundColor: 'transparent' }}>
          <Container>
            <ContainerSearchTodos>
              <Search
                onSearch={(value: string) => {
                  setSearchNomeSimple(value);
                }}
                nomePlaceHolder="Buscar Nome"
              />
              <Search
                onSearch={(value: string) => {
                  setSearchCPFSimple(value);
                }}
                nomePlaceHolder="Buscar CPF"
              />
              <Search
                onSearch={(value: string) => {
                  setSearchContratoSimple(value);
                }}
                nomePlaceHolder="Buscar Contrato"
              />
            </ContainerSearchTodos>
          </Container>
        </ContainerFiltro>
      )}

      {controleTab &&
        controleGeralTab &&
        responseDataSimple.length > 0 &&
        !verPonto &&
        !verPerfil && (
          <>
            <ContainerTable>
              <table id="geral">
                <HeaderPonto
                  headers={headersControle}
                  onChange={(value: number) => {
                    setSearchCPFSimple('');
                    setSearchNomeSimple('');
                    setSearchContratoSimple('');
                  }}
                  onModal={() => {
                    window.scrollTo(0, 0);
                    setVerModalInformacao(true);
                  }}
                />
                <tbody>
                  {responseDataSimple
                    .filter((a) => !a.FERIAS)
                    // .filter((a) => !a.SEM_PONTO && !a.FERIAS)
                    .map((res: ResponseGetSimples, index) => (
                      <tr key={res.CPFSEMFORMATO + index.toString()}>
                        <td
                          style={{
                            minWidth: 250,
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <IoPersonCircle
                            style={{
                              border:
                                response.find(
                                  (a) =>
                                    a.CPFSEMFORMATO === res.CPFSEMFORMATO &&
                                    a.DATA_INICIAL_DIA_AGENDA ===
                                      res.DATA_INICIAL_DIA_AGENDA,
                                )?.STATUS === 'Atrasado'
                                  ? '4px solid red'
                                  : response.find(
                                      (a) =>
                                        a.CPFSEMFORMATO === res.CPFSEMFORMATO &&
                                        a.DATA_INICIAL_DIA_AGENDA ===
                                          res.DATA_INICIAL_DIA_AGENDA,
                                    )?.STATUS === 'Expediente encerrado'
                                  ? ''
                                  : response.find(
                                      (a) =>
                                        a.CPFSEMFORMATO === res.CPFSEMFORMATO &&
                                        a.DATA_INICIAL_DIA_AGENDA ===
                                          res.DATA_INICIAL_DIA_AGENDA,
                                    )?.STATUS === 'Afastado'
                                  ? '4px solid gray'
                                  : '4px solid green',
                              marginRight: '8px',
                              borderRadius: '50%',
                            }}
                            size={45}
                          />
                          <span
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              fontSize: '1.1em',
                              fontWeight: 'bold',
                              alignItems: 'flex-start',
                            }}
                          >
                            {res.NOME}
                            <small>
                              {
                                response.find(
                                  (a) =>
                                    a.CPFSEMFORMATO === res.CPFSEMFORMATO &&
                                    a.DATA_INICIAL_DIA_AGENDA ===
                                      res.DATA_INICIAL_DIA_AGENDA,
                                )?.STATUS
                              }
                              {response.find(
                                (a) =>
                                  a.CPFSEMFORMATO === res.CPFSEMFORMATO &&
                                  a.DATA_INICIAL_DIA_AGENDA ===
                                    res.DATA_INICIAL_DIA_AGENDA,
                              )?.STATUS_BATIDA === 'Se adiantou'
                                ? ''
                                : ` | se atrasou ${
                                    response.find(
                                      (a) =>
                                        a.CPFSEMFORMATO === res.CPFSEMFORMATO &&
                                        a.DATA_INICIAL_DIA_AGENDA ===
                                          res.DATA_INICIAL_DIA_AGENDA,
                                    )?.DIFERENCA_PONTO_MARCADO_E_BATIDO
                                  } minutos`}
                            </small>
                          </span>
                        </td>
                        <td style={{ minWidth: 250 }}>{res.NOME_CLIENTE}</td>
                        <td style={{ minWidth: 120 }}>
                          {maskCpf(res.CPFSEMFORMATO)}
                        </td>
                        <td style={{ minWidth: 150 }}>{res.POSTO}</td>
                        <td style={{ minWidth: 150 }}>
                          {
                            response.find(
                              (a) =>
                                a.CPFSEMFORMATO === res.CPFSEMFORMATO &&
                                a.DATA_INICIAL_DIA_AGENDA ===
                                  res.DATA_INICIAL_DIA_AGENDA,
                            )?.HORA_INICIAL
                          }
                        </td>
                        <td style={{ minWidth: 150 }}>
                          {response.find(
                            (a) =>
                              a.CPFSEMFORMATO === res.CPFSEMFORMATO &&
                              a.DATA_INICIAL_DIA_AGENDA ===
                                res.DATA_INICIAL_DIA_AGENDA,
                          )?.PONTO_HORA_ENTRADA
                            ? response.find(
                                (a) =>
                                  a.CPFSEMFORMATO === res.CPFSEMFORMATO &&
                                  a.DATA_INICIAL_DIA_AGENDA ===
                                    res.DATA_INICIAL_DIA_AGENDA,
                              )?.PONTO_HORA_ENTRADA
                            : '-'}
                        </td>
                        {/* <td style={{ minWidth: 120 }}>
                      {res.DATA_INICIAL_DIA_AGENDA
                        ? formatarDataAnoMesDia(res.DATA_INICIAL_DIA_AGENDA)
                        : '-'}
                    </td> */}
                        <TdIcons
                          style={{
                            minWidth: 140,
                          }}
                          ponto={res.COM_PONTO}
                        >
                          <button
                            type="button"
                            title={res.COM_PONTO ? 'Ver Perfil' : 'Sem Perfil'}
                            onClick={() => {
                              setResponseUser(filterUserResponse(res));
                              setVerPerfil(true);
                            }}
                            disabled={!res.COM_PONTO}
                          >
                            <IoPersonOutline
                              color={res.COM_PONTO ? '#000' : '#333'}
                            />
                          </button>
                          <button
                            type="button"
                            title={res.COM_PONTO ? 'Ver Ponto' : 'Sem Ponto'}
                            disabled={!res.COM_PONTO}
                            onClick={() => {
                              setResponseUser(filterUserResponse(res));
                              setVerPonto(true);
                            }}
                          >
                            <FaRegClock
                              color={res.COM_PONTO ? '#000' : '#333'}
                            />
                          </button>
                        </TdIcons>
                      </tr>
                    ))}
                </tbody>
              </table>
            </ContainerTable>
            <ContainerPagination>
              <div className="divPaginacao">
                <Pagination
                  total={totalItemsGeral}
                  itemsPerPage={ITEMS_PER_PAGE}
                  currentPage={currentPage}
                  onPageChange={(page: number) => setCurrentPage(page)}
                />
                <DropDown
                  onChangeItems={(value: string) => {
                    setCurrentPage(1);
                    if (value === 'todos') {
                      setITEMS_PER_PAGE(1);
                    } else {
                      setITEMS_PER_PAGE(Number(value));
                    }
                  }}
                  objetoEnum={dropDown}
                  minWidth={30}
                />
                <Tooltip
                  title="Exportar em Excel."
                  className="Branco"
                  tamanho={160}
                  marginLeftComponent={100}
                  marginRightComponent={20}
                  marginTopComponent={0}
                  widthComponent={0}
                >
                  <div style={{ marginLeft: -70 }}>
                    <ReactHTMLTableToExcel
                      id="export-excel"
                      // className="btn"
                      table="geral"
                      filename="gerenciamento_ponto"
                      sheet="tablexls"
                      buttonText={
                        <DivButtonExcel>
                          <RiFileExcel2Line style={{ height: 25, width: 25 }} />
                        </DivButtonExcel>
                      }
                    />
                  </div>
                </Tooltip>
                {/* <Tooltip
            title="Exportar em PDF."
            className="Branco"
            tamanho={160}
            marginLeftComponent={100}
            marginTopComponent={0}
            widthComponent={0}
          >
            <button
              className="buttonPdf"
              type="button"
              onClick={() => {
                exportPdfGeral(
                  responseDataSimple,
                  filial,
                  formatarDataAnoMesDia(dataInicialGet),
                  formatarDataAnoMesDia(dataFinalGet),
                );
              }}
            >
              <GoFilePdf />
            </button>
          </Tooltip> */}
              </div>

              <div style={{}}>
                <p>Pessoas: {totalTabela}</p>
              </div>
            </ContainerPagination>
          </>
        )}
      {controleTab &&
        controleFaltososTab &&
        responseDataSimple.length > 0 &&
        !verPonto &&
        !verPerfil && (
          <>
            <ContainerTable>
              <table>
                <HeaderTable headers={headersControleFaltosos} />
                <tbody>
                  {responseDataSimple
                    .filter((a) => a.COM_PONTO)
                    .sort(
                      (a, b) =>
                        b.DIFERENCA_HORAS_TRABALHAR_TRABALHADA_MS! -
                        a.DIFERENCA_HORAS_TRABALHAR_TRABALHADA_MS!,
                    )
                    .map((res: ResponseGetSimples, index) => (
                      <tr key={res.CPFSEMFORMATO + index.toString()}>
                        <td
                          style={{
                            minWidth: 250,
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <IoPersonCircle
                            style={{
                              border:
                                res.DIFERENCA_HORAS_TRABALHAR_TRABALHADA_MS! > 0
                                  ? '4px solid red'
                                  : '4px solid green',
                              marginRight: '8px',
                              borderRadius: '50%',
                            }}
                            size={45}
                          />
                          <span
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              fontSize: '1.1em',
                              fontWeight: 'bold',
                              alignItems: 'flex-start',
                            }}
                          >
                            {res.NOME}
                            <small>
                              Trabalhou{' '}
                              {toHourAndMinute(
                                res.DIFERENCA_HORAS_TRABALHAR_TRABALHADA_MS! > 0
                                  ? res.DIFERENCA_HORAS_TRABALHAR_TRABALHADA_MS!
                                  : res.DIFERENCA_HORAS_TRABALHAR_TRABALHADA_MS! *
                                      -1,
                              )}
                              {res.DIFERENCA_HORAS_TRABALHAR_TRABALHADA_MS! < 0
                                ? ' a mais'
                                : ' a menos'}
                            </small>
                          </span>
                        </td>
                        <td style={{ minWidth: 250 }}>{res.NOME_CLIENTE}</td>
                        <td style={{ minWidth: 120 }}>
                          {maskCpf(res.CPFSEMFORMATO)}
                        </td>
                        <td style={{ minWidth: 150 }}>{res.POSTO}</td>
                        <td style={{ minWidth: 150 }}>
                          {toHourAndMinute(res.HORAS_A_TRABALHAR_MS!)}
                        </td>
                        <td style={{ minWidth: 150 }}>
                          {toHourAndMinute(res.HORAS_TRABALHADAS_MS!)}
                        </td>

                        {/* <td style={{ minWidth: 120 }}>
                      {res.DATA_INICIAL_DIA_AGENDA
                        ? formatarDataAnoMesDia(res.DATA_INICIAL_DIA_AGENDA)
                        : '-'}
                    </td> */}
                        <TdIcons
                          style={{
                            minWidth: 140,
                          }}
                          ponto={res.COM_PONTO}
                        >
                          <button
                            type="button"
                            title={res.COM_PONTO ? 'Ver Perfil' : 'Sem Perfil'}
                            onClick={() => {
                              setResponseUser(filterUserResponse(res));
                              setVerPerfil(true);
                            }}
                            disabled={!res.COM_PONTO}
                          >
                            <IoPersonOutline
                              color={res.COM_PONTO ? '#000' : '#333'}
                            />
                          </button>
                          <button
                            type="button"
                            title={res.COM_PONTO ? 'Ver Ponto' : 'Sem Ponto'}
                            disabled={!res.COM_PONTO}
                            onClick={() => {
                              setResponseUser(filterUserResponse(res));
                              setVerPonto(true);
                            }}
                          >
                            <FaRegClock
                              color={res.COM_PONTO ? '#000' : '#333'}
                            />
                          </button>
                        </TdIcons>
                      </tr>
                    ))}
                </tbody>
              </table>
            </ContainerTable>
            <ContainerPagination>
              <div className="divPaginacao">
                <Pagination
                  total={totalItemsGeral}
                  itemsPerPage={ITEMS_PER_PAGE}
                  currentPage={currentPage}
                  onPageChange={(page: number) => setCurrentPage(page)}
                />
                <DropDown
                  onChangeItems={(value: string) => {
                    setCurrentPage(1);
                    if (value === 'todos') {
                      setITEMS_PER_PAGE(1);
                    } else {
                      setITEMS_PER_PAGE(Number(value));
                    }
                  }}
                  objetoEnum={dropDown}
                  minWidth={30}
                />
              </div>
              <div>
                <p>Registros: {totalTabelaEventos}</p>
              </div>
            </ContainerPagination>
          </>
        )}
      {statusTab && responseDataSimple.length > 0 && !verPonto && !verPerfil && (
        <>
          <ContainerTable>
            <table id="geral">
              <HeaderPonto
                headers={headers}
                onChange={(value: number) => {
                  setSearchCPFSimple('');
                  setSearchNomeSimple('');
                  setSearchContratoSimple('');
                }}
                onModal={() => {
                  window.scrollTo(0, 0);
                  setVerModalInformacao(true);
                }}
              />
              <tbody>
                {responseDataSimple.map((res: ResponseGetSimples, index) => (
                  <tr key={res.CPFSEMFORMATO + index.toString()}>
                    <td
                      style={{
                        minWidth: 250,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <IoPersonCircle
                        style={{
                          border:
                            response.find(
                              (a) =>
                                a.CPFSEMFORMATO === res.CPFSEMFORMATO &&
                                a.DATA_INICIAL_DIA_AGENDA ===
                                  res.DATA_INICIAL_DIA_AGENDA,
                            )?.STATUS === 'Atrasado'
                              ? '4px soilid red'
                              : response.find(
                                  (a) =>
                                    a.CPFSEMFORMATO === res.CPFSEMFORMATO &&
                                    a.DATA_INICIAL_DIA_AGENDA ===
                                      res.DATA_INICIAL_DIA_AGENDA,
                                )?.STATUS === 'Expediente encerrado'
                              ? ''
                              : res.FERIAS
                              ? '4px solid yellowgreen'
                              : response.find(
                                  (a) =>
                                    a.CPFSEMFORMATO === res.CPFSEMFORMATO &&
                                    a.DATA_INICIAL_DIA_AGENDA ===
                                      res.DATA_INICIAL_DIA_AGENDA,
                                )?.STATUS === 'Afastado'
                              ? '4px solid gray'
                              : '4px solid green',
                          marginRight: '8px',
                          borderRadius: '50%',
                        }}
                        size={45}
                      />
                      <span
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          fontSize: '1.1em',
                          fontWeight: 'bold',
                          alignItems: 'flex-start',
                        }}
                      >
                        {res.NOME}
                        <small>
                          {!res.FERIAS
                            ? response.find(
                                (a) =>
                                  a.CPFSEMFORMATO === res.CPFSEMFORMATO &&
                                  a.DATA_INICIAL_DIA_AGENDA ===
                                    res.DATA_INICIAL_DIA_AGENDA,
                              )?.STATUS
                            : 'Férias'}
                        </small>
                      </span>
                    </td>
                    <td style={{ minWidth: 250 }}>{res.NOME_CLIENTE}</td>
                    <td style={{ minWidth: 120 }}>
                      {maskCpf(res.CPFSEMFORMATO)}
                    </td>
                    <td>{res.CONTRATO}</td>
                    <td style={{ minWidth: 150 }}>{res.POSTO}</td>
                    {/* <td style={{ minWidth: 120 }}>
                      {res.DATA_INICIAL_DIA_AGENDA
                        ? formatarDataAnoMesDia(res.DATA_INICIAL_DIA_AGENDA)
                        : '-'}
                    </td> */}
                    <TdIcons
                      style={{
                        minWidth: 140,
                      }}
                      ponto={res.COM_PONTO}
                    >
                      <button
                        type="button"
                        title={res.COM_PONTO ? 'Ver Perfil' : 'Sem Perfil'}
                        onClick={() => {
                          setResponseUser(filterUserResponse(res));
                          setVerPerfil(true);
                        }}
                        disabled={!res.COM_PONTO}
                      >
                        <IoPersonOutline
                          color={res.COM_PONTO ? '#000' : '#333'}
                        />
                      </button>
                      <button
                        type="button"
                        title={res.COM_PONTO ? 'Ver Ponto' : 'Sem Ponto'}
                        disabled={!res.COM_PONTO}
                        onClick={() => {
                          setResponseUser(filterUserResponse(res));
                          setVerPonto(true);
                        }}
                      >
                        <FaRegClock color={res.COM_PONTO ? '#000' : '#333'} />
                      </button>
                    </TdIcons>
                  </tr>
                ))}
              </tbody>
            </table>
          </ContainerTable>
          <ContainerPagination>
            <div className="divPaginacao">
              <Pagination
                total={totalItemsGeral}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page: number) => setCurrentPage(page)}
              />
              <DropDown
                onChangeItems={(value: string) => {
                  setCurrentPage(1);
                  if (value === 'todos') {
                    setITEMS_PER_PAGE(1);
                  } else {
                    setITEMS_PER_PAGE(Number(value));
                  }
                }}
                objetoEnum={dropDown}
                minWidth={30}
              />
              <Tooltip
                title="Exportar em Excel."
                className="Branco"
                tamanho={160}
                marginLeftComponent={100}
                marginRightComponent={20}
                marginTopComponent={0}
                widthComponent={0}
              >
                <div style={{ marginLeft: -70 }}>
                  <ReactHTMLTableToExcel
                    id="export-excel"
                    // className="btn"
                    table="geral"
                    filename="gerenciamento_ponto"
                    sheet="tablexls"
                    buttonText={
                      <DivButtonExcel>
                        <RiFileExcel2Line style={{ height: 25, width: 25 }} />
                      </DivButtonExcel>
                    }
                  />
                </div>
              </Tooltip>
              {/* <Tooltip
            title="Exportar em PDF."
            className="Branco"
            tamanho={160}
            marginLeftComponent={100}
            marginTopComponent={0}
            widthComponent={0}
          >
            <button
              className="buttonPdf"
              type="button"
              onClick={() => {
                exportPdfGeral(
                  responseDataSimple,
                  filial,
                  formatarDataAnoMesDia(dataInicialGet),
                  formatarDataAnoMesDia(dataFinalGet),
                );
              }}
            >
              <GoFilePdf />
            </button>
          </Tooltip> */}
            </div>

            <div style={{}}>
              <p>Pessoas: {totalTabela}</p>
            </div>
          </ContainerPagination>
        </>
      )}
      {responseUser.length > 0 && verPonto && (
        <>
          {preencherObjeto()}
          <div style={{ display: 'none' }}>
            <TablePonto dados={responseUser} />
          </div>

          {/* ------------------------LINHA 1{/*------------------------*/}
          <ContainerFiltro>
            <Aba>
              <div>
                <div className="dropdown">
                  <button
                    type="button"
                    className="dropbtn"
                    onFocus={(): void => {
                      setSetaExportar('AiFillCaretDown');
                    }}
                    onMouseOver={(): void => {
                      setSetaExportar('AiFillCaretDown');
                    }}
                    onBlur={(): void => {
                      setSetaExportar('AiFillCaretLeft');
                    }}
                    onMouseOut={(): void => {
                      setSetaExportar('AiFillCaretLeft');
                    }}
                  >
                    <p style={{ margin: 0, padding: 0 }}>Exportar Relatório</p>
                    {setaExportar === 'AiFillCaretLeft' ? (
                      <AiFillCaretLeft />
                    ) : (
                      <AiFillCaretDown />
                    )}
                  </button>
                  <div
                    className="dropdown-content"
                    onFocus={(): void => {
                      setSetaExportar('AiFillCaretDown');
                    }}
                    onMouseOver={(): void => {
                      setSetaExportar('AiFillCaretDown');
                    }}
                    onBlur={(): void => {
                      setSetaExportar('AiFillCaretLeft');
                    }}
                    onMouseOut={(): void => {
                      setSetaExportar('AiFillCaretLeft');
                    }}
                  >
                    <ReactHTMLTableToExcel
                      id="export-excel"
                      className="btn"
                      table="PontoExcel"
                      filename={`ponto_${responseUser[0].NOME}`}
                      sheet="tablexls"
                      buttonText="Exportar Excel"
                    />
                    <button
                      type="button"
                      onClick={() => {
                        setVisualizarModalPdf(true);
                        window.scrollTo(0, 0);
                      }}
                      style={{
                        borderRadius: '0px',
                      }}
                    >
                      Exportar PDF
                    </button>
                  </div>
                </div>
              </div>
            </Aba>
          </ContainerFiltro>
          {/* ------------------------LINHA 1{/*------------------------*/}

          <ContainerTableEscala>
            <table>
              <tbody>
                <tr key="a">
                  <td style={{ minWidth: 240, maxWidth: 250 }}>
                    Cliente: {responseUser[0].NOME_CLIENTE}
                  </td>
                  <td style={{ minWidth: 250 }}>
                    Nome: {responseUser[0].NOME}
                  </td>
                </tr>
                <tr key="b">
                  <td style={{ minWidth: 100 }}>
                    CPF: {maskCpf(responseUser[0].CPFSEMFORMATO)}
                  </td>
                  <td>Contrato: {responseUser[0].CONTRATO}</td>
                </tr>
                <tr key="c">
                  <td style={{ maxWidth: 250 }}>
                    Posto: {responseUser[0].POSTO}
                  </td>
                  <td>Função: {responseUser[0].FUNCAO}</td>
                </tr>
                <tr key="d">
                  <td style={{ minWidth: 240, maxWidth: 250 }}>
                    Escala: {responseUser[0].ESCALA}
                  </td>
                  <td style={{ maxWidth: 250 }}>
                    {`Hora Inicial: ${responseUser[0].HORA_INICIAL}    Hora Final: ${responseUser[0].HORA_FINAL}`}
                  </td>
                </tr>
              </tbody>
            </table>
          </ContainerTableEscala>

          <ContainerTable>
            <table id="EscalaEsp">
              <HeaderTable headers={headersEscala} />
              <tbody>
                {copiaResponseUser.map((res: ResponseGet, index) => (
                  <tr key={res.CPFSEMFORMATO + index.toString()}>
                    <td style={{ minWidth: 100 }}>
                      {formatarDataAnoMesDia(res.DATA_INICIAL_DIA_AGENDA)}
                    </td>
                    <td>{res.LOCAL_LATITUDE}</td>
                    <td>{res.LOCAL_LONGITUDE}</td>
                    <td>
                      <p>Entrada</p>
                      <p>Pausa</p>
                      <p>Volta</p>
                      <p>Saída</p>
                    </td>
                    <td style={{ minWidth: 100 }}>
                      <div>
                        <p>
                          {res.PONTO_DATA_ENTRADA
                            ? formatarDataAnoMesDia(res.PONTO_DATA_ENTRADA)
                            : '-'}
                        </p>
                        <p>
                          {res.PONTO_DATA_PAUSA
                            ? formatarDataAnoMesDia(res.PONTO_DATA_PAUSA)
                            : '-'}
                        </p>
                        <p>
                          {res.PONTO_DATA_VOLTA
                            ? formatarDataAnoMesDia(res.PONTO_DATA_VOLTA)
                            : '-'}
                        </p>
                        <p>
                          {res.PONTO_DATA_SAIDA
                            ? formatarDataAnoMesDia(res.PONTO_DATA_SAIDA)
                            : '-'}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div>
                        <p>
                          {res.PONTO_HORA_ENTRADA
                            ? res.PONTO_HORA_ENTRADA
                            : '-'}
                        </p>
                        <p>
                          {res.PONTO_HORA_PAUSA ? res.PONTO_HORA_PAUSA : '-'}
                        </p>
                        <p>
                          {res.PONTO_HORA_VOLTA ? res.PONTO_HORA_VOLTA : '-'}
                        </p>
                        <p>
                          {res.PONTO_HORA_SAIDA ? res.PONTO_HORA_SAIDA : '-'}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div>
                        <p>
                          <a
                            href={res.PONTO_LINK_FOTO_ENTRADA}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FiFileText />
                          </a>
                        </p>
                        <p>
                          <a
                            href={res.PONTO_LINK_FOTO_PAUSA}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FiFileText />
                          </a>
                        </p>
                        <p>
                          <a
                            href={res.PONTO_LINK_FOTO_VOLTA}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FiFileText />
                          </a>
                        </p>
                        <p>
                          <a
                            href={res.PONTO_LINK_FOTO_SAIDA}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FiFileText />
                          </a>
                        </p>
                      </div>
                    </td>
                    <td>
                      <div>
                        <p>
                          {res.PONTO_LATITUDE_ENTRADA
                            ? res.PONTO_LATITUDE_ENTRADA
                            : '-'}
                        </p>
                        <p>
                          {res.PONTO_LATITUDE_PAUSA
                            ? res.PONTO_LATITUDE_PAUSA
                            : '-'}
                        </p>
                        <p>
                          {res.PONTO_LATITUDE_VOLTA
                            ? res.PONTO_LATITUDE_VOLTA
                            : '-'}
                        </p>
                        <p>
                          {res.PONTO_LATITUDE_SAIDA
                            ? res.PONTO_LATITUDE_SAIDA
                            : '-'}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div>
                        <p>
                          {res.PONTO_LONGITUDE_ENTRADA
                            ? res.PONTO_LONGITUDE_ENTRADA
                            : '-'}
                        </p>
                        <p>
                          {res.PONTO_LONGITUDE_PAUSA
                            ? res.PONTO_LONGITUDE_PAUSA
                            : '-'}
                        </p>
                        <p>
                          {res.PONTO_LONGITUDE_VOLTA
                            ? res.PONTO_LONGITUDE_VOLTA
                            : '-'}
                        </p>
                        <p>
                          {res.PONTO_LONGITUDE_SAIDA
                            ? res.PONTO_LONGITUDE_SAIDA
                            : '-'}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div>
                        <p>
                          {res.PONTO_DIFERENCA_METROS_ENTRADA
                            ? `${res.PONTO_DIFERENCA_METROS_ENTRADA} m`
                            : '-'}
                        </p>
                        <p>
                          {res.PONTO_DIFERENCA_METROS_PAUSA
                            ? `${res.PONTO_DIFERENCA_METROS_PAUSA} m`
                            : '-'}
                        </p>
                        <p>
                          {res.PONTO_DIFERENCA_METROS_VOLTA
                            ? `${res.PONTO_DIFERENCA_METROS_VOLTA} m`
                            : '-'}
                        </p>
                        <p>
                          {res.PONTO_DIFERENCA_METROS_SAIDA
                            ? `${res.PONTO_DIFERENCA_METROS_SAIDA} m`
                            : '-'}
                        </p>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </ContainerTable>
          <ContainerPagination>
            <Pagination
              total={responseUser.length}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page: number) => setCurrentPage(page)}
            />
            <DropDown
              onChangeItems={(value: string) => {
                setCurrentPage(1);
                if (value === 'todos') {
                  setITEMS_PER_PAGE(1);
                } else {
                  setITEMS_PER_PAGE(Number(value));
                }
              }}
              objetoEnum={dropDown}
              minWidth={30}
            />
          </ContainerPagination>

          {visualizarModalPdf && (
            <ModalData
              cpf={responseUser[0].CPFSEMFORMATO}
              onClose={() => setVisualizarModalPdf(false)}
            />
          )}
        </>
      )}
      {responseUser.length > 0 && verPerfil && (
        <>
          <ContainerTable>
            <table>
              <HeaderTable headers={headersPontualidade} />
              <tbody>
                {copiaResponseUser.map((res: ResponseGet, index) => (
                  <tr key={res.CPFSEMFORMATO + index.toString()}>
                    <td style={{ minWidth: 100 }}>
                      {formatarDataAnoMesDia(res.DATA_INICIAL_DIA_AGENDA)}
                    </td>
                    <td>
                      {toHourAndMinute(
                        (res.PONTO_HORA_ENTRADA && res.PONTO_HORA_PAUSA
                          ? differenceTime(
                              `${res.PONTO_DATA_ENTRADA}T${res.PONTO_HORA_ENTRADA}`,
                              `${res.PONTO_DATA_PAUSA}T${res.PONTO_HORA_PAUSA}`,
                            )
                          : 0) +
                          (res.PONTO_HORA_VOLTA && res.PONTO_HORA_SAIDA
                            ? differenceTime(
                                `${res.PONTO_DATA_VOLTA}T${res.PONTO_HORA_VOLTA}`,
                                `${res.PONTO_DATA_SAIDA}T${res.PONTO_HORA_SAIDA}`,
                              )
                            : 0) +
                          (!res.PONTO_HORA_VOLTA &&
                          !res.PONTO_HORA_PAUSA &&
                          res.PONTO_HORA_ENTRADA &&
                          res.PONTO_HORA_SAIDA
                            ? differenceTime(
                                `${res.PONTO_DATA_ENTRADA}T${res.PONTO_HORA_ENTRADA}`,
                                `${res.PONTO_DATA_SAIDA}T${res.PONTO_HORA_SAIDA}`,
                              )
                            : 0),
                      )}
                    </td>
                    <td>
                      {horasATrabalharEmMS(res.HORAS_A_TRABALHAR) -
                        ((res.PONTO_HORA_ENTRADA && res.PONTO_HORA_PAUSA
                          ? differenceTime(
                              `${res.PONTO_DATA_ENTRADA}T${res.PONTO_HORA_ENTRADA}`,
                              `${res.PONTO_DATA_PAUSA}T${res.PONTO_HORA_PAUSA}`,
                            )
                          : 0) +
                          (res.PONTO_HORA_VOLTA && res.PONTO_HORA_SAIDA
                            ? differenceTime(
                                `${res.PONTO_DATA_VOLTA}T${res.PONTO_HORA_VOLTA}`,
                                `${res.PONTO_DATA_SAIDA}T${res.PONTO_HORA_SAIDA}`,
                              )
                            : 0) +
                          (!res.PONTO_HORA_VOLTA &&
                          !res.PONTO_HORA_PAUSA &&
                          res.PONTO_HORA_ENTRADA &&
                          res.PONTO_HORA_SAIDA
                            ? differenceTime(
                                `${res.PONTO_DATA_ENTRADA}T${res.PONTO_HORA_ENTRADA}`,
                                `${res.PONTO_DATA_SAIDA}T${res.PONTO_HORA_SAIDA}`,
                              )
                            : 0)) >
                      0
                        ? toHourAndMinute(
                            horasATrabalharEmMS(res.HORAS_A_TRABALHAR) -
                              ((res.PONTO_HORA_ENTRADA && res.PONTO_HORA_PAUSA
                                ? differenceTime(
                                    `${res.PONTO_DATA_ENTRADA}T${res.PONTO_HORA_ENTRADA}`,
                                    `${res.PONTO_DATA_PAUSA}T${res.PONTO_HORA_PAUSA}`,
                                  )
                                : 0) +
                                (res.PONTO_HORA_VOLTA && res.PONTO_HORA_SAIDA
                                  ? differenceTime(
                                      `${res.PONTO_DATA_VOLTA}T${res.PONTO_HORA_VOLTA}`,
                                      `${res.PONTO_DATA_SAIDA}T${res.PONTO_HORA_SAIDA}`,
                                    )
                                  : 0) +
                                (!res.PONTO_HORA_VOLTA &&
                                !res.PONTO_HORA_PAUSA &&
                                res.PONTO_HORA_ENTRADA &&
                                res.PONTO_HORA_SAIDA
                                  ? differenceTime(
                                      `${res.PONTO_DATA_ENTRADA}T${res.PONTO_HORA_ENTRADA}`,
                                      `${res.PONTO_DATA_SAIDA}T${res.PONTO_HORA_SAIDA}`,
                                    )
                                  : 0)),
                          )
                        : '-'}
                    </td>
                    <td>
                      {horasATrabalharEmMS(res.HORAS_A_TRABALHAR) -
                        ((res.PONTO_HORA_ENTRADA && res.PONTO_HORA_PAUSA
                          ? differenceTime(
                              `${res.PONTO_DATA_ENTRADA}T${res.PONTO_HORA_ENTRADA}`,
                              `${res.PONTO_DATA_PAUSA}T${res.PONTO_HORA_PAUSA}`,
                            )
                          : 0) +
                          (res.PONTO_HORA_VOLTA && res.PONTO_HORA_SAIDA
                            ? differenceTime(
                                `${res.PONTO_DATA_VOLTA}T${res.PONTO_HORA_VOLTA}`,
                                `${res.PONTO_DATA_SAIDA}T${res.PONTO_HORA_SAIDA}`,
                              )
                            : 0) +
                          (!res.PONTO_HORA_VOLTA &&
                          !res.PONTO_HORA_PAUSA &&
                          res.PONTO_HORA_ENTRADA &&
                          res.PONTO_HORA_SAIDA
                            ? differenceTime(
                                `${res.PONTO_DATA_ENTRADA}T${res.PONTO_HORA_ENTRADA}`,
                                `${res.PONTO_DATA_SAIDA}T${res.PONTO_HORA_SAIDA}`,
                              )
                            : 0)) <
                      0
                        ? toHourAndMinute(
                            (horasATrabalharEmMS(res.HORAS_A_TRABALHAR) -
                              ((res.PONTO_HORA_ENTRADA && res.PONTO_HORA_PAUSA
                                ? differenceTime(
                                    `${res.PONTO_DATA_ENTRADA}T${res.PONTO_HORA_ENTRADA}`,
                                    `${res.PONTO_DATA_PAUSA}T${res.PONTO_HORA_PAUSA}`,
                                  )
                                : 0) +
                                (res.PONTO_HORA_VOLTA && res.PONTO_HORA_SAIDA
                                  ? differenceTime(
                                      `${res.PONTO_DATA_VOLTA}T${res.PONTO_HORA_VOLTA}`,
                                      `${res.PONTO_DATA_SAIDA}T${res.PONTO_HORA_SAIDA}`,
                                    )
                                  : 0) +
                                (!res.PONTO_HORA_VOLTA &&
                                !res.PONTO_HORA_PAUSA &&
                                res.PONTO_HORA_ENTRADA &&
                                res.PONTO_HORA_SAIDA
                                  ? differenceTime(
                                      `${res.PONTO_DATA_ENTRADA}T${res.PONTO_HORA_ENTRADA}`,
                                      `${res.PONTO_DATA_SAIDA}T${res.PONTO_HORA_SAIDA}`,
                                    )
                                  : 0))) *
                              -1,
                          )
                        : '-'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </ContainerTable>
          <ContainerPagination>
            <Pagination
              total={responseUser.length}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page: number) => setCurrentPage(page)}
            />
            <DropDown
              onChangeItems={(value: string) => {
                setCurrentPage(1);
                if (value === 'todos') {
                  setITEMS_PER_PAGE(1);
                } else {
                  setITEMS_PER_PAGE(Number(value));
                }
              }}
              objetoEnum={dropDown}
              minWidth={30}
            />
          </ContainerPagination>
        </>
      )}
      {eventosTab && responseData.length > 0 && !verPonto && !verPerfil && (
        <>
          <ContainerTableEventos>
            <table>
              <HeaderTable headers={headersEventos} />
              <tbody>
                {responseData.map((res: DataEventos, index) => (
                  <tr key={res.CPFSEMFORMATO + index.toString()}>
                    <td>
                      <span>{moment(res.date).format('HH:mm')}</span>
                      {moment(res.date).format('DD-MM-yyyy')}
                    </td>
                    <td>{res.NOME}</td>
                    <td>{res.type}</td>
                    <td>{res.CPFSEMFORMATO}</td>
                    <td>{res.POSTO}</td>
                    <TdIcons
                      style={{
                        minWidth: 140,
                      }}
                      ponto
                    >
                      <button
                        type="button"
                        title="Ver Perfil"
                        onClick={() => {
                          setResponseUser(filterUserResponse(res));
                          setVerPerfil(true);
                        }}
                      >
                        <IoPersonOutline color="#000" />
                      </button>
                      <button
                        type="button"
                        title="Ver Ponto"
                        onClick={() => {
                          setResponseUser(filterUserResponse(res));
                          setVerPonto(true);
                          // console.log(res);
                        }}
                      >
                        <FaRegClock color="#000" />
                      </button>
                    </TdIcons>
                  </tr>
                ))}
              </tbody>
            </table>
          </ContainerTableEventos>
          <ContainerPagination>
            <div className="divPaginacao">
              <Pagination
                total={totalItemsGeralEventos}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page: number) => setCurrentPage(page)}
              />
              <DropDown
                onChangeItems={(value: string) => {
                  setCurrentPage(1);
                  if (value === 'todos') {
                    setITEMS_PER_PAGE(1);
                  } else {
                    setITEMS_PER_PAGE(Number(value));
                  }
                }}
                objetoEnum={dropDown}
                minWidth={30}
              />
            </div>
            <div>
              <p>Registros: {totalTabelaEventos}</p>
            </div>
          </ContainerPagination>
        </>
      )}
      {frequenciaTab &&
        responseDataSimple.length > 0 &&
        !verPonto &&
        !verPerfil && (
          <>
            <ContainerTable>
              <table id="geral">
                <HeaderPonto
                  headers={headersFrequencia}
                  onChange={(value: number) => {
                    setSearchCPFSimple('');
                    setSearchNomeSimple('');
                    setSearchContratoSimple('');
                  }}
                  onModal={() => {
                    window.scrollTo(0, 0);
                    setVerModalInformacao(true);
                  }}
                />
                <tbody>
                  {responseDataFrequencia.map((res: ResponseGet, index) => (
                    <tr key={res.CPFSEMFORMATO + index.toString()}>
                      <td
                        style={{
                          minWidth: 250,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <IoPersonCircle
                          style={{ marginRight: '8px' }}
                          size={70}
                        />
                        <span
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            fontSize: '1.1em',
                            fontWeight: 'bold',
                            alignItems: 'flex-start',
                          }}
                        >
                          {res.NOME}
                          <small>
                            Horas normais
                            <ProgressBar
                              height="8px"
                              percentage={
                                res.HORAS_DENTRO
                                  ? 100
                                  : ((horasATrabalharEmMS(
                                      res.HORAS_A_TRABALHAR,
                                    ) -
                                      (res.HORAS_FALTANTES ?? 0)) /
                                      horasATrabalharEmMS(
                                        res.HORAS_A_TRABALHAR,
                                      )) *
                                    100
                              }
                            />
                          </small>
                          <small>
                            Horas extras
                            <ProgressBar
                              height="8px"
                              color="#0f5f84"
                              percentage={
                                res.HORAS_DENTRO
                                  ? (res.HORAS_DENTRO /
                                      horasATrabalharEmMS(
                                        res.HORAS_A_TRABALHAR,
                                      )) *
                                    100
                                  : 0
                              }
                            />
                          </small>
                          <small>
                            Horas faltantes
                            <ProgressBar
                              height="8px"
                              color="#831818"
                              percentage={
                                res.HORAS_FALTANTES
                                  ? (res.HORAS_FALTANTES /
                                      horasATrabalharEmMS(
                                        res.HORAS_A_TRABALHAR,
                                      )) *
                                    100
                                  : 0
                              }
                            />
                          </small>
                        </span>
                      </td>
                      <td style={{ minWidth: 120 }}>
                        {res.DATA_INICIAL_DIA_AGENDA
                          ? formatarDataAnoMesDia(res.DATA_INICIAL_DIA_AGENDA)
                          : '-'}
                      </td>
                      <td style={{ minWidth: 250 }}>{res.NOME_CLIENTE}</td>
                      <td style={{ minWidth: 120 }}>
                        {maskCpf(res.CPFSEMFORMATO)}
                      </td>
                      <td>{res.CONTRATO}</td>
                      <td style={{ minWidth: 150 }}>{res.POSTO}</td>
                      <TdIcons
                        style={{
                          minWidth: 140,
                        }}
                        ponto={res.COM_PONTO}
                      >
                        <button
                          type="button"
                          title={res.COM_PONTO ? 'Ver Perfil' : 'Sem Perfil'}
                          onClick={() => {
                            setResponseUser(filterUserResponse(res));
                            setVerPerfil(true);
                          }}
                          disabled={!res.COM_PONTO}
                        >
                          <IoPersonOutline
                            color={res.COM_PONTO ? '#000' : '#333'}
                          />
                        </button>
                        <button
                          type="button"
                          title={res.COM_PONTO ? 'Ver Ponto' : 'Sem Ponto'}
                          disabled={!res.COM_PONTO}
                          onClick={() => {
                            setResponseUser(filterUserResponse(res));
                            setVerPonto(true);
                          }}
                        >
                          <FaRegClock color={res.COM_PONTO ? '#000' : '#333'} />
                        </button>
                      </TdIcons>
                    </tr>
                  ))}
                </tbody>
              </table>
            </ContainerTable>
            <ContainerPagination>
              <div className="divPaginacao">
                <Pagination
                  total={totalItemsGeralFrequencia}
                  itemsPerPage={ITEMS_PER_PAGE}
                  currentPage={currentPage}
                  onPageChange={(page: number) => setCurrentPage(page)}
                />
                <DropDown
                  onChangeItems={(value: string) => {
                    setCurrentPage(1);
                    if (value === 'todos') {
                      setITEMS_PER_PAGE(1);
                    } else {
                      setITEMS_PER_PAGE(Number(value));
                    }
                  }}
                  objetoEnum={dropDown}
                  minWidth={30}
                />
                <Tooltip
                  title="Exportar em Excel."
                  className="Branco"
                  tamanho={160}
                  marginLeftComponent={100}
                  marginRightComponent={20}
                  marginTopComponent={0}
                  widthComponent={0}
                >
                  <div style={{ marginLeft: -70 }}>
                    <ReactHTMLTableToExcel
                      id="export-excel"
                      // className="btn"
                      table="geral"
                      filename="gerenciamento_ponto"
                      sheet="tablexls"
                      buttonText={
                        <DivButtonExcel>
                          <RiFileExcel2Line style={{ height: 25, width: 25 }} />
                        </DivButtonExcel>
                      }
                    />
                  </div>
                </Tooltip>
              </div>

              <div style={{}}>
                <p>Pessoas: {totalTabelaFrequencia}</p>
              </div>
            </ContainerPagination>
          </>
        )}
      {verModalInformacao && (
        <ModalInformacao
          onClose={() => setVerModalInformacao(false)}
          title="Ponto"
          content="Será exibido uma tabela com todos que possuem ponto no período."
        />
      )}
      {loading && <Loading porcentagem={porcentagem} />}

      <div style={{ height: 40 }} />
    </>
  );
};

export default ViewPonto;
