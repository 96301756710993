import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { useField } from '@unform/core';
import { FiAlertCircle, FiEye, FiEyeOff } from 'react-icons/fi';

import Swal from 'sweetalert2';
import { Container, Error } from './styles';

import {
  maskCpf,
  maskTel,
  maskCnpj,
  maskCep,
  maskDateOfBirth,
  isCpf,
  isDate,
} from './mask';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  tipo?: string;
  mask?: string;
  onValue?: (arg: string) => void;
}

const Input: React.FC<InputProps> = ({
  name,
  icon: Icon,
  tipo = '',
  mask,
  onValue,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [whatMask, setWhatMask] = useState<string | number | null>();
  const [eyeClick, setEyeClick] = useState(tipo === 'password');
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);
  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    if (mask === 'cpf' && isCpf(inputRef.current?.value) === true) {
      setIsFilled(true);
      return;
    }

    if (
      mask === 'cpf' &&
      isCpf(inputRef.current?.value) === false &&
      inputRef.current?.value !== ''
    ) {
      Swal.fire({
        icon: 'info',
        title: 'Esse CPF não é válido! ',
      });
      setWhatMask('');
      // setIsModalVisibleCPF(true);
      setIsFilled(false);
      return;
    }

    if (mask === 'dateOfBirth' && isDate(inputRef.current?.value) === true) {
      setWhatMask(inputRef.current?.value);
      setIsFilled(true);
      return;
    }
    if (
      mask === 'dateOfBirth' &&
      isCpf(inputRef.current?.value) === false &&
      inputRef.current?.value !== ''
    ) {
      setWhatMask('');

      Swal.fire({
        icon: 'info',
        title: 'A Data de nascimento é inválida! ',
      });

      setIsFilled(false);
      return;
    }

    // Se for vazio será false se estiver preenchido será true.
    setIsFilled(!!inputRef.current?.value);
  }, []);// eslint-disable-line

  const handleInputChange = useCallback((e) => {
    if (onValue) {
      onValue(e.target.value);
    }
    switch (mask) {
      case 'cpf':
        setWhatMask(maskCpf(e.target.value));
        return;
      case 'cnpj':
        setWhatMask(maskCnpj(e.target.value));
        return;
      case 'phone':
        setWhatMask(maskTel(e.target.value));
        return;
      case 'cep':
        setWhatMask(maskCep(e.target.value));
        return;
      case 'dateOfBirth':
        setWhatMask(maskDateOfBirth(e.target.value));
        return;

      default:
        setWhatMask(e.target.value);
    }
  }, []);// eslint-disable-line

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container isErrored={!!error} isFilled={isFilled} isFocused={isFocused}>
      {Icon && <Icon size={20} />}
      <input
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        defaultValue={defaultValue}
        ref={inputRef}
        value={whatMask || ''}
        onChange={(eForChange) => {
          handleInputChange(eForChange);
        }}
        type={eyeClick && tipo === 'password' ? 'password' : 'text'}
        {...rest}
      />
      {tipo === 'password' && (
        <button
          type="button"
          onClick={() => {
            setEyeClick(!eyeClick);
          }}
        >
          {eyeClick && <FiEye />}
          {!eyeClick && <FiEyeOff />}
        </button>
      )}
      {error && (
        <Error title={error}>
          <FiAlertCircle color="#c53030" size="20" />
        </Error>
      )}
    </Container>
  );
};

export default Input;
