import styled from 'styled-components';

export const SelectContainer = styled.div`
  background: #232129;
  border-radius: 10px;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;

  border: 2px solid #232129;
  color: #665360;

  display: flex;
  align-items: center;

  select {
    background: none;
    border: none;
    font-size: 17px;
    padding: 16px;
    color: white;
    width: 100%;
  }

  input {
    background: none;
    border: none;
    font-size: 17px;
    padding: 16px;
    color: white;
    width: 100%;
  }

  option {
    background-color: #232323;
  }
`;

interface Props {
  color: string;
}

export const ButtonStyle = styled.button<Props>`
  background-color: ${(props) => props.color};
  height: 56px;
  border-radius: 10px;
  border: 0px;
  color: #f2f2f2;
  width: 150px;
  font-weight: 500;
  margin: 16px;
  transition: all 0.2s ease 0s;

  :hover {
    opacity: 0.8;
  }
`;
