import styled from 'styled-components';
import { shade } from 'polished';

interface ContainerProps {
  texto?: boolean;
  cor?: boolean;
  corTexto?: string;
}

export const Container = styled.div`
  height: auto;
  flex: 1;
  align-items: center;
  justify-content: center;
  overflow-x: auto;
  margin: 0px auto;
  margin-top: 10px;
`;

export const ContainerSearchTodos = styled.div`
  width: 100%;
  display: flex;
  margin: 0px auto;
  input {
    margin-right: 0px;
    width: 150px;
    border-top-right-radius: 5px;
    padding: 0px 5px;
  }
  div {
    border-top-left-radius: 5px;
  }
`;

export const ContainerFilter = styled.div`
  width: 90%;
  margin: 0px auto;
  margin-top: 15px;
  margin-bottom: 80px;
  padding: 55px 10px 30px 10px;
  background-color: #3e3946;
  overflow-x: auto;
  border-radius: 5px;
  h3 {
    text-align: center;
  }
`;

export const ContainerPesquisaGeral = styled.div`
  width: 90%;
  margin: 0px auto;
  margin-top: 15px;
  margin-bottom: 80px;
  padding: 55px 10px 30px 10px;
  background-color: #3e3946;
  overflow-x: auto;
  border-radius: 5px;
  h3 {
    text-align: center;
  }
`;

export const ContainerFilterGeral = styled.div`
  width: 90%;
  height: 170px;
  margin: 0px auto;
  margin-bottom: 40px;
  margin-top: -80px;
  background-color: #3e3946;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: auto;
  border-radius: 5px;
  h3 {
    text-align: center;
  }
`;

export const ContainerSupFilter = styled.div`
  display: flex;
  width: 100%;
  margin: 0px auto;
  justify-content: center;
  p {
    font-size: 17px;
    font-family: Arial;
    margin: 7px 5px 0px 10px;
  }
  select {
    background-color: #f1f1f1;
    color: #439133;
    border-radius: 10px;
    margin: 0px;
    margin-right: 10px;
    border: none;
    justify-content: center;
    width: 220px;
    padding: 5px 10px;
  }
  input {
    background-color: #f1f1f1;
    color: #439133;
    border-radius: 10px;
    border: none;
    padding: 10px;
    width: 110px;
    height: 40px;
    width: 120px;
    margin-left: 10px;
  }
`;

export const ContainerInfFilter = styled.div`
  display: flex;
  margin-left: 20px;
  justify-content: center;
  p {
    font-size: 17px;
    font-family: Arial;
    margin: 10px;
  }
  input {
    height: 20px;
    width: 20px;
  }
  label {
    font-size: 14px;
    text-align: center;
    font-family: Arial;
    width: 100px;
    padding: 10px 5px;
  }
  button {
    height: 40px;
    padding: 0px 10px;
    justify-content: center;
    align-content: center;
    background-color: #439133;
    color: #ffffff;
    border-radius: 5px;
    border: none;
    &:hover {
      background-color: #57bd42;
    }
  }
  svg {
    margin-left: 3px;
  }
`;

export const ContainerInfFilterGeral = styled.div`
  display: flex;
  height: 85px;
  justify-content: center;
  background-color: #4b4554;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  width: 95%;
  margin-bottom: -20px;

  p {
    font-size: 17px;
    font-family: Arial;
    margin: 10px;
  }
  input {
    height: 20px;
    width: 20px;
  }
  label {
    font-size: 14px;
    text-align: center;
    font-family: Arial;
    width: 100px;
    padding: 10px 5px;
  }
  button {
    height: 40px;
    padding: 0px 10px;
    justify-content: center;
    align-content: center;
    background-color: #439133;
    color: #ffffff;
    border-radius: 5px;
    border: none;
    &:hover {
      background-color: #57bd42;
    }
  }
  svg {
    margin-left: 3px;
  }
`;

export const ButtonFilter = styled.div`
  display: flex;
  justify-content: center;

  button {
    height: 40px;
    width: 60px;
    justify-content: center;
    align-content: center;
    background-color: #439133;
    color: #ffffff;
    border-radius: 5px;
    border: none;
    &:hover {
      background-color: #57bd42;
    }
  }
`;

export const ContainerFiltro = styled.div`
  width: 90%;
  margin: 0px auto;
  margin-top: 15px;
  background-color: #3e3946;
  border-radius: 10px;
`;
export const ContainerAbaDeButtons = styled.div`
  width: 90%;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px auto;
  margin-top: -35px;

  background-color: #3e3946;
  border-radius: 10px;
`;

export const Aba = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px 10px 20px;

  div.geralAba {
    display: flex;
  }

  .dropbtn {
    background-color: #006600;
    color: white;
    font-size: 16px;
    border: none;
    cursor: pointer;
  }

  .dropdown {
    position: sticky;
    display: inline-block;

    button {
      background-color: #006600;
      margin-left: 20px;
      border: none;
      height: 40px;
      width: 220px;
      //padding: 10px 20px 3px;
      padding-left: 20px;
      padding-right: 10px;
      border-radius: 5px;
      color: #ffffff;
      line-height: 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-content: center;
      align-items: center;
      p {
        //height: 100%;
        //width: 90%;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: transparent;
    width: auto;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    z-index: 1;
    margin-top: -3px;
    border-bottom-left-radius: 10px;

    border-bottom-right-radius: 10px;
  }

  .dropdown-content button {
    padding: 8px 12px;
    text-decoration: none;
    display: block;
    border-radius: 0px;
    width: 220px;
  }

  .dropdown-content button:hover {
    background-color: ${shade(0.2, '#006600')};
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  .dropdown:hover .dropbtn {
    background-color: #3e8e41;
  }
`;

export const AbaDeButtons = styled.div`
  width: 100%;
  height: 85px;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px 10px 20px;
  background-color: #4b4554;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  width: 95%;
  margin-bottom: 10px;

  div.geralAba {
    display: flex;
  }

  .dropbtn {
    background-color: #006600;
    color: white;
    font-size: 16px;
    border: none;
    cursor: pointer;
  }

  .dropdown {
    position: sticky;
    display: inline-block;

    button {
      background-color: #006600;
      margin-left: 20px;
      border: none;
      height: 40px;
      width: 220px;
      //padding: 10px 20px 3px;
      padding-left: 20px;
      padding-right: 10px;
      border-radius: 5px;
      color: #ffffff;
      line-height: 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-content: center;
      align-items: center;
      p {
        //height: 100%;
        //width: 90%;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: transparent;
    width: auto;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    z-index: 1;
    margin-top: -3px;
    border-bottom-left-radius: 10px;

    border-bottom-right-radius: 10px;
  }

  .dropdown-content button {
    padding: 8px 12px;
    text-decoration: none;
    display: block;
    border-radius: 0px;
    width: 220px;
  }

  .dropdown-content button:hover {
    background-color: ${shade(0.2, '#006600')};
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  .dropdown:hover .dropbtn {
    background-color: #3e8e41;
  }
`;

export const ContainerAba = styled.div<ContainerProps>`
  display: flex;

  button {
    height: 40px;
    background-color: ${(props) => (props.cor ? ' #3e8e41' : '#006600')};
    background-color: ${(props) => props.corTexto};
    color: ${(props) => (props.cor ? ' #ffffff' : '#cccccc')};
    font-weight: bold;
    border-radius: 10px;
    margin-right: 10px;
    border-width: 0px;
    min-width: 80px;
    padding: 0px 20px;
    &:hover {
      filter: brightness(1.2);
    }
  }
  button.buttonVoltar {
    height: 40px;
    background-color: #4b4554;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    margin-left: -25px;
    border-width: 0px;
    min-width: 80px;
    &:hover {
      background-color: #645c70;
    }
  }
`;

export const DivAbaDeButtons = styled.div<ContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    height: 40px;
    background-color: ${(props) => (props.cor ? ' #3e8e41' : '#006600')};
    background-color: ${(props) => props.corTexto};
    color: ${(props) => (props.cor ? ' #ffffff' : '#cccccc')};
    font-weight: bold;
    border-radius: 10px;
    margin-right: 10px;
    border-width: 0px;
    min-width: 80px;
    padding: 0px 20px;
    &:hover {
      filter: brightness(1.2);
    }
  }
  button.buttonVoltar {
    height: 40px;
    background-color: #4b4554;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    margin-left: -25px;
    margin-top: 5px;
    border-width: 0px;
    min-width: 80px;
    &:hover {
      background-color: #645c70;
    }
  }
`;

export const ContainerTable = styled.div`
  width: 90%;
  align-items: center;
  margin: 0px auto;
  padding: 0px;
  max-height: 60vh;
  overflow-x: auto;
  table {
    width: 100%;
    padding: 10px 40px;
    margin: 0px auto;
    border-collapse: collapse;
    border: 2px solid #006600;
    border-top: 0px;
    line-height: 17px;
    border-radius: 50px;
    th {
      border-bottom: 0px solid #006600;
      justify-content: space-between;
      padding: 5px 15px;
      background-color: #439133;
      color: #fff;
      position: sticky;
      position: -webkit-sticky;
      top: 0;
    }
  }
  tbody {
    td {
      margin: 5px;
      padding: 5px;
      text-align: center;
      font-size: 14px;
      button {
        background-color: transparent;
        color: #fff;
        border: 0;
        & + button {
          margin-left: 20px;
        }
      }
      .buttonAbonar {
        background-color: #439133;
        border-radius: 5px;
        padding: 8px;
        border: 1px;
      }
    }
    tr {
      border-bottom: 0px solid #006600;
      /**funcao para fazer as linhas da tabela uma de cada cor de forma alternada.*/
      &:nth-child(even) {
        background-color: #323232;
      }
      &:hover {
        background-color: #004d00;
      }
    }
    a {
      size: 25px;
      color: #ffffff;
    }
    .aComLink {
      text-decoration: normal;
    }
    select {
      background-color: #f1f1f1;
      color: #439133;
      height: 25px;
      border-radius: 10px;
      border: none;
      justify-content: center;
      padding: 0px 10px;
    }
  }
`;

export const ContainerPagination = styled.div<ContainerProps>`
  width: 90%;
  display: flex;
  margin: 0px auto;
  flex-direction: row;
  padding-bottom: 50px;
  padding-top: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 10px 10px;
  height: 70px;

  .divPaginacao {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    margin-left: 0px;
  }

  button {
    margin-left: 20px;
    border: none;
    height: auto;
    width: 100px;
    padding: 3px 20px 3px;
    border-radius: 5px;
    color: #ffffff;
    line-height: 20px;
    background-color: #006600;
    &:hover {
      background-color: #57bd42;
    }
  }
  .buttonPdf {
    background-color: #006600;
    margin-left: -50px;
    border: none;
    height: 40px;
    width: 100px;
    padding: 5px;
    border-radius: 5px;
    color: #ffffff;
    svg {
      height: 25px;
      width: 25px;
    }
    &:hover {
      background-color: #57bd42;
    }
  }
`;
export const DivButtonExcel = styled.div`
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContainerTableEscala = styled.div`
  width: 90%;
  align-items: center;
  margin: 0px auto;
  padding: 0px;
  max-height: 60vh;
  overflow-x: auto;
  table {
    width: 100%;
    padding: 10px 10px;
    margin: 20px auto;
    border-collapse: collapse;
    border: 1px solid #006600;
    th {
      border-bottom: 1px solid #006600;
      padding: 5px 15px;
      background-color: #439133;
      color: #fff;
      position: sticky;
      position: -webkit-sticky;
      top: 0;
    }
  }
  tbody {
    td {
      margin: 5px;
      padding: 5px;
      text-align: Left;
      font-size: 14px;
    }
  }
`;

export const TdIcons = styled.td`
  justify-content: center;
  align-content: center;
`;

export const ContainerButton = styled.div`
  width: 90%;
  display: flex;
  margin: 0px auto;
  margin-bottom: 50px;
  justify-content: flex-end;
  .buttonAbonar {
    background-color: #439133;
    border-radius: 5px;
    color: #ffffff;
    padding: 8px;
    border: 1px;
  }
`;

export const ContainerFormulario = styled.div<ContainerProps>`
  button {
    border: none;
    svg {
      height: 30px;
      width: 20px;
      color: ${(props) => (props.cor ? '#008800' : '#ffffff')};
    }
  }
`;

export const ContainerSpace = styled.div`
  height: 50px;
`;
