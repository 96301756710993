import styled, { css } from 'styled-components';
import Tooltip from '../Tooltip';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  background: #232129;
  border-radius: 10px;
  padding: 16px;
  width: 100%;
  border-width: 2px;
  border-style: solid;
  border-color: #232129;
  color: #665360;
  display: flex;
  flex-direction: column;
  margin-top: 8px;

  ${(props) =>
    props.isErrored &&
    css`
      color: #c53030;
      border-color: #c53030;
    `}

  ${(props) =>
    props.isFocused &&
    css`
      color: #439133;
      border: 2px solid #439133;
    `}

  ${(props) =>
    props.isFilled &&
    css`
      color: #439133;
    `}
  
    textarea {
    background: none;
    border: none;
    width: 100%;
    color: white;
    height: 80px;
    margin-left: 16px;
  }
`;

export const Error = styled(Tooltip)`
  margin-left: 16px;

  svg {
    margin-left: -10px;
  }

  span {
    background: #c53030;
    color: #fff;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;
