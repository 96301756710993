/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useCallback, useEffect, useState } from 'react';
import jsPDF from 'jspdf';
import { ResponseRelatorio } from '../Interface';
import {
  formatarHoraMin,
  formatarDataAnoMesDia,
  formatarDiasDaSemana,
} from '../../../components/Formulas/formulas';
import {
  ContainerModal,
  Container,
  ContainerButton,
  ContainerHeader,
  ContainerHeaderDados,
  ContainerPessoais,
  ContainerBodyDados,
  ContainerDadosDetalhes,
  ContainerBoxBorder,
  DadosDetalhes,
  ContainerButtonPDF,
} from './stylesModal';
import { horasATrabalharEmMS, tempoMsParaHorasEMin } from '../../Indicadores';

interface PontoMes {
  dados: ResponseRelatorio[];
  onClose(): void;
}

const Modal: React.FC<PontoMes> = ({
  dados,
  onClose = () => {
    //
  },
}) => {
  const horasATrabalhar = dados
    .map((a) => a.TEMPO_TRABALHADO_MS!)
    .reduce((acc, a) => acc + a, 0);
  const horasTrabalhadas = dados
    .map((a) => horasATrabalharEmMS(a.HORAS_A_TRABALHAR!))
    .reduce((acc, a) => acc + a, 0);
  const horasFalta = dados
    .map((a) => (a.DIFERENCA_TEMPO_MS! < 0 ? a.DIFERENCA_TEMPO_MS! * -1 : 0))
    .reduce((acc, a) => acc + a, 0);
  const horasExtra = dados
    .map((a) => (a.DIFERENCA_TEMPO_MS! > 0 ? a.DIFERENCA_TEMPO_MS! : 0))
    .reduce((acc, a) => acc + a, 0);
  const [copiaDados, setCopiaDados] = useState<ResponseRelatorio[]>([]);
  const textoAviso = '* - Abono possui mais de 1 motivo.';

  const handleOutSideClick = useCallback(
    (e) => {
      if (e.target.id === 'modal') {
        onClose();
      }
    },
    [onClose],
  );

  const copiaDadosComCorrecaoAbono = useCallback(
    (dado: ResponseRelatorio[]) => {
      const copia: ResponseRelatorio[] = JSON.parse(JSON.stringify(dado));
      copia.forEach((d) => {
        if (
          d.DESC_DIA ||
          d.DESC_PERIODO ||
          d.DESC_FALTA ||
          d.DESC_ENTRADA ||
          d.DESC_PAUSA ||
          d.DESC_VOLTA ||
          d.DESC_SAIDA
        ) {
          // eslint-disable-next-line no-param-reassign
          d.HORAS_TRABALHADAS_MENOS_HORA_PAUSA = d.HORAS_A_TRABALHAR;
          // eslint-disable-next-line no-param-reassign
          d.DIFERENCA_EM_MINUTOS_HORAS_A_TRABALHAR_HORAS_TRABALHADAS = 0;
        }
      });
      setCopiaDados(copia);
    },
    [],
  );

  const preencheMotivoPdf = useCallback((dado: ResponseRelatorio): string => {
    let respostaMotivo = '';
    let soma = 0;
    if (dado.DESC_DIA) {
      soma += 1;
      if (soma === 1) {
        respostaMotivo = dado.DESC_DIA;
      }
    }
    if (dado.DESC_PERIODO) {
      soma += 1;
      if (soma === 1) {
        respostaMotivo = dado.DESC_PERIODO;
      }
    }
    if (dado.DESC_FALTA) {
      soma += 1;
      if (soma === 1) {
        respostaMotivo = dado.DESC_FALTA;
      }
    }
    if (dado.DESC_ENTRADA) {
      soma += 1;
      if (soma === 1) {
        respostaMotivo = dado.DESC_ENTRADA;
      }
    }
    if (dado.DESC_PAUSA) {
      soma += 1;
      if (soma === 1) {
        respostaMotivo = dado.DESC_PAUSA;
      }
    }
    if (dado.DESC_VOLTA) {
      soma += 1;
      if (soma === 1) {
        respostaMotivo = dado.DESC_VOLTA;
      }
    }
    if (dado.DESC_SAIDA) {
      soma += 1;
      if (soma === 1) {
        respostaMotivo = dado.DESC_SAIDA;
      }
    }
    if (soma > 1) {
      respostaMotivo = `*${respostaMotivo}`;
    }
    return respostaMotivo;
  }, []);

  const handleDownloadPDF = useCallback(() => {
    const doc = new jsPDF(); // eslint-disable-line

    doc.setFontSize(16);
    doc.setTextColor(0, 0, 0);
    doc.setFontType('bold');
    doc.text('Registro de Pontos', 103, 10, 'center');
    doc.setFontSize(11);
    // tamanho 207 tela a4 eixo horizontal
    let posY: number;
    posY = 14;
    // doc.text('Comprovante de Preenchimento Virtual', 103, posY, 'center');
    // inicio bloco de dados pessoais
    posY += 5;
    doc.text('Empresa:', 10, posY, 'left');
    doc.setFontType('normal');
    let quebraLinha = doc.splitTextToSize(`${dados[0].NOME_EMPRESA}`, 70);
    doc.setFontType('normal');
    doc.text(quebraLinha, 30, posY, { align: 'justify', maxWidth: 70 });
    if (quebraLinha.length > 1) {
      posY += 5 * (quebraLinha.length - 1);
    }
    posY += 5;
    doc.setFontType('bold');
    doc.text('Nome:', 10, posY, 'left');
    quebraLinha = doc.splitTextToSize(`${dados[0].NOME}`, 70);
    doc.setFontType('normal');
    doc.text(quebraLinha, 30, posY, { align: 'justify', maxWidth: 70 });
    if (quebraLinha.length > 1) {
      posY += 5 * (quebraLinha.length - 1);
    }
    posY += 5;
    doc.setFontType('bold');
    doc.text('CPF: ', 10, posY, 'left');
    doc.setFontType('normal');
    doc.text(`${dados[0].CPF}`, 30, posY, 'left');
    posY += 5;
    doc.setFontType('bold');
    doc.text('Email: ', 10, posY, 'left');
    quebraLinha = doc.splitTextToSize(`${dados[0].EMAIL}`, 70);
    doc.setFontType('normal');
    doc.text(quebraLinha, 30, posY, { align: 'justify', maxWidth: 70 });
    if (quebraLinha.length > 1) {
      posY += 5 * (quebraLinha.length - 1);
    }
    posY += 5;
    doc.setFontType('bold');
    doc.text('Telefone: ', 10, posY, 'left');
    doc.setFontType('normal');
    doc.text(`${dados[0].Telefone}`, 30, posY, 'left');
    // fim bloco dados pessoais
    // inicio bloco escala
    posY = 14;
    posY += 5;
    doc.setFontType('bold');
    doc.text('Escala:', 112, posY, 'left');
    doc.setFontType('normal');
    doc.setFontSize(11);
    doc.text(`${dados[0].ESCALA}`, 117, posY + 5, 'left');
    // fim bloco de escala
    // inicio bloco pontos
    posY = 49;
    posY += 15;
    doc.setFontType('bold');
    doc.setFontSize(13);
    doc.text('Dia', 20, posY, 'center');
    doc.text('Pontos', 68, posY, 'center');
    doc.text('Abono', 120, posY, 'center');
    doc.text('HAT', 159, posY, 'center');
    doc.text('HT', 171, posY, 'center');
    doc.text('EX', 183, posY, 'center');
    doc.text('FA', 195, posY, 'center');
    doc.setFontType('normal');
    doc.setFontSize(11);
    posY += 5;
    copiaDados.forEach((dado) => {
      doc.text(
        `${formatarDataAnoMesDia(dado.DATA_INICIAL_DIA_AGENDA)}`,
        10,
        posY,
        'left',
      );
      doc.text(`${formatarDiasDaSemana(dado.DIAS_SEMANA)}`, 35, posY, 'left');
      doc.text(
        `${
          dado.PONTO_LATITUDE_ENTRADA
            ? formatarHoraMin(dado.PONTO_HORA_ENTRADA)
            : '-'
        }`,
        50,
        posY,
        'center',
      );
      doc.text(
        `${
          dado.PONTO_HORA_PAUSA ? formatarHoraMin(dado.PONTO_HORA_PAUSA) : '-'
        }`,
        63,
        posY,
        'center',
      );
      doc.text(
        `${
          dado.PONTO_HORA_VOLTA ? formatarHoraMin(dado.PONTO_HORA_VOLTA) : '-'
        }`,
        76,
        posY,
        'center',
      );
      doc.text(
        `${
          dado.PONTO_HORA_SAIDA ? formatarHoraMin(dado.PONTO_HORA_SAIDA) : '-'
        }`,
        89,
        posY,
        'center',
      );
      doc.text(`${preencheMotivoPdf(dado)}`, 120, posY, {
        align: 'center',
        maxWidth: 50,
      });
      doc.text(
        dado.HORAS_A_TRABALHAR.slice(0, dado.HORAS_A_TRABALHAR.length - 3),
        159,
        posY,
        'center',
      ); // hat
      doc.text(
        `${
          dado.TEMPO_TRABALHADO_MS! > 0
            ? tempoMsParaHorasEMin(dado.TEMPO_TRABALHADO_MS!)
            : '-'
        }`,
        171,
        posY,
        'center',
      ); // ht
      doc.text(
        `${
          dado.DIFERENCA_TEMPO_MS! < 0
            ? tempoMsParaHorasEMin(dado.DIFERENCA_TEMPO_MS! * -1)
            : '-'
        }`,
        183,
        posY,
        'center',
      ); // ex
      doc.text(
        `${
          dado.DIFERENCA_TEMPO_MS! > 0
            ? tempoMsParaHorasEMin(dado.DIFERENCA_TEMPO_MS!)
            : '-'
        }`,
        195,
        posY,
        'center',
      ); // fa
      posY += 4;
    });
    // fim bloco ponto
    // psy 193
    posY = 205;
    doc.setFontSize(9);
    doc.text(`${textoAviso}`, 12, posY);
    doc.setFontSize(10);
    posY = 193;
    doc.rect(10, 207, 190, 85);
    doc.setFontType('bold');
    posY += 20;
    doc.text('HT:', 12, posY);
    doc.setFontType('normal');
    doc.text(tempoMsParaHorasEMin(horasTrabalhadas), 30, posY);
    posY += 5;
    doc.setFontType('bold');
    doc.text('HAT:', 12, posY);
    doc.setFontType('normal');
    doc.text(tempoMsParaHorasEMin(horasATrabalhar), 30, posY);
    posY += 5;
    doc.setFontType('bold');
    doc.text('FA:', 12, posY);
    doc.setFontType('normal');
    doc.text(tempoMsParaHorasEMin(horasFalta), 30, posY);
    posY += 5;
    doc.setFontType('bold');
    doc.text('EX:', 12, posY);
    doc.setFontType('normal');
    doc.text(tempoMsParaHorasEMin(horasExtra), 30, posY);
    posY += 8;
    // doc.setFontType('bold');
    // doc.text('HT: Horas Trabalhadas', 12, posY);
    // posY += 5;
    // doc.text('HAT: Horas A Trabalhadar', 12, posY);
    // posY += 5;
    // doc.text('FA: Faltas', 12, posY);
    // posY += 5;
    // doc.setFontType('normal');
    // doc.text(
    //   tempoMsParaHorasEMin(
    //     dados.map((a) => a.DIFERENCA_TEMPO_MS!).reduce((acc, a) => acc + a, 0),
    //   ),
    //   75,
    //   posY,
    // );
    posY += 13;
    doc.setFontType('bold');
    doc.setFontSize(10);
    doc.text(
      'Reconheço como verdadeiros meus horários de entrada e saída contidos neste relatório.',
      12,
      posY,
      { align: 'justify', maxWidth: 140 },
    );
    posY += 8;
    doc.setFontType('bold');
    doc.setFontSize(9.5);
    posY += 2;
    doc.text(
      `Como responsável, reconheço como verdadeiros os horários de entrada e saída do funcionário.`,
      12,
      posY,
      { align: 'justify', maxWidth: 140 },
    );
    posY += 25;
    doc.setFontSize(10);
    doc.line(12, posY + 3, 100, posY + 3);
    doc.setFontType('normal');
    doc.text('Assinatura do Funcionário', 56, posY + 6, 'center');
    doc.setFontType('bold');
    doc.line(112, posY + 3, 199, posY + 3);
    doc.setFontType('normal');
    doc.text('Assinatura do Responsável', 156, posY + 6, 'center');

    doc.save(`${dados[0].NOME}_ponto.pdf`);
  }, [
    copiaDados,
    dados,
    horasATrabalhar,
    horasExtra,
    horasFalta,
    horasTrabalhadas,
    preencheMotivoPdf,
  ]);

  useEffect(() => {
    copiaDadosComCorrecaoAbono(dados);
  }, [copiaDadosComCorrecaoAbono, dados]);

  // useEffect(() => {
  //   let hat = 0; // horas a trabalhadas
  //   let ht = 0; // horas trabalhadas
  //   let hf = 0; // horas de falta
  //   let he = 0; // horas de Extra
  //   // console.log(dados);
  //   copiaDados.forEach((dado) => {
  //     hat += formatarStringToNumber(dado.HORAS_A_TRABALHAR);
  //     ht += formatarStringToNumber(dado.HORAS_TRABALHADAS_MENOS_HORA_PAUSA);
  //     if (dado.DIFERENCA_EM_MINUTOS_HORAS_A_TRABALHAR_HORAS_TRABALHADAS > 0) {
  //       he += dado.DIFERENCA_EM_MINUTOS_HORAS_A_TRABALHAR_HORAS_TRABALHADAS;
  //     } else {
  //       hf += Math.abs(
  //         dado.DIFERENCA_EM_MINUTOS_HORAS_A_TRABALHAR_HORAS_TRABALHADAS,
  //       );
  //     }
  //     if (
  //       dado.HORAS_TRABALHADAS_MENOS_HORA_PAUSA === null &&
  //       !(
  //         dado.DIAS_SEMANA.toLowerCase() === 'sábado' ||
  //         dado.DIAS_SEMANA.toLowerCase() === 'domingo'
  //       )
  //     ) {
  //       hf += CalcularFalta(
  //         dado.DIFERENCA_EM_MINUTOS_HORAS_A_TRABALHAR_HORAS_TRABALHADAS,
  //         dado.HORAS_TRABALHADAS_MENOS_HORA_PAUSA,
  //         dado.DATA_INICIAL_DIA_AGENDA,
  //         dado.HORAS_A_TRABALHAR,
  //       );
  //     }
  //   });
  //   setHorasATrabalhar(hat);
  //   setHorasTrabalhadas(ht);
  //   setHorasFalta(hf);
  //   setHorasExtra(he);
  // }, [copiaDados]);

  return (
    <ContainerModal id="modal" onClick={(e) => handleOutSideClick(e)}>
      {dados.length > 0 && (
        <Container>
          <ContainerButton>
            <button type="button" onClick={onClose}>
              X
            </button>
          </ContainerButton>
          <ContainerHeader>
            <p>Registro de Pontos</p>
          </ContainerHeader>
          <ContainerHeaderDados>
            <ContainerPessoais>
              <table>
                <tbody>
                  <tr>
                    <td>Empresa:</td>
                    <td>{dados[0].NOME_EMPRESA}</td>
                  </tr>
                  <tr>
                    <td>Nome:</td>
                    <td>{dados[0].NOME}</td>
                  </tr>
                  <tr>
                    <td>CPF:</td>
                    <td>{dados[0].CPF}</td>
                  </tr>
                  <tr>
                    <td>Email:</td>
                    <td>{dados[0].EMAIL}</td>
                  </tr>
                  <tr>
                    <td>Telefone:</td>
                    <td>{dados[0].Telefone}</td>
                  </tr>
                </tbody>
              </table>
            </ContainerPessoais>
            <ContainerPessoais>
              <table>
                <tbody>
                  <tr>
                    <td>Escala:</td>
                    <td>{dados[0].ESCALA}</td>
                  </tr>
                </tbody>
              </table>
            </ContainerPessoais>
          </ContainerHeaderDados>
          <ContainerBodyDados>
            <table>
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Dia</th>
                  <th colSpan={4}>Pontos</th>
                  <th>Abono</th>
                  <th>HAT</th>
                  <th>HT</th>
                  <th>EX</th>
                  <th>FA</th>
                </tr>
              </thead>
              <tbody>
                {dados.map((res, index) => (
                  <tr key={`${res.DATA_INICIAL_DIA_AGENDA + index}`}>
                    <td>
                      {formatarDataAnoMesDia(res.DATA_INICIAL_DIA_AGENDA)}
                    </td>
                    <td>{res.DIAS_SEMANA.toLowerCase()}</td>
                    {
                      // <td>{`${res.PONTO_HORA_ENTRADA} ${res.PONTO_HORA_PAUSA} ${res.PONTO_HORA_VOLTA} ${res.PONTO_HORA_SAIDA}`}</td>
                    }
                    <td>
                      {res.PONTO_HORA_ENTRADA
                        ? formatarHoraMin(res.PONTO_HORA_ENTRADA)
                        : '-'}
                    </td>
                    <td>
                      {res.PONTO_HORA_PAUSA
                        ? formatarHoraMin(res.PONTO_HORA_PAUSA)
                        : '-'}
                    </td>
                    <td>
                      {res.PONTO_HORA_VOLTA
                        ? formatarHoraMin(res.PONTO_HORA_VOLTA)
                        : '-'}
                    </td>
                    <td>
                      {res.PONTO_HORA_SAIDA
                        ? formatarHoraMin(res.PONTO_HORA_SAIDA)
                        : '-'}
                    </td>
                    <td>{preencheMotivoPdf(res)}</td>
                    <td>
                      {res.HORAS_A_TRABALHAR.slice(
                        0,
                        res.HORAS_A_TRABALHAR.length - 3,
                      )}
                    </td>
                    <td>
                      {res.TEMPO_TRABALHADO_MS! > 0
                        ? tempoMsParaHorasEMin(res.TEMPO_TRABALHADO_MS!)
                        : '-'}
                    </td>
                    <td>
                      {res.DIFERENCA_TEMPO_MS! < 0
                        ? tempoMsParaHorasEMin(res.DIFERENCA_TEMPO_MS! * -1)
                        : '-'}
                    </td>
                    <td>
                      {res.DIFERENCA_TEMPO_MS! > 0
                        ? tempoMsParaHorasEMin(res.DIFERENCA_TEMPO_MS!)
                        : '-'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </ContainerBodyDados>
          <ContainerDadosDetalhes>
            <p>{textoAviso}</p>
            <ContainerBoxBorder>
              <DadosDetalhes>
                <table>
                  <tbody>
                    <tr>
                      <td className="tdColumn">HT - Horas trabalhadas:</td>
                      <td style={{ minWidth: 55 }}>
                        {tempoMsParaHorasEMin(
                          dados
                            .map((a) => a.TEMPO_TRABALHADO_MS!)
                            .reduce((acc, a) => acc + a, 0),
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>HAT - Horas A Trabalhar:</td>
                      <td>
                        {tempoMsParaHorasEMin(
                          dados
                            .map((a) =>
                              horasATrabalharEmMS(a.HORAS_A_TRABALHAR!),
                            )
                            .reduce((acc, a) => acc + a, 0),
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>FA - Faltas:</td>
                      <td>
                        {tempoMsParaHorasEMin(
                          dados
                            .map((a) =>
                              a.DIFERENCA_TEMPO_MS! < 0
                                ? a.DIFERENCA_TEMPO_MS! * -1
                                : 0,
                            )
                            .reduce((acc, a) => acc + a, 0),
                        )}
                      </td>
                    </tr>
                    {/* <tr> */}
                    {/*  <td className="tdColumn">Total a Trabalhar:</td> */}
                    {/*  <td style={{ minWidth: 55 }}> */}
                    {/*    {formatarMinEmHoraMin(horasATrabalhadas)} */}
                    {/*  </td> */}
                    {/* </tr> */}
                    {/* <tr> */}
                    {/*  <td>Dias Completos de Falta:</td> */}
                    {/*  <td> */}
                    {/*    {CalcularFaltaDiasCompleto( */}
                    {/*      dados[0].HORAS_A_TRABALHAR, */}
                    {/*      horasFalta, */}
                    {/*    )} */}
                    {/*  </td> */}
                    {/* </tr> */}
                    <tr>
                      <td className="tdColumn">Total a Trabalhar - Faltas:</td>
                      <td>
                        {tempoMsParaHorasEMin(
                          dados
                            .map((a) => a.DIFERENCA_TEMPO_MS!)
                            .reduce((acc, a) => acc + a, 0),
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </DadosDetalhes>
            </ContainerBoxBorder>
          </ContainerDadosDetalhes>
          <ContainerButtonPDF>
            <button type="button" onClick={handleDownloadPDF}>
              Gerar PDF
            </button>
          </ContainerButtonPDF>
        </Container>
      )}
      {!(dados.length > 0) && (
        <Container>
          <ContainerButton>
            <button type="button" onClick={onClose}>
              X
            </button>
          </ContainerButton>
          <ContainerHeader>
            <p>Não existe registro para esse mês.</p>
          </ContainerHeader>
        </Container>
      )}
    </ContainerModal>
  );
};

export default Modal;
