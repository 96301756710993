import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  flex: 1;
  max-width: auto;
  align-items: center;
  max-height: 100vh;
`;
export const Content = styled.div`
  width: 90%;
  margin: 0px auto;
  margin-top: 15px;
  background-color: #3e3946;
  overflow-x: auto;
  border-radius: 5px;
  display: flex;
  justify-content: center;

  > div {
    padding: 2rem;
    width: 100%;
    max-width: 360px;
  }

  @media screen and (max-width: 400px) {
    > div {
      padding: 2rem 0.8rem;
    }
  }
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-70px);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
`;
export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  animation: ${appearFromLeft} 1s;

  img {
    height: 100px;
    object-fit: contain;
  }

  form {
    margin: 80px 0;
    width: 100%;
    max-width: 340px;
    text-align: center;

    h1 {
      margin-bottom: 24px;
    }

    a {
      color: #f4ede8;
      display: block;
      margin-top: 24px;
      text-decoration: none;
      transition: color 0.2s;

      &:hover {
        color: ${shade(0.2, '#f4ede8')};
      }
    }
  }

  > a {
    color: #ffffff;
    display: block;
    margin-top: 24px;
    text-decoration: none;
    transition: color 0.2s;

    svg {
      margin-right: 16px;
    }

    &:hover {
      color: ${shade(0.2, '#f1f1f1')};
    }

    display: flex;
    align-items: center;
  }
`;

export const InfoUser = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 0;
  gap: 0.75rem;
  width: 100%;
  text-align: center;

  > img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }

  span {
    margin-top: 30px;
    font-size: 0.75rem;
  }
`;

export const CardUser = styled.div`
  border: 1px solid #cccccc;
  border-radius: 1rem;
  width: 100%;

  > div {
    display: flex;
    padding: 4px 12px;
    align-items: center;
    gap: 1rem;

    p {
      font-size: 1rem;
      margin: 0px;
    }
  }
`;

const ButtonBase = styled.button`
  height: 40px;
  border-radius: 10px;
  border: none;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;

  &:hover {
    filter: brightness(1.2);
  }
`;

export const DeleteButton = styled(ButtonBase)`
  background-color: #c53030;
  color: #fff;
`;
