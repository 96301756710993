import React, { useEffect, useState, useCallback, useMemo } from 'react';
import {
  FiEye,
  FiCheckCircle,
  FiXCircle,
  FiFileText,
  FiSave,
} from 'react-icons/fi';
// @ts-ignore
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import { Loading } from '../../components/Loading';
import api from '../../services/api';
import Search from '../../components/Search';
import HeaderTable from '../../components/HeaderTable';
import { maskCpf } from '../../components/Input/mask';
import ModalConexao from '../../components/ModalConexao';
import DrawerApp from '../../components/Drawer';

import {
  Container,
  ContainerSearch,
  ContainerTable,
  ContainerTableValidar,
  Content,
  ButtonBorder,
  ButtonBorderIconTrue,
  ButtonBorderIconFalse,
  ContainerPendente,
  ContainerVisualizarVazio,
} from './styles';

interface ValidacaoAdmissao {
  IdUSER: number;
  NOME: string;
  CPF: string;
  DOCUMENTACAO: string;
  DOCUMENTO_ACEITO: boolean;
  ID_DOC: number;
  LINK_IMAGEM: string;
  TELEFONE: string;
  CODIGO_VALIDACAO: string;
  MOTIVO_REJEICAO_RH: string;
}

const setores = [
  { valor: 'Departamento Pessoal', id: 14, NivelAcesso: 1 },
  { valor: 'Todos', id: 15, NivelAcesso: 1 },
];

const DocumentosAdmissao: React.FC = () => {
  const { userAcesso } = useAuth();
  const history = useHistory();
  const [search, setSearch] = useState('');
  const [searchCPF, setSearchCPF] = useState('');
  const [response, setResponse] = useState<ValidacaoAdmissao[]>([]);
  const [copiaResponse, setCopiaResponse] = useState<ValidacaoAdmissao[]>([]);
  const [dataValidacao, setDataValidacao] = useState<ValidacaoAdmissao[]>([]);
  const [loading, setLoading] = useState(false);
  const [visualizarValidacao, setVisualizarValidacao] = useState(false);
  const [dadoIcon, setDadoIcon] = useState(false);
  const [docId, setDocId] = useState<number>(0);
  const [userId, setUserId] = useState<number>(0);
  const [visualizarVazio, setVisualizarVazio] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const headers = [
    { name: 'Documento', field: 'doc' },
    { name: 'Arquivo', field: 'arq' },
    { name: 'Validar', field: 'validar' },
    { name: 'Motivo', field: 'motivo' },
  ];

  const headersValidacao = [
    { name: 'Nome', field: 'name' },
    { name: 'CPF', field: 'cpf' },
    { name: 'Validar Documentação', field: 'validar' },
  ];

  useEffect(() => {
    let semAcesso = true;
    let access;
    userAcesso.forEach((user) => {
      access = setores.filter(
        (setor) =>
          setor.id === user.SetorID && user.NivelACesso >= setor.NivelAcesso,
      );
      if (access.length > 0) {
        semAcesso = false;
      }
    });
    if (semAcesso) {
      history.push('/dashboard');
      Swal.fire({
        icon: 'info',
        title:
          'Seu nível de acesso não tem permissão para acessar essa página!',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
    } else {
      setLoading(true);
      api
        .get(`/cadastroDocApp`)
        .then((data) => {
          setResponse(data.data);
          // console.log(data.data);
          setCopiaResponse(JSON.parse(JSON.stringify(data.data)));
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          if (navigator.onLine) {
            Swal.fire({
              icon: 'info',
              title: 'Erro ao carregar dados, por favor atualize a página!',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            });
          }
        });
    }
  }, [history, refresh, userAcesso]);

  useMemo(() => {
    dataValidacao.forEach((data: ValidacaoAdmissao) => {
      if (data.ID_DOC === docId) {
        // eslint-disable-next-line no-param-reassign
        data.DOCUMENTO_ACEITO = dadoIcon;
      }
      return data;
    });
    setDataValidacao(dataValidacao);
  }, [dataValidacao, docId, dadoIcon]);

  const handlePreencher = useCallback(
    (dados: ValidacaoAdmissao) => {
      // setNome(!dados.nome);
      let computedResponses: ValidacaoAdmissao[] = [];
      computedResponses = response;
      computedResponses = computedResponses.filter(
        (cpf) => cpf.CPF === dados.CPF,
      );
      setDataValidacao(computedResponses);
    },
    [response],
  );

  const handlePreencherESalvarDados = useCallback(async () => {
    const arrayDocId: number[] = [];
    const arrayDocAceito: number[] = [];
    const arrayMotivo: string[] = [];
    let motivoVazio = false;
    dataValidacao.forEach((data: ValidacaoAdmissao) => {
      // console.log(data);
      // console.log(data.MOTIVO_REJEICAO_RH);
      copiaResponse.forEach((dados) => {
        if (
          data.DOCUMENTO_ACEITO !== null &&
          (data.DOCUMENTO_ACEITO !== dados.DOCUMENTO_ACEITO ||
            data.MOTIVO_REJEICAO_RH !== dados.MOTIVO_REJEICAO_RH) &&
          data.ID_DOC === dados.ID_DOC &&
          data.CPF === dados.CPF
        ) {
          arrayDocId.push(data.ID_DOC);
          arrayDocAceito.push(+data.DOCUMENTO_ACEITO);
          if (
            (data.MOTIVO_REJEICAO_RH === '' ||
              data.MOTIVO_REJEICAO_RH === null) &&
            (data.DOCUMENTO_ACEITO === false || data.DOCUMENTO_ACEITO === null)
          ) {
            motivoVazio = true;
          } else {
            if (data.DOCUMENTO_ACEITO === true) {
              // eslint-disable-next-line no-param-reassign
              data.MOTIVO_REJEICAO_RH = '';
            }
            arrayMotivo.push(data.MOTIVO_REJEICAO_RH);
          }
        }
      });
    });
    if (motivoVazio) {
      Swal.fire({
        icon: 'error',
        title: 'Preencha o Motivo!',
        showConfirmButton: false,
        timer: 1500,
      });
      motivoVazio = false;
      return;
    }
    try {
      setLoading(true);
      setVisualizarValidacao(false);
      await api
        .patch('cadastroDocApp/aprovaDoc/', {
          user: userId,
          aprovado: arrayDocAceito,
          idDoc: arrayDocId,
          motivo_rejeicao_rh: arrayMotivo,
        })
        .then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Validação salva com Sucesso!',
            showConfirmButton: false,
            timer: 1500,
          });
          setVisualizarVazio(false);
          setLoading(false);
          setRefresh(!refresh);
        })
        .catch(() => {
          Swal.fire({
            icon: 'error',
            title: 'Erro, ao salvar a validação.',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          });
          setLoading(false);
          setVisualizarValidacao(true);
        });
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Erro, ao salvar a validação. Tente Novamente.',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
      setLoading(false);
      setVisualizarValidacao(true);
    }
    setDataValidacao(dataValidacao);
  }, [dataValidacao, userId, copiaResponse, refresh]);

  function arrayUnique(data: ValidacaoAdmissao[]): ValidacaoAdmissao[] {
    const result: ValidacaoAdmissao[] = [];
    const map = new Map();
    data.forEach((item) => {
      if (!map.has(item.CPF) && item.CPF !== null) {
        map.set(item.CPF, true);
        result.push({
          IdUSER: item.IdUSER,
          NOME: item.NOME,
          CPF: item.CPF,
          DOCUMENTACAO: item.DOCUMENTACAO,
          DOCUMENTO_ACEITO: item.DOCUMENTO_ACEITO,
          ID_DOC: item.ID_DOC,
          LINK_IMAGEM: item.LINK_IMAGEM,
          TELEFONE: item.TELEFONE,
          CODIGO_VALIDACAO: item.CODIGO_VALIDACAO,
          MOTIVO_REJEICAO_RH: item.MOTIVO_REJEICAO_RH,
        });
      }
    });
    return result;
  }

  const responseData = useMemo(() => {
    let computedResponses: ValidacaoAdmissao[] = [];
    computedResponses = arrayUnique(response);

    if (search) {
      computedResponses = computedResponses.filter((res: ValidacaoAdmissao) =>
        res.NOME.toLowerCase().includes(search.toLowerCase()),
      );
    }
    if (searchCPF) {
      let CPF: string = searchCPF.replace('.', '');
      CPF = CPF.replace('.', '');
      CPF = CPF.replace('-', '');
      computedResponses = computedResponses.filter((res: ValidacaoAdmissao) =>
        res.CPF.toLowerCase().includes(CPF.toLowerCase()),
      );
    }

    return computedResponses;
  }, [response, search, searchCPF]);

  const handlePreencherMotivo = useCallback(
    (dado: string, dadosCadatrais: ValidacaoAdmissao) => {
      // eslint-disable-next-line no-param-reassign
      dadosCadatrais.MOTIVO_REJEICAO_RH = dado;
    },
    [],
  );

  const handleErrorVisualizarValidacao = useCallback(() => {
    Swal.fire({
      icon: 'info',
      title: 'Por favor, cancele a visualização da validação de documentos!',
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
    });
  }, []);

  return (
    <>
      <ModalConexao />
      <DrawerApp />
      {/* <Header /> */}
      <Container>
        <ContainerSearch>
          <Search
            onSearch={(value: string) => {
              setSearch(value);
            }}
            nomePlaceHolder="Buscar Nome"
          />
          <Search
            onSearch={(value: string) => {
              setSearchCPF(value);
            }}
            nomePlaceHolder="Buscar CPF"
          />
          <ContainerPendente>
            <ReactHTMLTableToExcel
              id="export-excel"
              className="btn"
              table="pendentes"
              filename="pre-cadastro-admissao"
              sheet="tablexls"
              buttonText="Exportar Excel"
            />
          </ContainerPendente>
        </ContainerSearch>
        <ContainerTable>
          <table id="pendentes">
            <HeaderTable headers={headersValidacao} />
            <tbody>
              {responseData.map((res: ValidacaoAdmissao) => (
                <tr key={res.CPF}>
                  <td style={{ minWidth: 300 }}>{res.NOME}</td>
                  <td>{maskCpf(res.CPF)}</td>
                  <td>
                    <button
                      type="button"
                      onClick={() => {
                        if (visualizarValidacao) {
                          handleErrorVisualizarValidacao();
                        } else {
                          setVisualizarValidacao(true);
                          setUserId(res.IdUSER);
                          handlePreencher(res);
                          if (res.DOCUMENTACAO === null) {
                            setVisualizarVazio(true);
                          } else {
                            setVisualizarVazio(false);
                          }
                        }
                      }}
                    >
                      <FiEye />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </ContainerTable>
        {visualizarValidacao && (
          <Container>
            {visualizarVazio && (
              <ContainerVisualizarVazio>
                <h3>Não existe documento para esse usuário.</h3>
              </ContainerVisualizarVazio>
            )}
            {!visualizarVazio && (
              <>
                <ContainerTableValidar>
                  <table>
                    <HeaderTable headers={headers} />
                    <tbody>
                      {dataValidacao.map((res: ValidacaoAdmissao) => (
                        <tr key={res.ID_DOC}>
                          <td>{`${res.ID_DOC} - ${res.DOCUMENTACAO}`}</td>
                          <td style={{ width: 30 }}>
                            <a
                              href={res.LINK_IMAGEM}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <FiFileText />
                            </a>
                          </td>
                          <td style={{ minWidth: 150 }}>
                            <button
                              type="button"
                              onClick={() => {
                                setDadoIcon(false);
                                setDocId(res.ID_DOC);
                              }}
                            >
                              {(res.DOCUMENTO_ACEITO === null ||
                                res.DOCUMENTO_ACEITO === true) && (
                                <FiXCircle
                                  style={{
                                    color: '#737373',
                                    marginTop: -1,
                                    marginLeft: -1,
                                  }}
                                  size={25}
                                />
                              )}
                              {!(
                                res.DOCUMENTO_ACEITO === null ||
                                res.DOCUMENTO_ACEITO === true
                              ) && (
                                <ButtonBorderIconFalse>
                                  <FiXCircle
                                    style={{ color: '#f2f2f2' }}
                                    size={25}
                                  />
                                </ButtonBorderIconFalse>
                              )}
                            </button>
                            <button
                              type="button"
                              onClick={() => {
                                setDadoIcon(true);
                                setDocId(res.ID_DOC);
                              }}
                            >
                              {res.DOCUMENTO_ACEITO && (
                                <ButtonBorderIconTrue>
                                  <FiCheckCircle
                                    style={{ color: '#f2f2f2' }}
                                    size={25}
                                  />
                                </ButtonBorderIconTrue>
                              )}
                              {!res.DOCUMENTO_ACEITO && (
                                <FiCheckCircle
                                  style={{
                                    color: '#737373',
                                    marginTop: -1,
                                    marginLeft: -1,
                                  }}
                                  size={25}
                                />
                              )}
                            </button>
                          </td>
                          {(res.DOCUMENTO_ACEITO ||
                            res.DOCUMENTO_ACEITO === null) && (
                            <td style={{ minWidth: 200 }} />
                          )}
                          {res.DOCUMENTO_ACEITO === false && (
                            <td style={{ minWidth: 200 }}>
                              <textarea
                                rows={3}
                                maxLength={255}
                                style={{ resize: 'none', minWidth: 300 }}
                                defaultValue={res.MOTIVO_REJEICAO_RH}
                                onBlur={(e) =>
                                  handlePreencherMotivo(e.target.value, res)}
                              />
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </ContainerTableValidar>
                <Content>
                  <ButtonBorder>
                    <button
                      type="button"
                      onClick={() => {
                        setVisualizarValidacao(false);
                      }}
                    >
                      Cancelar
                    </button>
                  </ButtonBorder>
                  <ButtonBorder>
                    <button
                      type="button"
                      onClick={() => {
                        handlePreencherESalvarDados();
                      }}
                    >
                      <FiSave />
                      Salvar
                    </button>
                  </ButtonBorder>
                </Content>
              </>
            )}
          </Container>
        )}
        {loading && <Loading />}
      </Container>
    </>
  );
};

export default DocumentosAdmissao;
