import jsPDF from 'jspdf';
import { ResponseGet } from '../Interface/index';
import { maskCpf } from '../../../components/Input/mask';
import {
  formatarDataAnoMesDia,
  formatarHoraMin,
} from '../../../components/Formulas/formulas';

const filterUserResponse = (
  data: ResponseGet,
  dadosCompetos: ResponseGet[],
): ResponseGet[] => {
  let computedFilter: ResponseGet[] = dadosCompetos;
  computedFilter = computedFilter.filter(
    (res) => res.CPFSEMFORMATO === data.CPFSEMFORMATO,
  );
  return computedFilter;
};

export const exportPdfPontoDetalhado = (
  dados: ResponseGet[],
  dadosCompletos: ResponseGet[],
  empresa: string,
  dataInicial: string,
  dataFinal: string,
): void => {
  const filial = empresa.split('- ');
  const doc = new jsPDF('landscape'); // eslint-disable-line
  doc.setFontSize(16);
  doc.setTextColor(0, 0, 0);
  doc.setFontType('bold');
  doc.text(`Registro de Ponto - ${filial[1]}`, 147, 10, 'center');
  doc.setFontSize(12);
  doc.text(`Período: ${dataInicial} à ${dataFinal}`, 147, 15, 'center');
  // tamanho 207 / 294 tela a4
  let posY: number;
  let quebraLinha;
  let psY = 0;
  let pY = 0;
  let yTabela = 0;
  let contaLinha = 0;
  doc.setFontSize(10);
  posY = 20;
  posY += 5;
  contaLinha = 0;
  doc.setLineWidth(0.5);
  dados.forEach((dado) => {
    const responseUser = filterUserResponse(dado, dadosCompletos);
    doc.setFontSize(12);
    doc.setFontType('bold');
    doc.rect(7, posY - 6, 280, 9);
    doc.text('Empresa', 30, posY, 'left');
    doc.text('Nome', 85, posY, 'left');
    doc.text('CPF', 138, posY, 'left');
    doc.text('Contrato', 170, posY, 'left');
    doc.text('Posto', 215, posY, 'left');
    doc.text('Função', 260, posY, 'left');
    doc.setFontSize(10);
    doc.setFontType('normal');
    psY = posY + 10;
    quebraLinha = doc.splitTextToSize(`${dado.NOME_CLIENTE}`, 50);
    doc.text(quebraLinha, 10, psY, { align: 'left', maxWidth: 50 });
    contaLinha = quebraLinha.length;
    quebraLinha = doc.splitTextToSize(`${dado.NOME}`, 60);
    doc.text(quebraLinha, 65, psY, { maxWidth: 60 });
    if (quebraLinha.length > contaLinha) {
      contaLinha = quebraLinha.length;
    }
    doc.text(`${maskCpf(dado.CPFSEMFORMATO)}`, 130, psY, { maxWidth: 30 });
    quebraLinha = doc.splitTextToSize(`${dado.CONTRATO}`, 30);
    doc.text(quebraLinha, 165, psY, { maxWidth: 30 });
    if (quebraLinha.length > contaLinha) {
      contaLinha = quebraLinha.length;
    }
    quebraLinha = doc.splitTextToSize(`${dado.POSTO}`, 50);
    doc.text(quebraLinha, 200, psY, { maxWidth: 50 });
    if (quebraLinha.length > contaLinha) {
      contaLinha = quebraLinha.length;
    }
    quebraLinha = doc.splitTextToSize(`${dado.FUNCAO}`, 30);
    doc.text(quebraLinha, 255, psY, { maxWidth: 30 });
    if (quebraLinha.length > contaLinha) {
      contaLinha = quebraLinha.length;
    }
    posY = 5 * contaLinha + psY;
    doc.line(7, posY - 4, 287, posY - 4);
    doc.setFontSize(10);
    doc.setFontType('bold');
    doc.text('Data Escala', 15, posY, 'left');
    doc.text('Latitude Ponto ', 45, posY, 'left');
    doc.text('Longitude Ponto', 75, posY, 'left');
    doc.text('Tipo', 112, posY, 'left');
    doc.text('Data', 135, posY, 'left');
    doc.text('Hora', 157, posY, 'left');
    doc.text('Foto', 175, posY, 'left');
    doc.text('Latitude', 203, posY, 'left');
    doc.text('Longitude', 231, posY, 'left');
    doc.text('Diferença', 260, posY, 'left');
    doc.setFontType('normal');
    doc.setDrawColor(0, 102, 0);
    doc.rect(14, posY - 3.5, 266, 4.5);
    doc.setDrawColor(0);
    yTabela = posY + 1;
    responseUser.forEach((user, index) => {
      pY = posY + 5;
      doc.setFontType('normal');
      doc.text(
        `${formatarDataAnoMesDia(user.DATA_INICIAL_DIA_AGENDA)}`,
        15,
        pY,
      );
      doc.text(`${user.LOCAL_LATITUDE}`, 57, pY, {
        align: 'center',
        maxWidth: 28,
      });
      doc.text(`${user.LOCAL_LONGITUDE}`, 89, pY, {
        align: 'center',
        maxWidth: 28,
      });
      doc.setFontType('bold');
      doc.text(`Abono`, 20, pY + 10, {
        align: 'center',
        maxWidth: 15,
      });
      doc.text(
        `${user.ABONO || user.FALTA_ABONADA ? 'Tipo Abono' : ''}`,
        40,
        pY + 10,
        {
          align: 'center',
          maxWidth: 25,
        },
      );
      doc.text(
        `${user.ABONO || user.FALTA_ABONADA ? 'Motivo' : ''}`,
        75,
        pY + 10,
        {
          align: 'center',
          maxWidth: 25,
        },
      );
      // bloco para imprimir o abono, tipo e motivo
      doc.setFontType('normal');
      doc.text(
        `${user.ABONO || user.FALTA_ABONADA ? 'Sim' : 'Não'}`,
        20,
        pY + 15,
        {
          align: 'center',
          maxWidth: 15,
        },
      );
      if (user.FALTA_ABONADA || user.DIA_ABONADO || user.PERIODO_ABONADO) {
        // imprime essa primeira e as outras so imprime se cumprir a condicao.
        doc.text('Dia', 40, pY + 15, {
          align: 'center',
          maxWidth: 25,
        });
        doc.text(`${user.FALTA_ABONADA ? user.DESC_FALTA : ''}`, 75, pY + 15, {
          align: 'center',
          maxWidth: 40,
        });
        doc.text(`${user.DIA_ABONADO ? user.DESC_DIA : ''}`, 75, pY + 15, {
          align: 'center',
          maxWidth: 40,
        });
        doc.text(
          `${user.PERIODO_ABONADO ? user.DESC_PERIODO : ''}`,
          75,
          pY + 15,
          {
            align: 'center',
            maxWidth: 40,
          },
        );
      }
      if (
        user.ENTRADA_ABONADA ||
        user.PAUSA_ABONADA ||
        user.VOLTA_ABONADA ||
        user.SAIDA_ABONADA
      ) {
        doc.text(
          `${user.ENTRADA_ABONADA ? 'Entrada' : ''} ${
            user.PAUSA_ABONADA ? 'Pausa' : ''
          } ${user.VOLTA_ABONADA ? 'Volta' : ''} ${
            user.SAIDA_ABONADA ? 'Saída' : ''
          }`,
          40,
          pY + 15,
          {
            align: 'center',
            maxWidth: 25,
          },
        );
        doc.text(
          `${user.ENTRADA_ABONADA ? user.DESC_ENTRADA : ''} ${
            user.PAUSA_ABONADA && !user.ENTRADA_ABONADA ? user.DESC_PAUSA : ''
          } ${
            user.VOLTA_ABONADA && !(user.ENTRADA_ABONADA || user.PAUSA_ABONADA)
              ? user.DESC_VOLTA
              : ''
          } ${
            user.SAIDA_ABONADA &&
            !(user.ENTRADA_ABONADA || user.PAUSA_ABONADA || user.VOLTA_ABONADA)
              ? user.DESC_SAIDA
              : ''
          }`,
          75,
          pY + 15,
          {
            align: 'center',
            maxWidth: 40,
          },
        );
      }
      // fim do bloco do abono

      doc.text(`Entrada`, 110, pY);
      doc.text(
        `${
          user.PONTO_DATA_ENTRADA
            ? formatarDataAnoMesDia(user.PONTO_DATA_ENTRADA)
            : '-'
        }`,
        139,
        pY,
        { align: 'center', maxWidth: 20 },
      );
      doc.text(
        `${
          user.PONTO_HORA_ENTRADA
            ? formatarHoraMin(user.PONTO_HORA_ENTRADA)
            : '-'
        }`,
        162,
        pY,
        { align: 'center', maxWidth: 15 },
      );
      if (user.PONTO_LINK_FOTO_ENTRADA) {
        doc.setTextColor(0, 0, 255);
        doc.text('____', 175, pY);
      }
      doc.textWithLink(
        user.PONTO_LINK_FOTO_ENTRADA ? 'Foto' : '-',
        user.PONTO_LINK_FOTO_ENTRADA ? 175 : 178,
        pY,
        {
          url: user.PONTO_LINK_FOTO_ENTRADA,
        },
      );
      doc.setTextColor(0);
      doc.text(
        `${user.PONTO_LATITUDE_ENTRADA ? user.PONTO_LATITUDE_ENTRADA : '-'}`,
        210,
        pY,
        { align: 'center', maxWidth: 28 },
      );
      doc.text(
        `${user.PONTO_LONGITUDE_ENTRADA ? user.PONTO_LONGITUDE_ENTRADA : '-'}`,
        239,
        pY,
        { align: 'center', maxWidth: 28 },
      );
      doc.text(
        `${
          user.PONTO_DIFERENCA_METROS_ENTRADA
            ? `${user.PONTO_DIFERENCA_METROS_ENTRADA} m`
            : '-'
        }`,
        268,
        pY,
        { align: 'center', maxWidth: 15 },
      );
      pY += 5;
      doc.text(`Pausa`, 110, pY);
      doc.text(
        `${
          user.PONTO_DATA_PAUSA
            ? formatarDataAnoMesDia(user.PONTO_DATA_PAUSA)
            : '-'
        }`,
        139,
        pY,
        { align: 'center', maxWidth: 20 },
      );
      doc.text(
        `${
          user.PONTO_HORA_PAUSA ? formatarHoraMin(user.PONTO_HORA_PAUSA) : '-'
        }`,
        162,
        pY,
        { align: 'center', maxWidth: 15 },
      );
      if (user.PONTO_LINK_FOTO_PAUSA) {
        doc.setTextColor(0, 0, 255);
        doc.text('____', 175, pY);
      }
      doc.textWithLink(
        user.PONTO_LINK_FOTO_PAUSA ? 'Foto' : '-',
        user.PONTO_LINK_FOTO_PAUSA ? 175 : 178,
        pY,
        {
          url: user.PONTO_LINK_FOTO_PAUSA,
        },
      );
      doc.setTextColor(0);
      doc.text(
        `${user.PONTO_LATITUDE_PAUSA ? user.PONTO_LATITUDE_PAUSA : '-'}`,
        210,
        pY,
        { align: 'center', maxWidth: 28 },
      );
      doc.text(
        `${user.PONTO_LONGITUDE_PAUSA ? user.PONTO_LONGITUDE_PAUSA : '-'}`,
        239,
        pY,
        { align: 'center', maxWidth: 28 },
      );
      doc.text(
        `${
          user.PONTO_DIFERENCA_METROS_PAUSA
            ? `${user.PONTO_DIFERENCA_METROS_PAUSA} m`
            : '-'
        }`,
        268,
        pY,
        { align: 'center', maxWidth: 15 },
      );
      pY += 5;
      doc.text(`Volta`, 110, pY);
      doc.text(
        `${
          user.PONTO_DATA_VOLTA
            ? formatarDataAnoMesDia(user.PONTO_DATA_VOLTA)
            : '-'
        }`,
        139,
        pY,
        { align: 'center', maxWidth: 20 },
      );
      doc.text(
        `${
          user.PONTO_HORA_VOLTA ? formatarHoraMin(user.PONTO_HORA_VOLTA) : '-'
        }`,
        162,
        pY,
        { align: 'center', maxWidth: 15 },
      );
      if (user.PONTO_LINK_FOTO_VOLTA) {
        doc.setTextColor(0, 0, 255);
        doc.text('____', 175, pY);
      }
      doc.textWithLink(
        user.PONTO_LINK_FOTO_VOLTA ? 'Foto' : '-',
        user.PONTO_LINK_FOTO_VOLTA ? 175 : 178,
        pY,
        {
          url: user.PONTO_LINK_FOTO_VOLTA,
        },
      );
      doc.setTextColor(0);
      doc.text(
        `${user.PONTO_LATITUDE_VOLTA ? user.PONTO_LATITUDE_VOLTA : '-'}`,
        210,
        pY,
        { align: 'center', maxWidth: 28 },
      );
      doc.text(
        `${user.PONTO_LONGITUDE_VOLTA ? user.PONTO_LONGITUDE_VOLTA : '-'}`,
        239,
        pY,
        { align: 'center', maxWidth: 28 },
      );
      doc.text(
        `${
          user.PONTO_DIFERENCA_METROS_VOLTA
            ? `${user.PONTO_DIFERENCA_METROS_VOLTA} m`
            : '-'
        }`,
        268,
        pY,
        { align: 'center', maxWidth: 15 },
      );
      pY += 5;
      doc.text(`Saída`, 110, pY);
      doc.text(
        `${
          user.PONTO_DATA_SAIDA
            ? formatarDataAnoMesDia(user.PONTO_DATA_SAIDA)
            : '-'
        }`,
        139,
        pY,
        { align: 'center', maxWidth: 20 },
      );
      doc.text(
        `${
          user.PONTO_HORA_SAIDA ? formatarHoraMin(user.PONTO_HORA_SAIDA) : '-'
        }`,
        162,
        pY,
        { align: 'center', maxWidth: 15 },
      );
      if (user.PONTO_LINK_FOTO_SAIDA) {
        doc.setTextColor(0, 0, 255);
        doc.text('____', 175, pY);
      }
      doc.textWithLink(
        user.PONTO_LINK_FOTO_SAIDA ? 'Foto' : '-',
        user.PONTO_LINK_FOTO_SAIDA ? 175 : 178,
        pY,
        {
          url: user.PONTO_LINK_FOTO_SAIDA,
        },
      );
      doc.setTextColor(0);
      doc.text(
        `${user.PONTO_LATITUDE_SAIDA ? user.PONTO_LATITUDE_SAIDA : '-'}`,
        210,
        pY,
        { align: 'center', maxWidth: 28 },
      );
      doc.text(
        `${user.PONTO_LONGITUDE_SAIDA ? user.PONTO_LONGITUDE_SAIDA : '-'}`,
        239,
        pY,
        { align: 'center', maxWidth: 28 },
      );
      doc.text(
        `${
          user.PONTO_DIFERENCA_METROS_SAIDA
            ? `${user.PONTO_DIFERENCA_METROS_SAIDA} m`
            : '-'
        }`,
        268,
        pY,
        { align: 'center', maxWidth: 15 },
      );
      posY += 30;
      if (posY < 175 && index + 1 !== responseUser.length) {
        doc.setDrawColor(0, 102, 0);
        doc.line(14, posY - 4, 280, posY - 4);
        doc.setDrawColor(0);
      }
      if (posY < 175 && index + 1 === responseUser.length) {
        doc.setDrawColor(0, 102, 0);
        doc.rect(14, yTabela, 266, posY - yTabela - 6.5);
        yTabela = posY;
        doc.setDrawColor(0);
      }
      if (posY >= 175 && index + 1 !== responseUser.length) {
        doc.setDrawColor(0, 102, 0);
        doc.rect(14, yTabela, 266, posY - yTabela - 6.5);
        yTabela = posY;
        doc.setDrawColor(0);
        doc.rect(7, 19, 280, posY - 5 * contaLinha - 4);
        doc.addPage();
        doc.setFontSize(16);
        doc.setTextColor(0, 0, 0);
        doc.setFontType('bold');
        doc.text(`Registro de Ponto - ${filial[1]}`, 147, 10, 'center');
        doc.setFontSize(12);
        doc.text(`Período: ${dataInicial} à ${dataFinal}`, 147, 15, 'center');
        doc.setFontType('normal');
        posY = 25;
        yTabela = posY;
        doc.setFontSize(10);
        doc.setLineWidth(0.5);
      }
      if (posY >= 175) {
        doc.setDrawColor(0, 102, 0);
        doc.rect(14, yTabela, 266, posY - yTabela - 6.5);
        yTabela = posY;
        doc.setDrawColor(0);
        // doc.rect(7, 19, 280, 9);
        doc.rect(7, 19, 280, posY - 5 * contaLinha - 4);
        doc.addPage();
        doc.setFontSize(16);
        doc.setTextColor(0, 0, 0);
        doc.setFontType('bold');
        doc.text(`Registro de Ponto - ${filial[1]}`, 147, 10, 'center');
        doc.setFontSize(12);
        doc.text(`Período: ${dataInicial} à ${dataFinal}`, 147, 15, 'center');
        doc.setFontType('normal');
        posY = 25;
        doc.setFontSize(10);
        doc.setLineWidth(0.5);
      }
    });
  });
  doc.rect(7, 19, 280, pY);
  doc.setFontSize(16);
  doc.setTextColor(0, 0, 0);
  doc.setFontType('bold');
  doc.text(`Registro de Ponto - ${filial[1]}`, 147, 10, 'center');
  doc.setFontSize(12);
  doc.text(`Período: ${dataInicial} à ${dataFinal}`, 147, 15, 'center');

  doc.save(`relatorio_ponto_${filial[1]}_${dataInicial}_a_${dataFinal}.pdf`);
};
