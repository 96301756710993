import React from 'react';
import {
  RiCheckboxMultipleLine,
  RiCheckboxMultipleBlankLine,
} from 'react-icons/ri';
import { ContainerTh } from './styles';

interface HeaderInterface {
  name: string;
  field: string;
  id?: number;
}

interface DadosInterface {
  headers: HeaderInterface[];
  checkboxList?(data: boolean): void;
}

const HeaderTable: React.FC<DadosInterface> = ({
  headers,
  checkboxList = (data: boolean): boolean => {
    return data;
  },
}) => {
  return (
    <thead>
      <tr>
        {headers.map((head: HeaderInterface, index: number) => (
          <ContainerTh key={head.field + index.toString()}>
            {head.name}
            {head.name === '' && head.field === 'checkbox' && (
              <>
                <div className="divBtn">
                  <button
                    type="button"
                    className="btnBusca"
                    onClick={() => checkboxList(true)}
                  >
                    <RiCheckboxMultipleLine size={22} />
                  </button>
                  <button
                    type="button"
                    className="btnBusca2"
                    onClick={() => checkboxList(false)}
                  >
                    <RiCheckboxMultipleBlankLine size={22} />
                  </button>
                </div>
              </>
            )}
          </ContainerTh>
        ))}
      </tr>
    </thead>
  );
};

export default HeaderTable;
