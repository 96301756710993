import React, { useState, useEffect, useMemo } from 'react';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
// @ts-ignore
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { ImQrcode } from 'react-icons/im';
import moment from 'moment-timezone';
import HeaderTable from '../../components/HeaderTable';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import DropDown from '../../components/DropDown';
import Pagination from '../../components/Pagination';
import { Loading } from '../../components/Loading';
import { maskCpf } from '../../components/Input/mask';
import ModalConexao from '../../components/ModalConexao';
import Search from '../../components/Search';
import ModalQrCode from '../../components/ModalQrCode';
import DrawerApp from '../../components/Drawer';

import { Container, ContainerTable, ContainerBusca } from './styles';

interface ResponseGet {
  AVATAR: string;
  Ativo: number;
  CPF: string;
  DataCadastro: string;
  DataNascimento: string;
  EMAIL: string;
  ID: number;
  NOME: string;
  Password: string;
  Telefone: string;
  privarDados: boolean;
  token: string;
  param: string;
}

const setores = [
  { valor: 'Administrativo', id: 11, NivelAcesso: 1 },
  { valor: 'Todos', id: 15, NivelAcesso: 1 },
];

const ListagemUsuariosQrCode: React.FC = () => {
  const { userAcesso } = useAuth();
  const history = useHistory();
  const [response, setResponse] = useState<ResponseGet[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const [searchCPF, setSearchCPF] = useState('');
  const [porcentagem, setPorcentagem] = useState(0);
  const [loading, setLoading] = useState(false);
  const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(20);

  const [qrCode, setQrCode] = useState(false);
  const [idQrCode, setIDQrCode] = useState('');
  const [dadosQrCode, setDadosQrCode] = useState<ResponseGet>();
  // const baseUrlQrCode = 'https://0017e7f010a6.ngrok.io/qrcode?search=';

  const dropDown = [
    { valor: '20', id: 20 },
    { valor: '30', id: 30 },
    { valor: '40', id: 40 },
    { valor: '50', id: 50 },
    { valor: 'Todos', id: 1 },
  ];

  const headers = [
    { name: 'Nome', field: 'name' },
    { name: 'CPF', field: 'cpf' },
    { name: 'E-mail', field: 'email' },
    { name: 'Telefone', field: 'telefone' },
    { name: 'Data de Nascimento', field: 'dataNascimento' },
    { name: 'Data de Cadastro', field: 'dataCadastro' },
    { name: 'QR Code', field: 'qrCode' },
  ];

  useEffect(() => {
    let semAcesso = true;
    let access;
    userAcesso.forEach((user) => {
      access = setores.filter(
        (s) => s.id === user.SetorID && user.NivelACesso >= s.NivelAcesso,
      );
      if (access.length > 0) {
        semAcesso = false;
      }
    });
    if (semAcesso) {
      history.push('/dashboard');
      Swal.fire({
        icon: 'info',
        title:
          'Seu nível de acesso não tem permissão para acessar essa página!',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
    } else {
      setLoading(true);
      api
        .get(`/users/findAll`, {
          onDownloadProgress(progress) {
            setPorcentagem((progress.loaded * 100) / progress.total);
          },
        })
        .then((data) => {
          // console.log(data.data);
          setResponse(data.data);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          if (navigator.onLine) {
            Swal.fire({
              icon: 'info',
              title: 'Erro ao Carregar dados! Tente Regarregar a Página.',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            });
          }
        });
    }
  }, [history, userAcesso]);

  const responseData = useMemo(() => {
    let computedResponse = response;

    if (search) {
      computedResponse = computedResponse.filter((data: ResponseGet) =>
        data.NOME.toLowerCase().includes(search.toLowerCase()),
      );
    }
    if (searchCPF) {
      let CPF: string = searchCPF.replace('.', '');
      CPF = CPF.replace('.', '');
      CPF = CPF.replace('-', '');
      computedResponse = computedResponse.filter((data: ResponseGet) =>
        data.CPF.toLowerCase().includes(CPF.toLowerCase()),
      );
    }

    setTotalItems(computedResponse.length);
    if (ITEMS_PER_PAGE === 1) {
      return computedResponse;
    }

    return computedResponse.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE,
    );
  }, [response, currentPage, search, searchCPF, ITEMS_PER_PAGE]);

  function formatarData(data: string): string {
    const dia = data.split('-')[2];
    const mes = data.split('-')[1];
    const ano = data.split('-')[0];

    return `${dia}/${mes}/${ano}`;
  }

  return (
    <>
      {qrCode && (
        <ModalQrCode
          dados={idQrCode}
          dadosGeral={dadosQrCode}
          onClose={() => setQrCode(false)}
        />
      )}
      <ModalConexao />
      <DrawerApp />
      {/* <HeaderComponent /> */}
      <ContainerBusca>
        <Search
          onSearch={(value: string) => {
            setSearch(value);
            setCurrentPage(1);
          }}
          nomePlaceHolder="Buscar Nome"
        />
        <Search
          onSearch={(value: string) => {
            setSearchCPF(value);
            setCurrentPage(1);
          }}
          nomePlaceHolder="Buscar CPF"
        />
      </ContainerBusca>
      <ContainerTable>
        <table id="tableListagemUsuariosQrCode">
          <HeaderTable headers={headers} />
          <tbody>
            {responseData.map((data: ResponseGet) => (
              <tr key={data.token}>
                <td style={{ minWidth: 250 }}>
                  {data.NOME === null ? '-' : data.NOME}
                </td>
                <td style={{ minWidth: 120 }}>
                  {data.CPF === null ? '-' : maskCpf(data.CPF)}
                </td>
                <td style={{ minWidth: 200 }}>
                  {data.EMAIL === null ? '-' : data.EMAIL}
                </td>
                <td>{data.Telefone === null ? '-' : data.Telefone}</td>
                <td style={{ minWidth: 150 }}>
                  {data.DataNascimento === null
                    ? '-'
                    : formatarData(data.DataNascimento)}
                </td>
                <td style={{ minWidth: 150 }}>
                  {data.DataCadastro === null
                    ? '-'
                    : moment(data.DataCadastro)
                        .tz('America/Fortaleza')
                        .format('DD/MM/YYYY - HH:mm')}
                </td>
                <td>
                  <button
                    type="button"
                    onClick={() => {
                      setQrCode(true);
                      setIDQrCode(() => {
                        // let cpfCripto = '';

                        // data.CPF.split('').map((obj) => {
                        //   switch (obj) {
                        //     case '0':
                        //       cpfCripto += 'Z9A';
                        //       break;
                        //     case '1':
                        //       cpfCripto += 'Y8B';
                        //       break;
                        //     case '2':
                        //       cpfCripto += 'X7C';
                        //       break;
                        //     case '3':
                        //       cpfCripto += 'W6D';
                        //       break;
                        //     case '4':
                        //       cpfCripto += 'V5E';
                        //       break;
                        //     case '5':
                        //       cpfCripto += 'U4F';
                        //       break;
                        //     case '6':
                        //       cpfCripto += 'T3G';
                        //       break;
                        //     case '7':
                        //       cpfCripto += 'S2H';
                        //       break;
                        //     case '8':
                        //       cpfCripto += 'R1I';
                        //       break;
                        //     case '9':
                        //       cpfCripto += 'Q0J';
                        //       break;
                        //     default:
                        //       console.log('Chegou no fim do switch case');
                        //   }
                        // });
                        return data.param;
                      });
                      setDadosQrCode(data);
                    }}
                  >
                    <ImQrcode />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </ContainerTable>

      <Container>
        <Pagination
          total={totalItems}
          itemsPerPage={ITEMS_PER_PAGE}
          currentPage={currentPage}
          onPageChange={(page: number) => setCurrentPage(page)}
        />
        <DropDown
          onChangeItems={(value: string) => {
            setCurrentPage(1);
            if (value === 'Todos') {
              setITEMS_PER_PAGE(1);
            } else {
              setITEMS_PER_PAGE(Number(value));
            }
          }}
          objetoEnum={dropDown}
          minWidth={30}
        />

        <ReactHTMLTableToExcel
          id="export-excel"
          className="btn"
          table="tableListagemUsuariosQrCode"
          filename={`listagem-usuarios-qrCode-${moment().format('DD-MM-YYYY')}`}
          sheet="tablexls"
          buttonText="Exportar Excel"
        />
        {loading && <Loading porcentagem={porcentagem} />}
      </Container>
    </>
  );
};

export default ListagemUsuariosQrCode;
