import styled, { keyframes } from 'styled-components';

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to{
    opacity: 0.2;
    transform: scale(1);
  }
`;

export const Container = styled.div`
  //flex: 1;
  header {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      margin-top: 60px;
      width: 900px;
      height: 400px;
      opacity: 0.2;
      transition: opacity 0.3s;
      animation: ${appearFromLeft} 1s;

      &:hover {
        opacity: 0.8;
      }
    }
  }
`;

export const ContainerImg = styled.div`
  /* justify-content: center;
  align-items: center; */
`;
