import React from 'react';
import { Container } from './styles';

interface TooltipProps {
  title: string;
  className?: string;
  tamanho?: number;
  marginRightComponent?: number | string;
  marginLeftComponent?: number | string;
  marginBottomComponent?: number | string;
  marginTopComponent?: number | string;
  widthComponent?: number | string;
}

const Tooltip: React.FC<TooltipProps> = ({
  title,
  className = '',
  tamanho = 160,
  marginRightComponent = 0,
  marginLeftComponent = 0,
  marginBottomComponent = 0,
  marginTopComponent = 0,
  widthComponent = 0,
  children,
}) => {
  return (
    <Container
      className={className}
      tamanho={tamanho}
      style={{
        marginRight: marginRightComponent,
        marginLeft: marginLeftComponent,
        marginTop: marginTopComponent,
        marginBottom: marginBottomComponent,
        width: widthComponent,
      }}
    >
      <div
        style={{ width: '100%', justifyItems: 'center', alignItems: 'center' }}
      >
        {children}
      </div>

      <span>{title}</span>
    </Container>
  );
};

export default Tooltip;
