import styled from 'styled-components';
// import { shade, setHue } from 'polished';
// import signInBackGroundImg from '../../assets/sign-in-background.png';

interface ContainerProps {
  tamanho: number;
  className?: string;
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  //z-index:1;
 /* width: auto; */
  Span {
    width: ${(props) => `${props.tamanho}px`};
    background: ${(props) => {
      if (props.className === 'Branco') {
        return '#f2f2f2';
      }
      return '#ff9000';
    }};

    padding: 5px 10px 5px 10px;
    border-radius: 14px;
    font-weight: 500;
    font-size: 14px;
    opacity: 0;
    transition: opacity 0.4s;
    visibility: hidden;
    line-height:17px;
    position: absolute;
    bottom: calc(100% + 12px);
    left: 50%;
    transform: translateX(-50%);

    color: #312e38;

    &::before {
      content: '';
      border-style: solid;

      border-color: ${(props) => {
        if (props.className === 'Branco') {
          return '#f2f2f2 transparent;';
        }
        return '#ff9000 transparent;';
      }}
        

      //border-color: #ff9000 transparent;
      border-width: 6px 6px 0 6px;
      bottom: 20px;
      top: 100%;
      position: absolute;

      left: 50%;
      transform: translateX(-50%);
    }
  }

  &:hover span {
    opacity: 1;
    visibility: visible;
  }
`;
