import styled from 'styled-components';
import { shade } from 'polished';

export const ContainerTh = styled.th`
  /* max-width: 150px; */
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  text-align: center;
  white-space: nowrap;
  padding: 7px 20px;
  z-index: 2;
  div.divBtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    button.btnBusca {
      border-radius: 7px 0 0 7px;
      border-right: 1px solid ${shade(0.1, '#006600')};
    }

    button.btnBusca2 {
      border-radius: 0 7px 7px 0;
      border-left: 1px solid ${shade(0.1, '#006600')};
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #006600;
    color: #ffffff;
    width: 100px;
    height: 30px;
    border: none;

    transition: background 0.2s;

    &:hover {
      background: ${shade(0.1, '#006600')};
    }
  }
`;
