import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  align-items: center;
  font-family: Arial;
  select {
    font-size: 16px;
    height: 40px;
    background-color: #008f00;
    color: #ffffff;
    margin-left: 40px;
    //box-shadow: 0 2px 4px #0d0d0d;
    border-color: #008f00;
    cursor: pointer;
    &:hover {
      background: ${shade(0.1, '#008f00')};
    }
    label + & {
      margin-left: 0px;
      border-radius: 0px;
      background-color: #ffffff;
      color: #888888;
    }
  }
`;
