import React, { useCallback, useState } from 'react';
import { FiCalendar, FiMail, FiPhone, FiUser } from 'react-icons/fi';

import Swal from 'sweetalert2';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import DrawerApp from '../../components/Drawer';
import { maskDateOfBirth, maskTel } from '../../components/Input/mask';
import { Loading } from '../../components/Loading';
import ModalConexao from '../../components/ModalConexao';

import api from '../../services/api';
import {
  AnimationContainer,
  CardUser,
  Container,
  Content,
  DeleteButton,
  InfoUser,
} from './styles';
import logoImg from '../../assets/logo.png';
import avatar from '../../assets/avatar2.png';

export const DeleteAccountMenu: React.FC = () => {
  const { addToast } = useToast();
  const { user, signOut } = useAuth();
  const [loading, setLoading] = useState(false);

  const deleteAccount = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);

      await api.post('/profile/deleteMyAccount', {
        userId: user.id,
        ativo: 0,
      });

      addToast({
        type: 'success',
        title: 'Conta deletada com sucesso!',
      });
      signOut();
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro ao deletar conta',
        description: 'Ocorreu um erro ao apagar a conta.',
      });
    } finally {
      setLoading(false);
    }
  }, [addToast, signOut, user.id]);

  const handleDeleteAccount = useCallback(() => {
    Swal.fire({
      title: 'Tem certeza que deseja apagar a conta?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Não',
      confirmButtonColor: '#c53030',
      cancelButtonColor: '#ababab',
      confirmButtonText: 'Sim',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteAccount();
      }
    });
  }, [deleteAccount]);

  return (
    <Container>
      <ModalConexao />
      <DrawerApp />
      <Content>
        <div>
          {loading && <Loading />}
          <AnimationContainer>
            <img src={logoImg} alt="Logo Servnac" />

            <InfoUser>
              <img
                src={user.avatar_url ? user.avatar_url : avatar}
                style={{ objectFit: 'cover' }}
                alt="Imagem profile"
              />
              <CardUser>
                <div>
                  <FiUser />
                  <p>{user.nome}</p>
                </div>
                <div>
                  <FiMail />
                  <p>{user.email}</p>
                </div>
                <div>
                  <FiCalendar />
                  <p>{maskDateOfBirth(user.dataNascimento)}</p>
                </div>
                <div>
                  <FiPhone />
                  <p>{maskTel(user.telefone)}</p>
                </div>
              </CardUser>

              <span>
                Atenção!
                <br />
                Esta ação é irreversível. Ao deletar a conta, tenha certeza da
                sua confirmação, os dados serão excluídos de imediato.
              </span>
              <DeleteButton type="button" onClick={handleDeleteAccount}>
                Deletar conta
              </DeleteButton>
            </InfoUser>
          </AnimationContainer>
        </div>
      </Content>
    </Container>
  );
};
