import React from 'react';

import {
  RouteProps as ReactDOMRouteProps,
  Route as ReactDOMRoute,
  Redirect,
} from 'react-router-dom';

import { useAuth } from '../hooks/auth';
import { is } from 'immer/dist/internal';

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  Setor?: boolean;
  component: React.ComponentType;
  requiredNivel?: number;
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  requiredNivel,
  ...rest
}) => {
  const { user, userAcesso } = useAuth();
  let temPermissao = true;

  if (isPrivate && requiredNivel === undefined) {
    requiredNivel = 1;
    temPermissao = requiredNivel === 1 || userAcesso?.some(({ NivelACesso }) => NivelACesso === requiredNivel);
  }

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        return isPrivate === !!user && temPermissao ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname: isPrivate // eslint-disable-line
                ? '/'
                : '/dashboard',

              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default Route;
