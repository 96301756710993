import styled from 'styled-components';

export const ContainerItem = styled.div`
  max-width: 1120px;
  ul {
    justify-content: center;
    align-items: center;
    list-style-type: none;
    display: flex;
    height: 40px;
    margin: 0px;
    //border-radius: 10px;
    border: 2px solid #006600;
  }
  .active {
    background-color: #006600;
    padding: 10px;
  }
  li {
    padding-top: 10px;
    padding-bottom: 10px;
    display: list-item;
    /* border-right: 1px solid #006600;
    border-left: 1px solid #006600; */
    &:active {
      background-color: #006600;
    }
    /**ocultar link e mudar a cor*/
    a {
      padding: 10px;
      text-decoration: none;
      color: #ffffff;
      /** mudar cor na hora do click */
      &:active {
        color: #ffffff;
      }
    }
    /**mudar com do elemento clicado */
    span {
      color: #ffffff;
    }
    /** span class= sr-only -- para ocultar o texto -> current */
    .sr-only {
      overflow: hidden;
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      clip: rect(0, 0, 0, 0);
      border: 0;
      color: #ffffff;
    }
  }
`;
